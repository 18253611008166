import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DashboardTitle from "../../Components/DashboardTitle";

import { toast } from "react-toastify";
import { ReactComponent as MenuIcon } from "../../assets/Images/ic_menudot.svg";
import { ReactComponent as MenuEditIcon } from "../../assets/Images/ic_menuedit.svg";
import { ReactComponent as MenuDeleteIcon } from "../../assets/Images/ic_menudelete.svg";
import Menutext from "../../Components/Menutext";
import Menutitletext from "../../Components/Menutitletext";
import { Images } from "../../Utils/Constants";
import ServiceviewDialog from "../ServicePage/ServiceviewDialog";
import EditInvestors from "./EditStartupInvestors";
import DeleteDialog from "../../Components/DeleteDialog";
import {
  mCRMBoard,
  mDeleteCRM,
  mgetCRMBoard,
  mMoveCRMBoard,
} from "../../Services/allAPI";
import Titletext from "../../Components/Titletext";
import Loader from "../../Utils/Loader";
import axios from "axios";

const BoardView = () => {
  const [dashboarddate, setDashboardData] = useState([]);
  const menuRef = useRef(null);
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [ShowInvestorDetails, setShowInvestorDetails] = useState(false);
  const [SelectInvestor, setSelectInvestor] = useState({});

  //const [crmdata, setCRMdata] = useState([]);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  let CRMdataItems = [];

  // Data call

  useEffect(() => {
    BoardAPICall();
  }, []);

  // Service Page API call
  const BoardAPICall = async () => {
    setLoading(true);
    const response = await mCRMBoard({
      user_name: UserInfoData.user_email,
    });
    //alert(response.status);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        const shortlistedItem = {
          title:
            "Shortlisted" +
            "(" +
            response?.data.message.investors_board.shortlisted.length +
            ")",
          items: response?.data.message.investors_board.shortlisted,
          itemsTitle: "Shortlisted",
        };
        CRMdataItems.push(shortlistedItem);

        const contactedItem = {
          title:
            "Contacted" +
            "(" +
            response?.data.message.investors_board.contacted.length +
            ")",
          items: response?.data.message.investors_board.contacted,
          itemsTitle: "Contacted",
        };

        CRMdataItems.push(contactedItem);

        const pitchedItem = {
          title:
            "Pitched" +
            "(" +
            response?.data.message.investors_board.pitched.length +
            ")",
          items: response?.data.message.investors_board.pitched,
          itemsTitle: "Pitched",
        };

        CRMdataItems.push(pitchedItem);

        const wonItem = {
          title:
            "Won" +
            "(" +
            response?.data.message.investors_board.won.length +
            ")",
          items: response?.data.message.investors_board.won,
          itemsTitle: "Won",
        };

        CRMdataItems.push(wonItem);

        setColumns(CRMdataItems);

        // console.log(CRMdataItems);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  //
  // Close the menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const [columns, setColumns] = useState(CRMdataItems);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      // console.log("Source Index:", result.draggableId);
      // console.log("Destination Index:", destColumn.title);

      // toast.success(result.draggableId);
      // toast.success(destColumn.title);

      ChangeCRMStatusAPICall(result.draggableId, destColumn.title);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  // Service Page API call
  const ChangeCRMStatusAPICall = async (investorid, movedstatus) => {
    setLoading(true);
    const response = await mMoveCRMBoard({
      user_name: UserInfoData.user_email,
      investor_fav_id: investorid,
      moved_status: movedstatus,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        // console.log(CRMdataItems);
        toast.success(response?.data.message.message);
        BoardAPICall();
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const [gridshow, setGridShow] = useState(true);

  const listclick = () => {
    setGridShow(false);
  };

  const gridclick = () => {
    setGridShow(true);
  };

  // Drag and drop end

  const menuClick = (item) => {
    setActiveMenu(activeMenu === item.id ? null : item.id);
  };
  const clickEdit = (item, event) => {
    // alert(`Edit ${item.Task}`);
    setSelectInvestor(item);
    setActiveMenu(null);
    setShowInvestorDetails(true);
  };

  const clickClose = () => {
    setShowInvestorDetails(false);
  };

  const clickRefresh = () => {
    setShowInvestorDetails(false);
    BoardAPICall();
  };

  const clickDelete = (item, event) => {
    setActiveMenu(null);
    setSelectInvestor(item);
    setIsDialogOpen(true);
  };
  const [activeMenu, setActiveMenu] = useState(null);

  //Delete dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const confirmDelete = () => {
    // Perform delete action here
    console.log("Item deleted");
    closeDialog();

    DeleteAPICall();
  };

  // Service Page API call
  const DeleteAPICall = async () => {
    setLoading(true);
    const response = await mDeleteCRM({
      user_name: UserInfoData.user_email,
      investor_fav_id: SelectInvestor.investor_fav_id,
      delete: 1,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.status);
        clickRefresh();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <p className="ml-5 font-PlusJakartaSansRegular text-mTextColorLogin text-sm">
            *Drag and drop the card to change its status
          </p>

          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            <div className="flex  md:mr-5">
              <div className="m-2 md:flex w-full md:min-h-svh ">
                {Object.entries(columns).map(([columnId, column], index) => {
                  return (
                    <Droppable key={columnId} droppableId={columnId}>
                      {(provided, snapshot) => (
                        <div
                          className="min-h-28 flex flex-col bg-white w-full md:min-w-80 rounded-2xl p-2 md:mr-4 mb-4 md:mb-1"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <div className="p-2">
                            <DashboardTitle label={column.title} />
                          </div>
                          {column.items.length !== 0 ? (
                            column.items.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.investor_fav_id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="p-2  rounded-lg mb-2 relative"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="flex min-h-28 flex-col justify-start items-start p-4 bg-mMenuSelect mt-3 rounded-xl w-full">
                                      <div
                                        className="flex-row flex h-full w-full justify-center items-center"
                                        onClick={() => clickEdit(item)}
                                      >
                                        <img
                                          className="rounded-full bg-white w-11 h-11 p-px "
                                          src={item.image}
                                          alt="review"
                                        />
                                        <div className="h-full flex w-full ml-2 flex-col">
                                          <Menutext
                                            label={item.investor_name}
                                          />

                                          <div className="mt-1">
                                            <Menutitletext
                                              label={item.investor_type}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mt-4 flex flex-row justify-between items-center w-full ">
                                        <div>
                                          <p
                                            className={`font-PlusJakartaSansRegular ${
                                              column.itemsTitle ===
                                              "Shortlisted"
                                                ? "text-Shortlisted"
                                                : column.itemsTitle ===
                                                  "Contacted"
                                                ? "text-Contacted"
                                                : column.itemsTitle ===
                                                  "Pitched"
                                                ? "text-Pitched"
                                                : "text-Won"
                                            }  text-base`}
                                          >
                                            {column.itemsTitle}
                                          </p>
                                        </div>
                                        <div className="cursor-pointer">
                                          <MenuIcon
                                            className="w-6 h-6 flex-shrink-0 "
                                            onClick={() => menuClick(item)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {activeMenu === item.id && (
                                      <div
                                        className="absolute right-0 mt-1 w-40 bg-white p-3 rounded-xl shadow shadow-mChatbg z-10"
                                        ref={menuRef}
                                      >
                                        <ul>
                                          <li
                                            className="px-2 py-2  cursor-pointer"
                                            onClick={() => clickEdit(item)}
                                          >
                                            <div className="flex flex-row justify-start items-center">
                                              <MenuEditIcon className="w-5 h-5 flex-shrink-0 " />
                                              <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base ml-3">
                                                {t("EditDetails")}{" "}
                                              </p>
                                            </div>
                                          </li>
                                          <li
                                            className="px-2 py-2 mt-2 cursor-pointer"
                                            onClick={() => clickDelete(item)}
                                          >
                                            <div className="flex flex-row justify-start items-center">
                                              <MenuDeleteIcon className="w-5 h-5 flex-shrink-0 " />
                                              <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base ml-3">
                                                {t("Delete")}{" "}
                                              </p>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <div className="w-full h-full justify-center items-center flex"></div>
                          )}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                })}
              </div>
            </div>
          </DragDropContext>
          {ShowInvestorDetails ? (
            <EditInvestors
              show={ShowInvestorDetails}
              onCancel={clickClose}
              clickRefresh={clickRefresh}
              getInvestors={SelectInvestor}
            />
          ) : (
            <div></div>
          )}
          {/* Delete Confirmation Dialog */}
          <DeleteDialog
            isOpen={isDialogOpen}
            onCancel={closeDialog}
            onConfirm={confirmDelete}
            title={"Delete Confirmation"}
            message={" Are you sure you want to delete this item? "}
          />
        </div>
      )}
    </>
  );
};
export default BoardView;

// Design files

// Data Start

// const data = [
//   {
//     id: "109",
//     Task: "One",

//     Due_Date: "25-May-2020",
//   },
//   {
//     id: "2",
//     Task: "Two",

//     Due_Date: "26-May-2020",
//   },
//   {
//     id: "3",
//     Task: "Three",

//     Due_Date: "27-May-2020",
//   },
//   {
//     id: "4",
//     Task: "Fouasfsafr",

//     Due_Date: "23-Aug-2020",
//   },
//   {
//     id: "5",
//     Task: "Five",

//     Due_Date: "05-Jan-2021",
//   },
//   {
//     id: "6",
//     Task: "Sixddd",

//     Due_Date: "05-Jan-2021",
//   },
//   {
//     id: "7",
//     Task: "Seven",

//     Due_Date: "05-Jan-2021",
//   },
//   {
//     id: "8",
//     Task: "Eigth",

//     Due_Date: "05-Jan-2021",
//   },
// ];

// const columnsFromBackend = {
//   [1]: {
//     title: "Deal",
//     items: data,
//   },
//   [2]: {
//     title: "Contacted",
//     items: [],
//   },
//   [3]: {
//     title: "Evaluating",
//     items: [],
//   },
//   [4]: {
//     title: "Diligence",
//     items: [],
//   },
//   [5]: {
//     title: "Investment Committee",
//     items: [],
//   },
//   [6]: {
//     title: "Invested",
//     items: [],
//   },
// };

// const columnsFromBackend = [
//   {
//     title: "Shortlisted",
//     items: data,
//   },
//   {
//     title: "Contacted",
//     items: [],
//   },
//   {
//     title: "Pitched",
//     items: [],
//   },
//   {
//     title: "Won",
//     items: [],
//   },
// ];

//Data End
