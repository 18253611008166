import React from "react";
import { ReactComponent as PwdShow } from "../assets/Images/ic_pwdshow.svg";
import { ReactComponent as PwdHide } from "../assets/Images/ic_pwdhide.svg";

// Custom Input text
const InputContainer = ({
  value,
  label,
  name,
  placeholder,
  type,
  onChange,
  disabled,
  secureTextEntry,
  PasswordVisibility,
  setshowPassword,
  maxLength,
  showIcon,
  Icon,
  clickInfoIcon,
}) => (
  <div
    className={`flex flex-row justify-between border-mBorderColor h-12 rounded-lg p-3 w-full focus:outline-none focus:mBlueOne hover:mBorderColor mt-3 border ${
      disabled ? "bg-mDisabled" : "bg-white"
    }`}
  >
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      required
      disabled={disabled}
      className={`text-sm  3xl:text-base  text-mTextColor placeholder-mTextColorLogin font-PlusJakartaSansRegular font-normal w-full border-none focus:border-none  border-4 !outline-none`}
    ></input>

    {/* Check the ride side image showing or not */}
    {showIcon && <Icon className="w-6 h-6 flex-shrink-0" />}

    {/* Visiable or hide the password */}
    {secureTextEntry && (
      <button className="flex-row-reverse ml-4 " onClick={PasswordVisibility}>
        {setshowPassword ? <PwdShow /> : <PwdHide />}
      </button>
    )}
  </div>
);

export default InputContainer;
//t
