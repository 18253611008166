import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Utils/Loader";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../assets/Images/ic_back.svg";
import { ReactComponent as SparklesIcon } from "../../assets/Images/ic_sparkles.svg";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { Images, isValidEmail } from "../../Utils/Constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PdfUploader from "../../Components/PdfUploader";
import { mUploadDeck } from "../../Services/allAPI";
import Headingtext from "../../Components/Headingtext";
import TextView from "../../Components/TextView";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "../../redux/slice/userSlice";
import DeleteDialog from "../../Components/DeleteDialog";
import LogoText from "../../Components/LogoText";
import FirstPdfUploader from "../../Components/FirstPdfUploader";

const Uploadpicthdeck = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [base64, setBase64] = useState("");
  const [FileName, setFileName] = useState("");
  const [deckId, setdeckId] = useState("");
  const dispatch = useDispatch();

  // Clear local stoage

  localStorage.clear();
  dispatch(removeUser());

  //Send OTP Click

  const sendOTPClick = () => {
    if (!isValidEmail(email)) {
      toast.error(t("vaildmail"));
      return;
    }
    ForgetPwdCall();
  };

  const ForgetPwdCall = async () => {
    navigate("/resetpasswordotp");
  };

  const mBackClick = () => {
    navigate("/login");
  };

  const loginClick = () => {
    navigate("/login");
  };

  const registerClick = () => {
    navigate("/signup");
  };

  // Check Disable button
  const isDisabled = !email;

  const handleFileSelect1 = async (file) => {
    //  setSelectedFile(file);
    console.log(file);

    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const base64String = await convertToBase64(file);
    setBase64(base64String);

    console.log("Base64 String:", base64String);

    var mCropImage = "";

    if (base64String.length === 0) {
      mCropImage = base64String.replace("data:application/pdf;base64,", "");
    } else {
      mCropImage = base64String.replace("data:application/pdf;base64,", "");
    }

    // console.log(mCropImage);
    setFileName(file.name);
    setLoading(true);
    const response = await mUploadDeck({
      deck_name: file.name,
      deck_doc: mCropImage,
    });
    console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        // toast.success(response?.data.message.message);

        setdeckId(response?.data.message.deck_id);

        navigate("/signup", {
          state: {
            mdeckId: response?.data.message.deck_id,
            mdeckName: file.name,
          },
        });
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  //Function to convert file to Base64
  const convertToBase64 = (file) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const skipclick = () => {
    navigate("/login");
  };

  // if (typeof window.localStorage !== "undefined") {
  //   alert("localStorage is supported");
  // } else {
  //   alert("localStorage is not supported in this browser");
  // }

  // State to store screen width and height
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Function to update state with new screen size
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // Add an event listener when the component mounts
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="min-h-screen w-full flex flex-row justify-center">
          <div className=" hidden lg:block w-3/5 bg-mwhite  flex-col items-start justify-start p-5">
            <div className="mt-3 ml-3">
              {/* <img
                src={Images.zenpivot}
                alt="google Icon"
                className=" h-9 w-28"
              /> */}
              <LogoText label={t("appname")} />
            </div>

            {/* <div>
              <h1>Screen Size</h1>
              <p>Width: {windowSize.width}px</p>
              <p>Height: {windowSize.height}px</p>
            </div> */}

            <div className="w-full h-auto justify-center items-center flex">
              <img src={Images.pitchdeckbg} alt="login" className="w-2/3 " />
            </div>
          </div>
          <div className=" lg:w-2/5 w-full pl-2 py-5 lg:pl-20 lg:py-10 items-center justify-center lg:items-start lg:justify-start bg-mBackground  flex-col flex ">
            <div className="w-11/12 h-full ">
              <div className="grid gap-2 grid-cols-4 h-2 mt-3 items-center justify-center ">
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
                <div className=" h-1 bg-mTextColorLogin rounded-full"></div>
                <div className=" h-1 bg-mTextColorLogin rounded-full"></div>
                <div className=" h-1 bg-mTextColorLogin rounded-full"></div>
              </div>

              <div className="mt-4  w-full justify-between flex">
                <div>
                  <p className="text-mTextColor font-PlusJakartaSansMedium text-sm  3xl:text-base">
                    Step 1 / 4
                  </p>
                </div>

                <div onClick={() => skipclick()}>
                  <p className="text-mTextColor font-PlusJakartaSansMedium text-sm 3xl:text-base cursor-pointer">
                    {t("skip")}
                  </p>
                </div>
              </div>

              <div className="mt-10 w-full p-2  items-center justify-center flex-col flex">
                <div>
                  <Headingtext label={t("UploadPitchDeck")} />
                </div>

                <div className="mt-4 items-center justify-center flex">
                  <TextView isCenter={true} label={t("UploadPitchDeckmsg")} />
                </div>

                <FirstPdfUploader
                  onFileSelect={handleFileSelect1}
                  hidelogin={0}
                />
              </div>

              <div
                className="h-14 w-full mt-10 bg-mButtonColorligth rounded-lg items-center justify-center flex cursor-pointer"
                onClick={registerClick}
              >
                <p className="ml-2 font-PlusJakartaSansSemiBold text-mButtonColor text-base 3xl:text-base">
                  {" "}
                  {t("Signup")}{" "}
                </p>
              </div>

              <div className="flex-row flex justify-center mt-4">
                <p className="text-center font-PlusJakartaSansRegular text-sm  3xl:text-base text-mTextColorLogin">
                  {t("alreadyaccount")}
                </p>
                <button
                  className="text-center font-PlusJakartaSansSemiBold text-sm  3xl:text-base text-mButtonColor ml-2"
                  onClick={loginClick}
                >
                  {t("login")}
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Uploadpicthdeck;
