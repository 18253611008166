import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DashboardTitle from "../../Components/DashboardTitle";
import TextView from "../../Components/TextView";
import InputContainer from "../../Components/InputContainer";
import { mNotificationDetails, passwordchange } from "../../Services/allAPI";
import { toast } from "react-toastify";
import Loader from "../../Utils/Loader";

const Accountinfo = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const isDisabled = !password || !confirmpassword;
  const [notificationdetails, setNotificationDetails] = useState([]);

  // State to track selected checkboxes
  // const [selectedItems, setSelectedItems] = useState([]);
  const [stageselectedItems, setstageSelectedItems] = useState([]);

  // Handle checkbox change event
  const handlestageCheckboxChange = (id) => {
    setstageSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(id)) {
        // If the item is already selected, remove it from the list
        return prevSelectedItems.filter((itemId) => itemId !== id);
      } else {
        // Otherwise, add it to the list
        return [...prevSelectedItems, id];
      }
    });
  };

  useEffect(() => {
    //NotificationDetails();
  }, []);

  const NotificationDetails = async () => {
    setLoading(true);
    const response = await mNotificationDetails({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setNotificationDetails(response?.data.message.notification_details);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const updatePwdClick = () => {
    PasswordchangeAPICall();
  };

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  // Password change API call
  const PasswordchangeAPICall = async () => {
    setLoading(true);
    const response = await passwordchange({
      user_name: UserInfoData.user_email,

      current_password: password,
      new_password: confirmpassword,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);

      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className=" p-2 md:p-6">
            <div className="grid grid-cols-1 gap-5">
              <div className="h-9 justify-between flex ">
                <div className="justify-center items-center flex">
                  <DashboardTitle label={t("ChangePassword")} />
                </div>
                <div className=" hidden md:flex h-9 rounded-lg items-center justify-center  cursor-pointer">
                  <button
                    className={`  text-white text-base 3xl:text-base px-5 rounded-md w-full h-9 font-PlusJakartaSansSemiBold ${
                      isDisabled
                        ? "bg-mButtonDisable cursor-not-allowed"
                        : "bg-mButtonColor"
                    }`}
                    onClick={updatePwdClick}
                  >
                    {t("UpdatePassword")}
                  </button>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <div className="mt-4">
                  <TextView label={t("CurrentPassword")} />
                </div>
                <InputContainer
                  type={"text"}
                  value={password}
                  placeholder={t("EnterPassword")}
                  label="password"
                  name="password"
                  disabled={false}
                  maxLength={60}
                  setshowPassword={false}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    // setLogin({ ...login, password: e.target.value });
                  }}
                />
              </div>
              <div className="w-full md:w-1/2">
                <div className="mt-4">
                  <TextView label={t("NewPassword")} />
                </div>
                <InputContainer
                  type={"text"}
                  value={confirmpassword}
                  placeholder={t("EnterNewPassword")}
                  label="password"
                  name="password"
                  disabled={false}
                  maxLength={60}
                  setshowPassword={false}
                  onChange={(e) => {
                    setconfirmPassword(e.target.value);
                    // setLogin({ ...login, password: e.target.value });
                  }}
                />
              </div>

              <div className=" flex md:hidden h-9 rounded-lg items-center justify-center  cursor-pointer">
                <button
                  className={`  text-white text-base 3xl:text-base px-5 rounded-md w-full h-9 font-PlusJakartaSansSemiBold ${
                    isDisabled
                      ? "bg-mButtonDisable cursor-not-allowed"
                      : "bg-mButtonColor"
                  }`}
                  onClick={updatePwdClick}
                >
                  {t("UpdatePassword")}
                </button>
              </div>
            </div>

            <div className="mt-10 hidden">
              <div className="h-9  justify-between flex ">
                <div className="justify-center items-center flex">
                  <DashboardTitle label={t("NotificationSettings")} />
                </div>
                <div className=" hidden md:flex h-9 rounded-lg items-center justify-center cursor-pointer">
                  <button
                    className={`  text-white text-base 3xl:text-base  px-5 rounded-md w-full h-9 font-PlusJakartaSansSemiBold ${
                      isDisabled
                        ? "bg-mButtonDisable cursor-not-allowed"
                        : "bg-mButtonColor"
                    }`}
                    onClick={updatePwdClick}
                  >
                    {t("save")}
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5 bg-white w-full rounded-md md:rounded-xl p-3 hidden">
              <div className="mt-5 grid grid-cols-1 gap-5">
                {notificationdetails.map((item) => (
                  <div className="flex flex-col" key={item.notification_type}>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="privacyTermsCheckbox"
                        onChange={() => handlestageCheckboxChange(item.id)}
                        className="h-4 w-4 font-PlusJakartaSansRegular text-mTextColor text-sm"
                      />
                      <label
                        htmlFor="privacyTermsCheckbox"
                        className="ml-2 text-sm 3xl:text-sm  text-mTextColorLogin font-PlusJakartaSansRegular"
                      >
                        {item.notification_type}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
              <div className="h-5"></div>
            </div>

            <div className=" mt-3  hidden h-9 rounded-lg items-center justify-center cursor-pointer">
              <button
                className={`  text-white text-base 3xl:text-base px-5 rounded-md w-full h-9 font-PlusJakartaSansSemiBold ${
                  isDisabled
                    ? "bg-mButtonDisable cursor-not-allowed"
                    : "bg-mButtonColor"
                }`}
                onClick={updatePwdClick}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Accountinfo;
