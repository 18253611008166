import React from "react";

// Custom text
const TextView = ({ label, isCenter, ismandatory }) => (
  <div>
    {" "}
    <span
      className={`font-PlusJakartaSansRegular font-normal text-mTextColor text-sm  xl:text-base 3xl:text-lg ${
        isCenter ? "text-center" : ""
      }`}
    >
      {label}
    </span>
    {ismandatory ? (
      <span className="font-PlusJakartaSansRegular text-red-500 text-sm md:text-base xl:text-lg 3xl:text-lg">
        *
      </span>
    ) : (
      <span className="font-PlusJakartaSansRegular text-red-500 text-sm md:text-base xl:text-lg 3xl:text-lg"></span>
    )}
  </div>
);

export default TextView;
