import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";
import { Images } from "../../Utils/Constants";
import Menutext from "../../Components/Menutext";
import Menutitletext from "../../Components/Menutitletext";
import DashboardTitle from "../../Components/DashboardTitle";
import { ReactComponent as EIStatusIcon } from "../../assets/Images/EI_status.svg";
import { ReactComponent as EIDateIcon } from "../../assets/Images/EI_date.svg";
import { ReactComponent as EIDealIcon } from "../../assets/Images/EI_deal.svg";
import { ReactComponent as EISectorIcon } from "../../assets/Images/EI_sector.svg";
import { ReactComponent as EIPitchdeckIcon } from "../../assets/Images/EI_pitchdeck.svg";
import { ReactComponent as EIOnepagerIcon } from "../../assets/Images/EI_onepager.svg";
import { ReactComponent as EIRevenueIcon } from "../../assets/Images/EI_revenue.svg";
import { ReactComponent as EIBurnIcon } from "../../assets/Images/EI_burn.svg";
import { ReactComponent as StarIcon } from "../../assets/Images/ic_star.svg";
import { ReactComponent as NoofIcon } from "../../assets/Images/ic_noofinv.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/Images/ic_linkedin.svg";
import { toast } from "react-toastify";

import TextView from "../../Components/TextView";
import PrimaryButton from "../../Components/PrimaryButton";
import { mEditCRM } from "../../Services/allAPI";

const DealsDetails = ({
  show,
  onConfirm,
  onCancel,
  getInvestors,
  clickRefresh,
}) => {
  const { t } = useTranslation("");
  const [valutname, setValutName] = useState("");
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState(getInvestors.notes);
  const [loading, setLoading] = useState(false);

  // alert(JSON.stringify(getInvestors));

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const submitClick = () => {
    SaveAPICall();
  };

  // Service Page API call
  const SaveAPICall = async () => {
    setLoading(true);
    const response = await mEditCRM({
      user_name: UserInfoData.user_email,
      investor_fav_id: getInvestors.investor_fav_id,
      notes: message,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);

        clickRefresh();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      setLoading(false);
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      //  console.log("Error");
    }
  };

  const isDisable = !message;

  const dialogRef = useRef();
  // Close the modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside the dialog

      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onCancel(); // Close the dialog if the click is outside
      }
    };

    // Add the event listener when the dialog is shown
    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Remove the event listener when the dialog is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onCancel]);

  // Change data formet start
  const formatDate = (inputDate) => {
    // Split the date string into day, month, year
    const [day, month, year] = inputDate.split("-");
    // Create a new Date object from the components (month is zero-based, so we subtract 1)
    const date = new Date(year, month - 1, day);
    // Format the date using toLocaleDateString with options
    const options = { day: "numeric", month: "short", year: "numeric" }; // e.g., 25 Sep 2024
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-2 z-50">
      {/* max-w-sm */}
      <div
        className="bg-mDashboardBg rounded-md overflow-auto scrollbar-hide shadow-xl w-full  md:w-1/2 lg:w-1/3 py-4 pl-8 pr-4 h-90"
        ref={dialogRef}
      >
        <div className="justify-between flex ">
          <div></div>

          <div>
            <button onClick={onCancel}>
              <CloseIcon className="" />
            </button>
          </div>
        </div>

        <div className="flex-row flex w-full justify-center items-center">
          <img
            className="rounded-lg bg-white w-9 h-9"
            src={Images.Pdf}
            alt=""
          />
          <div className="h-full flex w-full ml-2 flex-col">
            <DashboardTitle label={getInvestors.deal_name} />

            <div className="mt-1">
              <Menutitletext label={getInvestors.sector} />
            </div>
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 gap-5">
          <div className="flex flex-row justify-center items-center">
            <div className="w-2/5 flex flex-row justify-start items-center">
              <EIStatusIcon className="w-6 h-6 flex-shrink-0 " />
              <div className="ml-4">
                {" "}
                <Menutext label={t("Status")} />
              </div>
            </div>
            <div className="w-3/5">
              <Menutext label={getInvestors.status} />
            </div>
          </div>

          <div className="flex flex-row justify-center items-center">
            <div className="w-2/5 flex flex-row justify-start items-center">
              <EIDateIcon className="w-6 h-6 flex-shrink-0 " />
              <div className="ml-4">
                {" "}
                <Menutext label={t("AppliedDate")} />
              </div>
            </div>
            <div className="w-3/5">
              <Menutext label={formatDate(getInvestors.applied_date)} />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center">
            <div className="w-2/5 flex flex-row justify-start items-center">
              <EIDealIcon className="w-6 h-6 flex-shrink-0 " />
              <div className="ml-4">
                {" "}
                <Menutext label={t("Sourceofdeal")} />
              </div>
            </div>
            <div className="w-3/5">
              <Menutext label={getInvestors.source_of_deal} />
            </div>
          </div>

          <div className="flex flex-row justify-center items-center">
            <div className="w-2/5 flex flex-row justify-start items-center">
              <EISectorIcon className="w-6 h-6 flex-shrink-0 " />
              <div className="ml-4">
                {" "}
                <Menutext label={t("Sector")} />
              </div>
            </div>
            <div className="w-3/5">
              <Menutext label={getInvestors.sector} />
            </div>
          </div>

          <div className="flex flex-row justify-center items-center">
            <div className="w-2/5 flex flex-row justify-start items-center">
              <EIPitchdeckIcon className="w-6 h-6 flex-shrink-0 " />
              <div className="ml-4">
                {" "}
                <Menutext label={t("PitchDeckLink")} />
              </div>
            </div>
            <div
              className="w-3/5 cursor-pointer"
              onClick={() => {
                window.open(getInvestors.pitch_deck_link, "_blank");
              }}
            >
              <p className="font-PlusJakartaSansRegular break-words  text-blue-500 underline hover:text-blue-700 text-sm">
                {getInvestors.pitch_deck_link}
              </p>
            </div>
          </div>

          <div className="flex flex-row justify-center items-center">
            <div className="w-2/5 flex flex-row justify-start items-center">
              <EIBurnIcon className="w-6 h-6 flex-shrink-0 " />
              <div className="ml-4">
                {" "}
                <Menutext label={t("Burn")} />
              </div>
            </div>
            <div className="w-3/5">
              {" "}
              <Menutext label={t("rupess") + getInvestors.burn} />
            </div>
          </div>

          <div className="flex flex-row justify-center items-center">
            <div className="w-2/5 flex flex-row justify-start items-center">
              <EIBurnIcon className="w-6 h-6 flex-shrink-0 " />
              <div className="ml-4">
                {" "}
                <Menutext label={t("ask")} />
              </div>
            </div>
            <div className="w-3/5">
              {" "}
              <Menutext label={t("rupess") + getInvestors.ask} />
            </div>
          </div>

          <div className="flex flex-row justify-center items-center">
            <div className="w-2/5 flex flex-row justify-start items-center">
              <EIRevenueIcon className="w-6 h-6 flex-shrink-0 " />
              <div className="ml-4">
                {" "}
                <Menutext label={t("Revenue")} />
              </div>
            </div>
            <div className="w-3/5">
              {" "}
              <Menutext label={t("rupess") + getInvestors.revenue} />
            </div>
          </div>

          <div className="flex flex-row justify-center items-center">
            <div className="w-2/5 flex flex-row justify-start items-center">
              <EIOnepagerIcon className="w-6 h-6 flex-shrink-0 " />
              <div className="ml-4">
                {" "}
                <Menutext label={t("OnePagerLink")} />
              </div>
            </div>
            <div className="w-3/5">
              <Menutext label={getInvestors.one_page_link} />
            </div>
          </div>
        </div>
        <div className="mt-5">
          <DashboardTitle label={t("Notes")} />
          <div className="mt-4 border border-mBorderColor rounded-md bg-white">
            <textarea
              id="message"
              rows="5"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              // className="block h-28 resize-none overflow-y-auto w-full border-black  text-sm break-words text-mTextColor placeholder-mTextColorLogin px-2 py-3 text-start justify-start items-start  rounded-md   font-PlusJakartaSansRegular border-none focus:border-none  bg-white border-4 !outline-none"
              className="h-32 w-full resize-none overflow-y-auto  border-mBorderColor p-2 rounded-md text-sm break-words text-mTextColor placeholder-mTextColorLogin text-start justify-start items-start font-PlusJakartaSansRegular   bg-white !outline-none"
              placeholder={"Write something..."}
            ></textarea>
          </div>
        </div>
        <div className="mt-4">
          <PrimaryButton
            label={t("save")}
            onClick={submitClick}
            onDisable={isDisable}
          ></PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default DealsDetails;
