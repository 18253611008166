import React from "react";

const MiniLoader = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="flex space-x-1 justify-center items-center">
        <div className="w-2 h-2 bg-mButtonColor rounded-full animate-bounce"></div>
        <div className="w-2 h-2 bg-mButtonColor rounded-full animate-bounce200"></div>
        <div className="w-2 h-2 bg-mButtonColor rounded-full animate-bounce400"></div>
        <p className=" pl-2 font-PlusJakartaSansRegular text-mTextColor text-sm">
          Processing...
        </p>
        {/* <div className="w-2 h-2 bg-mButtonColor rounded-full animate-bounce400"></div>
        <div className="w-2 h-2 bg-mButtonColor rounded-full animate-bounce400"></div> */}
      </div>
    </div>
  );
};

export default MiniLoader;
