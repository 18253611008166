import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../Utils/Loader";
import {
  buySubscription,
  getSubscriptionplan,
  mGetpdfanddownloadcount,
  mGetslideimages,
  mUserFeedback,
  paymentordercreate,
} from "../../Services/allAPI";
import { toast } from "react-toastify";
import PrimaryButton from "../../Components/PrimaryButton";

import { Images } from "../../Utils/Constants";
import SideMenu from "../../Components/SideMenu";
import { ReactComponent as BackIcon } from "../../assets/Images/ic_summeryback.svg";
import { ReactComponent as ChatIcon } from "../../assets/Images/ic_chat.svg";
import { ReactComponent as TickIcon } from "../../assets/Images/ic_tick.svg";
import HeaderMenu from "../../Components/HeaderMenu";
import Headingtext from "../../Components/Headingtext";
import DashboardTitle from "../../Components/DashboardTitle";
import Titletext from "../../Components/Titletext";
import { UserAlertDialog } from "../../Components/UserAlertDialog";

const Subscription = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const [subscriptionPlan, setsubscriptionPlan] = useState([]);

  const navigate = useNavigate();

  // Razorpay

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  //Set user Alert Msg
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  useEffect(() => {
    if (!UserInfoData.view_status) {
      GetsubscriptionListAPICall();

      setIsAlertOpen(true);
    }
  }, []);

  //Set user Alert close
  const closeAlert = () => setIsAlertOpen(false);

  //Set user Alert reload page
  const Confirm = () => {
    setIsAlertOpen(false);
    window.location.reload();
  };

  const GetsubscriptionListAPICall = async () => {
    setLoading(true);
    const response = await getSubscriptionplan({
      user_name: UserInfoData.user_email,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setsubscriptionPlan(response?.data.message.subscription_plan_details);

        //console.log("API Response -> " + serviceDetails.process_steps[0].steps);
      } else {
        toast.error(response?.data.message.status);
        console.log(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }

    //  await AsyncStorage.setItem('userInfo', JSON.stringify(response?.data));
  };

  const mBackClick = () => {
    navigate("/dashboard");
  };

  // Calculate GST

  const GST_RATE = 18; // 18% GST

  const calculateGST = (amount) => {
    return (amount * GST_RATE) / 100;
  };

  // Function to calculate total amount including GST
  const calculateTotalAmount = (amount) => {
    return parseFloat(amount) + calculateGST(amount);
  };

  const proClick = (item) => {
    // BuySubscription(item);
    handlePayment(item);
  };

  const handlePayment = async (item) => {
    var price = item.offer_price * 100;
    const response = await paymentordercreate({
      amount: price,
    });

    if (response.status === 200) {
      try {
        // Configure Razorpay options
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Razorpay API key
          amount: response?.data.message.order_id.amount,
          currency: response?.data.message.order_id.currency,
          name: "theprevc",
          description:
            UserInfoData.user_name +
            " Purchase the Subscription " +
            item.plan_name,
          order_id: response?.data.message.order_id.id,
          handler: function (paymentresponse) {
            // Handle successful payment here
            console.log(JSON.stringify(paymentresponse));
            console.log(response?.data.message.order_id.amount);
            console.log("Payment Success:", JSON.stringify(paymentresponse));
            BuySubscription(
              response?.data.message.order_id.amount,
              paymentresponse.razorpay_payment_id,
              paymentresponse.razorpay_order_id,
              item
            );
          },
          prefill: {
            name: UserInfoData.user_name,
            email: UserInfoData.user_email,
            //  contact: "9999999999",
          },
          theme: {
            color: "#2A7A5A",
          },
        };

        // Open Razorpay checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } catch (error) {
        console.error("Payment error:", error);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const BuySubscription = async (Amount, paymentId, orderId, item) => {
    setLoading(true);
    const response = await buySubscription({
      user_name: UserInfoData.user_email,
      plan_id: item.id,
      plan_amount: Amount / 100,
      paid_amount: calculateTotalAmount(item.offer_price),
      payment_id: paymentId,
      payment_status: "Paid",
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        GetsubscriptionListAPICall();
      } else {
        toast.error(response?.data.message.status);
        console.log(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex h-screen overflow-hidden bg-mDashboardBg  bg-no-repeat bg-cover">
            {/* Sidebar */}

            {/* Main content */}
            <div className="flex flex-col flex-grow overflow-hidden  bg-mDashboardBg">
              {/* Top Navigation */}

              <HeaderMenu
                title={t("Subscription")}
                isShowing={true}
                notShowsubscription={true}
                onClick={mBackClick}
              />

              {/* Scrollable content scrollbar-hide */}
              <main
                className={`flex-grow  overflow-auto  mt-2 mb-2 lg:mr-2 py-2 px-3 lg:py-8 lg:px-28 ${
                  !UserInfoData.view_status ? "blur-sm pointer-events-none" : ""
                }`}
              >
                <div>
                  <Titletext label={t("Chooseyourplan")} />
                  <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-10 w-full mt-3 lg:mt-7 p-1 lg:p-5  bg-mDashboardBg">
                    {subscriptionPlan.map((item) => (
                      <div
                        className={`p-3 lg:p-6 grid grid-cols-1 gap-5 rounded-xl md:rounded-2xl lg:rounded-3xl ${
                          item.current_plan_purchase
                            ? "bg-mButtonColor"
                            : "bg-white"
                        } shadow-lg`}
                        key={item.id}
                      >
                        <div className="flex flex-row justify-between">
                          <Titletext
                            label={item.plan_name}
                            changecolor={item.current_plan_purchase}
                          />
                          {item.recommended_plan_status ? (
                            <div className="rounded-full bg-mTextColor p-2 h-8">
                              <p
                                className={`font-PlusJakartaSansRegular text-xs text-white`}
                              >
                                {t("Recommended")}
                              </p>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>

                        <div className="justify-center w-full items-center flex flex-col py-2">
                          <p
                            className={`font-PlusJakartaSansRegular text-sm xl:text-base 3xl:text-base ${
                              item.current_plan_purchase
                                ? "text-white"
                                : "text-mTextColorLogin"
                            } line-through`}
                          >
                            {"₹ " + item.format_actual_plan_price}
                          </p>

                          <div className="flex flex-row justify-center items-center">
                            <Headingtext
                              label={"₹ " + item.format_offer_price}
                              changecolor={item.current_plan_purchase}
                            />
                            <p
                              className={`font-PlusJakartaSansRegular text-xs xl:text-sm 3xl:text-sm ${
                                item.current_plan_purchase
                                  ? "text-white"
                                  : "text-mTextColorLogin"
                              } ml-1 `}
                            >
                              {"Offer Price"}
                            </p>
                          </div>
                        </div>

                        <div
                          className={`md:h-14 h-11 w-full mt-2 lg:mt-10  ${
                            item.current_plan_purchase
                              ? "bg-black"
                              : "bg-mButtonColorligth"
                          }  rounded-lg items-center justify-center flex cursor-pointer`}
                          onClick={() => proClick(item)}
                        >
                          <p
                            className={`ml-2 font-PlusJakartaSansSemiBold  ${
                              item.current_plan_purchase
                                ? "text-white"
                                : "text-mButtonColor"
                            }  text-sm xl:text-base 3xl:text-base`}
                          >
                            {t("GetPro")}
                          </p>
                        </div>

                        <div className="flex flex-col justify-start w-full items-start">
                          <DashboardTitle
                            label={t("Features")}
                            changecolor={item.current_plan_purchase}
                          />

                          <div className="grid grid-cols-1 gap-4 w-full mt-5">
                            {item.features.map((item1) => (
                              <div
                                className="flex flex-row  justify-start items-center"
                                key={item1.plan_feature}
                              >
                                <TickIcon className="w-4 h-4 md:w-5 md:h-5 flex-shrink-0 " />
                                <p
                                  className={`font-PlusJakartaSansRegular text-sm xl:text-base 3xl:text-base ${
                                    item.current_plan_purchase
                                      ? "text-white"
                                      : "text-mTextColorLogin"
                                  }  ml-2`}
                                >
                                  {item1.plan_feature}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </main>
            </div>
            <UserAlertDialog
              isOpen={isAlertOpen}
              onClose={closeAlert}
              Confirm={Confirm}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Subscription;
