import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import { Images } from "../../Utils/Constants";
import {
  deleteFile,
  getVault,
  invitePeople,
  mGetDashboard,
} from "../../Services/allAPI";
import { toast } from "react-toastify";

import Headingtext from "../../Components/Headingtext";
import HeaderMenu from "../../Components/HeaderMenu";
import { ReactComponent as AddIcon } from "../../assets/Images/ic_add.svg";
import { ReactComponent as RightIcon } from "../../assets/Images/ic_leftarrow.svg";
import { ReactComponent as DownarrowIcon } from "../../assets/Images/ic_downarrow.svg";
import { ReactComponent as SharefileIcon } from "../../assets/Images/ic_sharefile.svg";
import { ReactComponent as DottedIcon } from "../../assets/Images/ic_dotted.svg";
import { ReactComponent as DownloadIcon } from "../../assets/Images/ic_download.svg";
import CreateNewVault from "./CreateNewVault";
import TextView from "../../Components/TextView";
import { ReactComponent as MenuEditIcon } from "../../assets/Images/ic_menuedit.svg";
import { ReactComponent as MenuDeleteIcon } from "../../assets/Images/ic_menudelete.svg";
import CreateNewFile from "./CreateNewFile";
import { ReactComponent as MenuIcon } from "../../assets/Images/ic_menu.svg";
import { ReactComponent as Docclose } from "../../assets/Images/new_ic_docclose.svg";

import DeleteDialog from "../../Components/DeleteDialog";
import ShareFile from "./ShareFile";
import { AlertDialog } from "../../Components/AlertDialog";

const ValutDetails = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [dashboarddata, setDashboardData] = useState([
    { deck_profile_image: "test", id: "" },
  ]);
  const menuRef = useRef(null);
  const [isEdit, setisEdit] = useState(false);
  const [Title, setTitle] = useState(t("CreateNewFile"));
  const [buttonname, setButtonname] = useState(t("uploadnow"));
  const [getFiles, setgetFiles] = useState([]);
  const [selectedFiles, setselectedFiles] = useState({});
  const [getValutDetails, setValutDetails] = useState([]);
  const [isValut, setisValut] = useState(false);

  //Get Valut id

  const location = useLocation();
  const Valut_Id = location.state?.mValut_Id || "Deck-Review-0023";

  // Add new file
  const [newFileShow, setnewFileShow] = useState(false);

  // Show share option
  const [mShareFile, setmShareFile] = useState(false);
  const [invitepeople, setInvitepeople] = useState([]);

  const newfileCreate = () => {
    getValutAPICall();
    setnewFileShow(false);
  };

  const clickClose = () => {
    setnewFileShow(false);
  };

  const shareClose = () => {
    setmShareFile(false);
  };

  // Hide menu start

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  //Hide menu start

  // Add new file end

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  //
  useEffect(() => {
    getValutAPICall();
    getinvitePeople();
  }, []);

  // Service Page API call
  const getValutAPICall = async () => {
    setLoading(true);
    const response = await getVault({
      vault_id: Valut_Id,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        // setDashboardData(response?.data.message.deck_details);
        setgetFiles(response?.data.message.vault_details.file_upload);
        setValutDetails(response?.data.message.vault_details);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  // Update new File API call
  const getinvitePeople = async () => {
    setLoading(true);
    const response = await invitePeople({});
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setInvitepeople(response?.data.message.invite_people);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const newvalutClick = () => {
    setActiveMenu(null);
    setnewFileShow(true);
    setisEdit(false);
    setTitle(t("CreateNewFile"));
    setButtonname(t("uploadnow"));
  };

  // const items = [
  //   { id: 1, name: "Item 1" },
  //   { id: 2, name: "Item 2" },
  //   { id: 3, name: "Item 3" },
  //   { id: 4, name: "Item 4" },
  //   { id: 5, name: "Item 5" },
  //   { id: 6, name: "Item 6" },
  // ];

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };

  const clickEdit = (item, event) => {
    // alert(`Edit ${item.Task}`);
    setselectedFiles(item);
    setActiveMenu(null);
    setnewFileShow(true);
    setisEdit(false);

    setTitle(t("EditDetails"));
    setButtonname(t("update"));
  };

  const clickDownload = (item, event) => {
    setActiveMenu(null);
    setIsDialogOpen(true);
  };

  const clickShare = (mFrom, item, event) => {
    setisValut(mFrom === "Valut" ? true : false);
    // alert(isValut);

    setActiveMenu(null);
    setselectedFiles(item);
    setmShareFile(true);
  };

  const clickDelete = (item, event) => {
    setActiveMenu(null);
    setIsDialogOpen(true);
    setselectedFiles(item);
  };

  //Delete dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const confirmDelete = () => {
    // Perform delete action here
    console.log("Item deleted");
    ondeleteAPICall();
  };

  // Service Page API call
  const ondeleteAPICall = async () => {
    setLoading(true);
    const response = await deleteFile({
      user_name: UserInfoData.user_email,
      vault_id: Valut_Id,
      file_id: selectedFiles.doc_id,
      delete: 1,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        closeDialog();
        getValutAPICall();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const onBack = () => {
    navigate("/valut");
  };

  // Open or download file

  const openUrl = (pdfurl) => {
    setActiveMenu(null);
    window.open(pdfurl, "_blank");
  };
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [description, setdescription] = useState("");
  const infoclick = (description) => {
    setIsAlertOpen(true);
    setdescription(description);
  };

  const closeAlert = () => setIsAlertOpen(false);

  // Delete dialog end

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex h-screen overflow-hidden bg-gray-100">
          {/* Sidebar */}
          <SideMenu mFrom={5} mProfile={-1} />

          {/* Main content */}
          <div className="flex flex-col flex-grow overflow-hidden ml-4 mr-4 mt-3">
            {/* Top Navigation */}
            <HeaderMenu title={t("Valut")} />

            {/* Scrollable content */}
            <main className="flex-grow overflow-auto  mt-1 mb-3 scrollbar-hide ">
              {/* Adjusted padding top to avoid overlap */}

              <div className=" w-full justify-between flex flex-col md:flex-row mt-5">
                <div className="h-9 items-center justify-start md:justify-center flex ">
                  <div className="cursor-pointer " onClick={onBack}>
                    <p className="font-PlusJakartaSansSemiBold text-sm md:text-base xl:text-lg 3xl:text-lg text-mTextColorLogin">
                      Vault
                    </p>
                  </div>
                  <RightIcon className="w-4 h-4 md:w-5 md:h-5 xl:w-6 xl:h-6  flex-shrink-0 ml-2" />
                  <p className="font-PlusJakartaSansSemiBold text-sm md:text-base xl:text-lg 3xl:text-lg text-mTextColor ml-2">
                    {getValutDetails.vault_name}
                  </p>
                  <div
                    className="ml-2 mt-1 cursor-pointer  justify-center items-center flex"
                    onClick={() => infoclick(getValutDetails.description)}
                  >
                    <img
                      src={Images.infobtn}
                      alt="Profile"
                      className="cursor-pointer w-5 h-5"
                    />
                    {/* <SharefileIcon className="w-4 h-4 md:w-6 md:h-6 mt-1 ml-1 flex-shrink-0 " /> */}
                  </div>
                </div>

                <div className="flex mt-5 md:mt-0 ">
                  <div
                    className=" h-8 md:h-9  3xl:h-9 rounded-lg bg-white items-center justify-center flex  p-2 md:p-3 cursor-pointer mr-3 md:mr-6"
                    onClick={() => clickShare("Valut")}
                  >
                    <TextView label={t("ShareLink")} />
                    <SharefileIcon className="w-4 h-4 md:w-6 md:h-6 mt-1 ml-1 flex-shrink-0 " />
                  </div>
                  {/* <div
                    className="h-9  rounded-lg bg-white items-center justify-center flex p-3 cursor-pointer mr-6"
                    onClick={newvalutClick}
                  >
                    <TextView label={t("DocumentType")} />
                    <DownarrowIcon className="w-6 h-6 mt-1 ml-2 flex-shrink-0 " />
                  </div> */}
                  <div
                    className=" h-8 md:h-9 3xl:h-9 rounded-lg bg-mButtonColor items-center justify-center flex p-2 md:p-3 cursor-pointer"
                    onClick={newvalutClick}
                  >
                    <AddIcon className="w-5 h-5 md:w-6 md:h-6 flex-shrink-0 " />
                    <p className="ml-1 md:ml-2 font-PlusJakartaSansRegular text-white text-sm md:text-base xl:text-lg 3xl:text-lg">
                      {t("NewFile")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="grid-container mt-5">
                {getFiles.map((item) => (
                  <div
                    key={item.doc_id}
                    className="grid-itemValutdetails bg-white flex justify-start items-center w-full"
                  >
                    <div
                      className="w-[95%] flex justify-start items-center "
                      // onClick={() => clickEdit(item)}
                    >
                      <div className="w-1/5  h-full justify-center items-center flex">
                        {/* <img
                          src={Images.folderimage}
                          alt="Profile"
                          className="cursor-pointer w-9 h-9"
                          onClick={() => clickEdit(item)}
                        /> */}

                        {item.file_type === "pdf" ? (
                          <img
                            src={Images.Pdf}
                            alt="Profile"
                            className="cursor-pointer w-9 h-9"
                            onClick={() => clickEdit(item)}
                          />
                        ) : item.file_type === "docx" ||
                          item.file_type === "doc" ? (
                          <img
                            src={Images.Word}
                            alt="Profile"
                            className="cursor-pointer w-9 h-9"
                            onClick={() => clickEdit(item)}
                          />
                        ) : item.file_type === "xlsx" ? (
                          <img
                            src={Images.Excel}
                            alt="Profile"
                            className="cursor-pointer w-9 h-9"
                            onClick={() => clickEdit(item)}
                          />
                        ) : item.file_type === "png" ||
                          item.file_type === "jpg" ||
                          item.file_type === "jpeg" ? (
                          // <Watermark className="w-14 h-14  " />
                          <img
                            src={Images.Image}
                            alt="Profile"
                            className="cursor-pointer w-9 h-9"
                            onClick={() => clickEdit(item)}
                          />
                        ) : (
                          <img
                            src={Images.Image}
                            alt="Profile"
                            className="cursor-pointer w-9 h-9"
                            onClick={() => clickEdit(item)}
                          />
                        )}
                      </div>
                      <div className="w-4/5 h-full mr-1 ml-1">
                        <p className="font-PlusJakartaSansRegular text-mTextColor text-sm break-words break-all text-left  xl:text-sm 3xl:text-sm items-center justify-start flex">
                          {item.file_name}
                        </p>
                      </div>
                    </div>
                    <div className="w-[5%] cursor-pointer items-center justify-center flex">
                      <MenuIcon
                        className="w-8 h-8 flex-shrink-0 "
                        onClick={() => toggleMenu(item.doc_id)}
                      />
                    </div>

                    {activeMenu === item.doc_id && (
                      <div
                        className="popup-menuValutdetails right-2 mt-2 w-40 bg-white p-3 rounded-xl shadow-xl shadow-mChatbg z-10"
                        ref={menuRef}
                      >
                        <ul>
                          <li
                            className="px-2 py-2 mt-2 cursor-pointer"
                            onClick={() => openUrl(item.file)}
                          >
                            <div className="flex flex-row justify-start items-center">
                              <DownloadIcon className="w-5 h-5 3xl:w-5 3xl:h-5 flex-shrink-0 " />
                              <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base  3xl:text-base ml-3">
                                {t("download")}{" "}
                              </p>
                            </div>
                          </li>

                          <li
                            className="px-2 py-2  cursor-pointer"
                            onClick={() => clickEdit(item)}
                          >
                            <div className="flex flex-row justify-start items-center">
                              <MenuEditIcon className="w-5 h-5 3xl:w-5 3xl:h-5 flex-shrink-0 " />
                              <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base 3xl:text-base ml-3">
                                {t("EditDetails")}{" "}
                              </p>
                            </div>
                          </li>

                          <li
                            className="px-2 py-2 mt-2 cursor-pointer"
                            onClick={() => clickShare("Item", item)}
                          >
                            <div className="flex flex-row justify-start items-center">
                              <SharefileIcon className="w-5 h-5 3xl:w-5 3xl:h-5 flex-shrink-0 " />
                              <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base 3xl:text-base ml-3">
                                {t("Share")}{" "}
                              </p>
                            </div>
                          </li>
                          <li
                            className="px-2 py-2 mt-2 cursor-pointer"
                            onClick={() => clickDelete(item)}
                          >
                            <div className="flex flex-row justify-start items-center">
                              <MenuDeleteIcon className="w-5 h-5 3xl:w-5 3xl:h-5 flex-shrink-0 " />
                              <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base 3xl:text-base ml-3">
                                {t("Delete")}{" "}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </main>

            {newFileShow ? (
              <CreateNewFile
                show={newFileShow}
                onConfirm={newfileCreate}
                onCancel={clickClose}
                Title={Title}
                selectedItem={selectedFiles}
                valutdetails={getValutDetails}
                isEdit={isEdit}
                buttonname={buttonname}
              />
            ) : (
              <div></div>
            )}

            {mShareFile ? (
              <ShareFile
                show={mShareFile}
                onConfirm={newfileCreate}
                onCancel={shareClose}
                invitepeople={invitepeople}
                selectedItem={selectedFiles}
                isShareValut={isValut}
                valutId={Valut_Id}
              />
            ) : (
              <div></div>
            )}

            <DeleteDialog
              isOpen={isDialogOpen}
              onCancel={closeDialog}
              onConfirm={confirmDelete}
              title={"Delete Confirmation"}
              message={" Are you sure you want to delete this item? "}
            />

            <AlertDialog
              isOpen={isAlertOpen}
              onClose={closeAlert}
              message={description}
              isShowDes={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ValutDetails;
