import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import {
  getchatconversation,
  getchatconversationlist,
  getChatlist,
  mGetDashboard,
  mServices,
} from "../../Services/allAPI";
import { toast } from "react-toastify";
import HeaderMenu from "../../Components/HeaderMenu";
import DashboardTitle from "../../Components/DashboardTitle";
import Headingtext from "../../Components/Headingtext";
import { toPng } from "html-to-image";
import { TwitterShareButton, LinkedinShareButton } from "react-share";
import LogoText from "../../Components/LogoText";
import TextView from "../../Components/TextView";
import { ReactComponent as ShareIcon } from "../../assets/Images/ic_share.svg";
import { ReactComponent as Icondrop } from "../../assets/Images/ic_dropdown.svg";
import { ReactComponent as QuestionIcon } from "../../assets/Images/question.svg";
import {
  formatTextWithLineBreaks,
  Images,
  isEmptyObject,
} from "../../Utils/Constants";
import LogoTextsmall from "../../Components/LogoTextsmall";
import { UserAlertDialog } from "../../Components/UserAlertDialog";
import { ReactComponent as Filter } from "../../assets/Images/ic_filter.svg";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";
import SuggestedStartupInvestors from "../StartupCRM/SuggestedStartupInvestors";
import MiniLoader from "../../Components/MiniLoader";

const Zenchat = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [mClickPostion, setmClickPostion] = useState(0);
  const [message, setMessage] = useState("");
  const [Chat, setChat] = useState([]);
  const [DeckList, setDeckList] = useState([]);
  const [SelectedDeck, setSelectedDeck] = useState("general-001");
  const [ChatQuestion, setChatQuestion] = useState({});
  const [ChatType, setChatType] = useState("Learn");
  const [ChatTopics, setChatTopics] = useState("");
  const [ChatTypeid, setChatTypeid] = useState("");
  const bottomRef = useRef(null); // Reference to the last item
  const [ChatConversation, setChatConversation] = useState([]);
  //const [SelectedChatList, setSelectedChatList] = useState([]);
  const [Index, setIndex] = useState(0);
  const [showDialog, setshowDialog] = useState(false);
  const [data, setData] = useState(null);

  const apiCalledRef = useRef(false); // Ref to track if APIs were already called

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  // Service Page API call
  const ChatListAPICall = async (chatType, mChatTopics) => {
    setLoading(true);
    const response = await getChatlist({
      user_name: UserInfoData.user_email,
      type_of_chat: mChatTopics,
      deck_id: SelectedDeck,
      prompt_type: chatType,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        // setmClickPostion(0);
        setChat(response?.data.message.chat_list);
        setDeckList(response?.data.message.deck_list);
        setSelectedDeck(response?.data.message.deck_list[0].id);
        setSelectedOption(response?.data.message.deck_list[0].deck_name);
        setSelectedOptionid(response?.data.message.deck_list[0].id);
        setChatQuestion(response?.data.message.chat_list[0]);
        setChatTopics(response?.data.message.chat_list[0].title);
        setChatTypeid(response?.data.message.chat_list[0].id);
        const result = JSON.stringify(response?.data.message);
        setData(result);
        // apiCalledRef.current = false;
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  useEffect(() => {
    if (!apiCalledRef.current) {
      apiCalledRef.current = true; // Mark the ref to avoid multiple calls
      ChatListAPICall(ChatType, ChatTopics);
    }
  }, [ChatTypeid]);

  // useEffect to run after data is fetched
  useEffect(() => {
    if (data) {
      // alert(SelectedDeck + "  --- " + ChatTopics);
      ChatConversationListAPICall(ChatTopics, ChatType, SelectedDeck);
    }
  }, [data]);

  // useEffect(() => {
  //   alert(SelectedDeck + "  --- " + ChatTopics);
  //   if (SelectedDeck && ChatTopics) {
  //     ChatConversationListAPICall(ChatTopics, ChatType);
  //   }
  // }, [SelectedDeck, ChatTopics]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [ChatQuestion, ChatConversation, Chat]);

  const ChatConversationListAPICall = async (
    ChatTopics,
    ChatType,
    mSelectedDeck
  ) => {
    setLoading(true);
    const response = await getchatconversationlist({
      user_name: UserInfoData.user_email,
      deck_id: mSelectedDeck,
      type_of_chat: ChatTopics,
      prompt_type: ChatType,

      // user_prompt: "teach some ideas for increse my valuvation",
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setChatConversation(response?.data.message.chat_conversation);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const mChatType = [
    { id: 1, name: "Learn" },
    { id: 2, name: "Task" },
    { id: 3, name: "Discuss" },
  ];

  // Handle proflie menu Click
  const chatTopicsClick = (index, getchat) => {
    setmClickPostion(index);
    setChatQuestion(getchat);

    setChatTopics(getchat.title);

    setIndex(index);

    setshowDialog(false);

    ChatConversationListAPICall(getchat.title, ChatType, SelectedDeck);
  };

  const chatTypeClick = (index, chatType) => {
    setChatTypeid(chatType.id);
    setChatType(chatType.name);
    setTypeIsOpen(false);

    //ChatListAPICall(chatType.name, ChatTopics);
    ChatListAPICall(chatType.name, "");
    // ChatConversationListAPICall(ChatTopics, chatType.name);

    //dhsgljkhdgsk
    if (index === 0) {
    } else if (index === 1) {
    }
  };

  // Dropdown start

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select your Deck");
  const [selectedOptionid, setSelectedOptionid] = useState("");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Chat type Dropdown

  const [isTypeOpen, setTypeIsOpen] = useState(false);

  const toggleTypeDropdown = () => {
    setTypeIsOpen(!isTypeOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option.deck_name);
    setSelectedOptionid(option.id);

    setSelectedDeck(option.id);

    ChatConversationListAPICall(ChatTopics, ChatType, option.id);

    setIsOpen(false);
  };

  const QuestionClick = (Question) => {
    ChatConversationAPICall(Question);
  };

  const MessageSend = () => {
    if (message === "") {
      toast.error(t("entermsg"));
      return;
    }

    ChatConversationAPICall(message);
  };

  const ChatConversationAPICall = async (Question) => {
    setLoading(true);
    const response = await getchatconversation({
      user_name: UserInfoData.user_email,
      deck_id: SelectedDeck,
      type_of_chat: ChatTopics,
      prompt_type: ChatType,
      user_prompt: Question,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setChatConversation(response?.data.message.chat_conversation);

        // alert(JSON.stringify(response?.data.message.chat_conversation));

        // if (Index === 0) {
        //   setSelectedChatList(response?.data.message.message.idea_valuation);
        // } else if (Index === 1) {
        //   setSelectedChatList(response?.data.message.message.financial);
        // } else if (Index === 2) {
        //   setSelectedChatList(response?.data.message.message.market_research);
        // } else if (Index === 3) {
        //   setSelectedChatList(
        //     response?.data.message.message.product_market_fit
        //   );
        // }
        setMessage("");
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  //Dropdown end

  const mFilterView = () => {
    // setBoardView(true);
    setshowDialog(true);
  };

  const clickClose = () => {
    setshowDialog(false);
  };

  return (
    <>
      <div>
        <div className="flex h-screen  overflow-hidden  bg-no-repeat bg-cover">
          {/* Sidebar */}
          <SideMenu mFrom={2} mProfile={-1} />

          {/* Main content */}
          <div className="flex flex-col flex-grow overflow-hidden  bg-mDashboardBg">
            {/* Top Navigation */}

            <HeaderMenu title={t("ZenpivotChat")} />
            {/* blur-sm */}
            {/* Scrollable content scrollbar-hide */}

            {/* ${
                  !UserInfoData.view_status ? "blur-sm pointer-events-none" : ""
                } */}

            <main className={`flex-grow overflow-hidden p-2 lg:p-6  `}>
              <div className="justify-center items-center flex h-full">
                {/* <Headingtext label={"Coming Soon"} /> */}
                <div className="flex w-full flex-row h-full ">
                  <div className="hidden lg:block lg:w-1/5 h-full bg-white rounded-lg lg:py-4 lg:px-3 overflow-y-scroll scrollbar-hide mb-2">
                    <div className=" grid grid-cols-1 gap-7">
                      <div className="flex flex-row justify-between ">
                        <div className="justify-center items-center flex">
                          <DashboardTitle label={t("explore")} />
                        </div>
                      </div>
                      <div>
                        <ul className="">
                          {Chat.map((mchat, index) => (
                            <li
                              key={index}
                              onClick={() => chatTopicsClick(index, mchat)}
                            >
                              <div className=" py-2 ">
                                <div
                                  className={`flex rounded-md ${"cursor-pointer"} hover:bg-light-white text-gray-300 text-sm items-center flex-row py-2
                      ${
                        mClickPostion === index ? "bg-mMenuSelect" : "bg-white"
                      }  ${mClickPostion === index ? "px-2" : "px-0"} `}
                                >
                                  <p
                                    className={` font-PlusJakartaSansRegular font-normal text-mTextColor text-sm xl:text-base 3xl:text-base ml-4`}
                                  >
                                    {" "}
                                    {mchat.title}{" "}
                                  </p>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className=" w-full lg:w-4/5 h-full bg-white rounded-lg py-3 px-1 lg:py-4 lg:px-3 overflow-y-auto scrollbar-hide  lg:ml-5">
                    <div className="flex justify-between ">
                      <div className="hidden lg:block">
                        <LogoText label={t("appname")} />
                      </div>
                      <div className=" border-mBorderColor h-11 min-w-52 md:min-w-72 lg:min-w-96 rounded-lg p-3  border bg-white flex-row justify-between flex">
                        <div className="items-center justify-center flex  w-full">
                          <div className="relative   w-full">
                            <button
                              className="custom-dropdown-toggle"
                              onClick={toggleDropdown}
                            >
                              <p
                                className={`font-PlusJakartaSansRegular line-clamp-1  font-normal text-mTextColorLogin text-xs md:text-sm  3xl:text-sm `}
                              >
                                {selectedOption}
                              </p>
                            </button>

                            {/* Dropdown menu */}
                            <ul
                              className={`custom-dropdown-menu1  ${
                                isOpen ? "show" : ""
                              }`}
                            >
                              {DeckList.map((option) => (
                                <li
                                  key={option.id}
                                  className={`custom-dropdown-option  ${
                                    selectedOptionid === option.id
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleOptionClick(option)}
                                >
                                  <p
                                    className={`font-PlusJakartaSansRegular font-normal text-mTextColorLogin text-sm  3xl:text-sm ${
                                      selectedOptionid === option.id
                                        ? "text-white"
                                        : "text-mTextColorLogin"
                                    }`}
                                  >
                                    {option.deck_name}
                                  </p>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <Icondrop className="w-6 h-6 flex-shrink-0" />
                      </div>
                      <div
                        className="ml-2 mr-2 lg:hidden justify-center flex items-center h-auto"
                        onClick={() => mFilterView()}
                      >
                        <Filter className="w-5 h-5 flex-shrink-0 " />
                      </div>
                    </div>
                    <div className="h-[93%] mt-3 ">
                      <div className="h-[90%] w-full overflow-y-auto custom-scrollbar scrollbar-show focus:outline-none focus:overflow-y-auto">
                        <div className=" mb-2  ">
                          {ChatConversation.map((item) => (
                            <div key={item.chate_time}>
                              <div className="py-1 relative flex flex-grow flex-col px-2  justify-end">
                                {item.place === "Right" ? (
                                  <div className="ml-auto rounded-lg w-full lg:w-5/6 my-2 p-3 bg-mchatbdligth flex flex-row relative speech-bubble-right">
                                    {/* <div className="hidden lg:block w-1/12 justify-center items-start ">
                                        {UserInfoData.image_url.length === 0 ? (
                                          <img
                                            src={Images.profileimage}
                                            alt="Profile"
                                            className="w-11 h-11 rounded-full "
                                          />
                                        ) : (
                                          <img
                                            src={UserInfoData.image_url}
                                            alt="Profile"
                                            className="w-11 h-11 rounded-full "
                                          />
                                        )}
                                      </div>

                                      <div className="w-11/12 ml-2">
                                        <div className="h-11 justify-start flex items-center">
                                          <p className="text-lg text-mTextColorLogin font-PlusJakartaSansSemiBold ">
                                            {UserInfoData.user_name}
                                          </p>
                                        </div>

                                        <p className="text-base text-mTextColor font-PlusJakartaSansRegular ">
                                          {item.chat_box}
                                        </p>
                                      </div> */}
                                    <p className="text-base text-mTextColor font-PlusJakartaSansRegular ">
                                      {formatTextWithLineBreaks(item.chat_box)}
                                    </p>
                                  </div>
                                ) : (
                                  <div className="mr-auto rounded-lg w-full lg:w-5/6 my-2 p-3 bg-mMenuSelect flex flex-row relative speech-bubble-left">
                                    {/* <div className="hidden lg:block w-1/12 justify-center items-start ">
                                        <div className="w-11 h-11 rounded-full bg-white justify-center items-center flex">
                                          <LogoTextsmall />
                                        </div>
                                      </div>

                                      <div className="w-11/12 ml-2">
                                        <div className="h-11 justify-start flex items-center">
                                          <p className="text-lg text-mTextColorLogin font-PlusJakartaSansSemiBold ">
                                            Zen
                                          </p>
                                        </div>

                                        <p className="text-base text-mTextColor font-PlusJakartaSansRegular ">
                                          {item.chat_box}
                                        </p>
                                      </div> */}

                                    <p className="text-base text-mTextColor font-PlusJakartaSansRegular ">
                                      {formatTextWithLineBreaks(item.chat_box)}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}

                          {isEmptyObject(ChatQuestion) ? (
                            <div></div>
                          ) : (
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 w-full p-3">
                              {ChatQuestion.questions.map((item) => (
                                <div
                                  className="bg-white p-3 rounded-lg flex border border-mBorderColor min-h-[40px] justify-start items-center"
                                  key={item.question}
                                  onClick={() => QuestionClick(item.question)}
                                >
                                  <QuestionIcon className="hidden lg:block w-6 h-6 flex-shrink-0" />
                                  <p className="font-PlusJakartaSansRegular text-sm text-mTextColor ml-2">
                                    {item.question}
                                  </p>
                                </div>
                              ))}
                            </div>
                          )}

                          <div ref={bottomRef} />
                        </div>
                      </div>

                      <div className="h-8 justify-start flex pl-5 ">
                        {loading ? <MiniLoader /> : <div />}
                      </div>

                      <div className="h-[10%] w-full mt-2 ">
                        <div className="w-full flex h-12 bg-mMenuSelect rounded-lg ">
                          <div className="w-[90%] h-full  justify-start items-center flex px-2">
                            <div className="  ">
                              <div className="relative inline-block text-left min-w-28">
                                {/* Dropdown Toggle Button */}
                                <button
                                  className="inline-flex justify-center w-full rounded-md border text-mTextColor text-base  3xl:text-base bg-white placeholder-mTextColorLogin font-PlusJakartaSansRegular border-mBorderColor shadow-sm px-4 py-2 "
                                  onClick={toggleTypeDropdown}
                                >
                                  {ChatType}
                                </button>

                                {/* Dropdown Menu */}
                                {isTypeOpen && (
                                  <div className="origin-top-right absolute bottom-full mb-2  w-32 rounded-md shadow-lg border bg-mMenuSelect ">
                                    <div className="py-1">
                                      <ul className=" ">
                                        {mChatType.map((mchat, index) => (
                                          <li
                                            key={index}
                                            onClick={() =>
                                              chatTypeClick(index, mchat)
                                            }
                                          >
                                            <div
                                              className={`flex px-3  ${"cursor-pointer"} hover:bg-light-white text-gray-300 text-sm items-center flex-row py-2
                      ${
                        mchat.id === ChatTypeid ? "bg-mButtonColor" : "bg-white"
                      }  `}
                                            >
                                              {/* ChatTypeid */}
                                              <p
                                                className={` font-PlusJakartaSansRegular font-normal  ${
                                                  mchat.id === ChatTypeid
                                                    ? "text-white"
                                                    : "text-mTextColor"
                                                }  text-base `}
                                              >
                                                {mchat.name}{" "}
                                              </p>
                                            </div>
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="w-full ">
                              <div className="flex flex-row justify-between  bg-mMenuSelect rounded-lg p-3 w-full ">
                                <input
                                  type="email"
                                  placeholder="Ask anything"
                                  value={message}
                                  onChange={(e) => {
                                    setMessage(e.target.value);
                                  }}
                                  maxLength={1000}
                                  className={`text-base  3xl:text-base  text-mTextColor bg-mMenuSelect placeholder-mTextColorLogin font-PlusJakartaSansRegular font-normal w-full border-none focus:border-none  border-4 !outline-none`}
                                ></input>
                              </div>
                            </div>
                          </div>
                          <div
                            className="w-[10%] h-full justify-center items-center flex"
                            onClick={() => MessageSend()}
                          >
                            <ShareIcon className="w-6 h-6 flex-shrink-0" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                {showDialog && (
                  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  px-2 z-50">
                    {/* max-w-sm */}
                    <div className="bg-mDashboardBg rounded-md overflow-auto scrollbar-hide shadow-xl w-full py-4 pl-8 pr-4 h-90">
                      <div className="justify-between flex flex-col ">
                        <div className="w-full justify-end items-end flex">
                          <button onClick={clickClose}>
                            <CloseIcon className="" />
                          </button>
                        </div>
                        <div className=" grid grid-cols-1 gap-7">
                          <div className="flex flex-row justify-between ">
                            <div className="justify-center items-center flex">
                              <DashboardTitle label={t("explore")} />
                            </div>
                          </div>
                          <div>
                            <ul className="">
                              {Chat.map((mchat, index) => (
                                <li
                                  key={index}
                                  onClick={() => chatTopicsClick(index, mchat)}
                                >
                                  <div className=" py-2 ">
                                    <div
                                      className={`flex rounded-md ${"cursor-pointer"} hover:bg-light-white text-gray-300 text-sm items-center flex-row py-2
                      ${
                        mClickPostion === index
                          ? "bg-mMenuSelect"
                          : "bg-mMenuSelect"
                      }  ${mClickPostion === index ? "px-2" : "px-0"} `}
                                    >
                                      <p
                                        className={` font-PlusJakartaSansRegular font-normal text-mTextColor text-sm xl:text-base 3xl:text-base ml-4`}
                                      >
                                        {" "}
                                        {mchat.title}{" "}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </main>
          </div>
          {/* <UserAlertDialog
              isOpen={isAlertOpen}
              onClose={closeAlert}
              Confirm={Confirm}
            /> */}
        </div>
      </div>
    </>
  );
};

export default Zenchat;
