import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import {
  createsupportticket,
  FAQSuppport,
  getSupportTicket,
  mNotificationDetails,
  mServices,
} from "../../Services/allAPI";
import { toast } from "react-toastify";
import HeaderMenu from "../../Components/HeaderMenu";
import { ReactComponent as UparrowIcon } from "../../assets/Images/ic_helpuparrow.svg";
import { ReactComponent as DownarrowIcon } from "../../assets/Images/ic_helpdownarrow.svg";
import DashboardTitle from "../../Components/DashboardTitle";
import TextView from "../../Components/TextView";
import InputContainer from "../../Components/InputContainer";
import { ReactComponent as Icondrop } from "../../assets/Images/ic_dropdown.svg";
import PrimaryButton from "../../Components/PrimaryButton";
import { ReactComponent as Docclose } from "../../assets/Images/new_ic_docclose.svg";
import { ReactComponent as UploadIcon } from "../../assets/Images/ic_upload.svg";
import { ReactComponent as Watermark } from "../../assets/Images/new_ic_watermarkbg.svg";
import { useDispatch, useSelector } from "react-redux";
import { fetchMasterData } from "../../redux/slice/masterSlice";
import { ReactComponent as EIStatusIcon } from "../../assets/Images/EI_status.svg";
import Menutext from "../../Components/Menutext";
import ReadMoreText from "../../Components/ReadMoreText";

const HelpSupport = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = useState();
  const dispatch = useDispatch();

  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const [email, setEmail] = useState(UserInfoData.user_email);
  const [message, setMessage] = useState("");
  const [FAQSupport, setFAQSupport] = useState([]);
  const [supportticket, setSupportTicket] = useState([]);

  //Get User Details

  //
  useEffect(() => {
    FAQSuppportAPICall();
    SupportTicketList();
  }, []);

  // Service Page API call
  const FAQSuppportAPICall = async () => {
    setLoading(true);
    const response = await FAQSuppport({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setFAQSupport(response?.data.message.fsq_support);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const SupportTicketList = async () => {
    setLoading(true);
    const response = await getSupportTicket({
      user_name: UserInfoData.user_email,
      // user_name: "padhunpn@gmail.com",
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setSupportTicket(response?.data.message.support_ticket);

        //console.log("API Response -> " + serviceDetails.process_steps[0].steps);
      } else {
        toast.error(response?.data.message.status);
        console.log(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }

    //  await AsyncStorage.setItem('userInfo', JSON.stringify(response?.data));
  };

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select User Type");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const submitticketClick = () => {
    if (selectedOption === "Select User Type") {
      toast.error(t("SelectIssueType"));
      return;
    }
    onCreateSupport();
  };

  // Create Support API call
  const onCreateSupport = async () => {
    setLoading(true);

    const fileData =
      UploadFiles && UploadFiles.length > 0
        ? {
            file_type: UploadFiles[0].file_type,
            upload_file: UploadFiles[0].file_attach,
            file_name: UploadFiles[0].file_name,
          }
        : {
            file_type: "",
            upload_file: "",
            file_name: "",
          };

    const response = await createsupportticket({
      user_name: UserInfoData.user_email,
      ticket_type: selectedOption,
      message: message,

      file_type: fileData.file_type,
      upload_file: fileData.upload_file,
      file_name: fileData.file_name,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        setMessage("");
        setSelectedOption("Select User Type");
        setUploadFiles([]);
        SupportTicketList();
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  // File upload Start

  const [UploadFiles, setUploadFiles] = useState([]);
  const inputRef = useRef();

  const handleFileChange = (e) => {
    if (UploadFiles.length >= 10) {
      alert("You have reached your upload limit");
    } else {
      if (e.target.files && e.target.files.length > 0) {
        const reader = new FileReader();

        // reader.addEventListener("load", () => setImgSrc(reader.result));
        reader.readAsDataURL(e.target.files[0]);

        const file = e.target.files[0];
        if (file) {
          const fileName = file.name.split(".");
          const fileExtension = file.name.split(".").pop();
          console.log(file);
          console.log(fileName[0]);
          console.log(fileExtension);
          convertToBase64(file, fileName[0], fileExtension);
        }
      }
    }
  };

  const convertToBase64 = (file, fileName, fileExtension) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    console.log(fileExtension);
    reader.onloadend = () => {
      var attachFile = "";
      if (fileExtension === "pdf") {
        attachFile = reader.result.replace("data:application/pdf;base64,", "");
      } else if (fileExtension === "docx" || file === "doc") {
        attachFile = reader.result.replace(
          "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
          ""
        );
      } else if (fileExtension === "png") {
        attachFile = reader.result.replace("data:image/png;base64,", "");
      } else if (fileExtension === "jpg" || fileExtension === "jpeg") {
        attachFile = reader.result.replace("data:image/jpeg;base64,", "");
      } else if (fileExtension === "xlsx") {
        attachFile = reader.result.replace(
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          ""
        );
      }
      console.log(attachFile);
      let myObject = {
        file_type: fileExtension,
        file_attach: attachFile,
        file_name: fileName,
      }; // create an object

      setUploadFiles([...UploadFiles, myObject]);
      console.log(UploadFiles);
    };
    reader.onerror = (error) => {
      console.error("Error: ", error);
    };
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const onDeleteImage = (index) => {
    setUploadFiles(UploadFiles.filter((person, i) => i !== index));
  };

  //File upload end

  // Get Master data start

  useEffect(() => {
    dispatch(fetchMasterData());
  }, [dispatch]);

  const masterdataList = useSelector((state) => state.master.MasterdataList);
  let status = useSelector((state) => state.master.status);
  const error = useSelector((state) => state.master.error);

  console.log(status);

  if (status === "loading" || status === "idle") {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  // Get master data end

  const isDisabled = !email || !message;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="flex h-screen overflow-hidden  bg-no-repeat bg-cover">
            {/* Sidebar */}
            <SideMenu mFrom={-1} mProfile={1} />

            {/* Main content */}
            <div className="flex flex-col flex-grow overflow-hidden  bg-mDashboardBg">
              {/* Top Navigation */}

              <HeaderMenu title={t("HelpSupport")} />

              {/* Scrollable content scrollbar-hide */}
              <main className="flex-grow  overflow-auto mt-4 px-3 lg:px-8 py-2 lg:py-6 ">
                <div className="w-full h-auto flex flex-col lg:flex-row">
                  <div className="w-full lg:w-4/6 h-auto mr-2">
                    <div className="ml-1 hidden">
                      {" "}
                      <DashboardTitle label={t("Frequentsupportquestions")} />
                    </div>
                    <div className="w-full mt-5 hidden">
                      {FAQSupport.map((item, index) => (
                        <div
                          key={item.chapter_name}
                          className="mb-4  border-gray-300 bg-white rounded-lg"
                        >
                          <div
                            className="py-4  flex justify-between items-center  cursor-pointer"
                            onClick={() => handleToggle(index)}
                          >
                            <div className="w-full flex px-2">
                              <div className="w-11/12  flex flex-col">
                                <div className="mt-1.5">
                                  <span className="font-PlusJakartaSansRegular text-mTextColor text-sm md:text-base 3xl:text-base">
                                    {item.fsq_question}
                                  </span>
                                </div>
                              </div>
                              <div className="w-1/12  justify-center items-center flex">
                                <span>
                                  {expandedIndex === index ? (
                                    <UparrowIcon className="w-6 h-6 3xl:w-6 3xl:h-6" />
                                  ) : (
                                    <DownarrowIcon className="w-6 h-6 3xl:w-6 3xl:h-6" />
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>

                          {expandedIndex === index && (
                            <div className="   ">
                              <div className="p-4">
                                <p className="font-PlusJakartaSansRegular text-mTextColorLogin px-2 py-4 overflow-ellipsis text-sm xl:text-base  3xl:text-xl">
                                  {item.fsq_description}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                      <div className="h-14 w-full "></div>
                    </div>
                    <div className="ml-1">
                      {" "}
                      <DashboardTitle label={t("CustomerSupportstatus")} />
                    </div>
                    <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 w-full mt-3  bg-mDashboardBg">
                      {supportticket.map((item) => (
                        <div
                          className={`grid grid-cols-1 gap-5 rounded-xl  ${
                            item.current_plan_purchase
                              ? "bg-mButtonColor"
                              : "bg-white"
                          } shadow-lg`}
                          key={item.id}
                        >
                          <div className="w-full justify-end flex h-8 ">
                            <div className="w-2/5 bg-mButtonColor rounded-bl-xl justify-center items-center flex">
                              <p className="font-PlusJakartaSansSemiBold text-base text-white">
                                {item.ticket_status}
                              </p>
                            </div>
                          </div>
                          <div className="grid grid-cols-1 gap-5 px-5 pb-2">
                            <div className="flex flex-col justify-center items-center">
                              <div className="w-full flex flex-row justify-start items-center">
                                <div className="ml-2">
                                  {" "}
                                  <p className="font-PlusJakartaSansSemiBold text-base ">
                                    {" "}
                                    {t("ticket_type")}
                                  </p>
                                </div>
                              </div>
                              <div className="w-full flex  mt-2">
                                <div className="ml-2"></div>
                                <Menutext label={item.ticket_type} />
                              </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                              <div className="w-full flex flex-row justify-start items-center">
                                <div className="ml-2">
                                  {" "}
                                  <p className="font-PlusJakartaSansSemiBold text-base ">
                                    {" "}
                                    {t("description")}
                                  </p>
                                </div>
                              </div>
                              <div className="w-full flex  mt-2">
                                <div className="ml-2"></div>
                                <ReadMoreText text={item.description} />
                                {/* <p className="font-PlusJakartaSansRegular break-words leading-6 text-mTextColor text-sm line-clamp-2">
                                  {" "}
                                  {item.description}{" "}
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="w-full lg:w-2/6 h-auto ml-0 lg:ml-3 bg-white rounded-lg overflow-auto scrollbar-hide lg:rounded-2xl p-4 mt-4">
                    <div className="ml-1">
                      {" "}
                      <DashboardTitle label={t("CustomerSupport")} />
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                      <div className="hidden">
                        <div className="mt-4 ">
                          <TextView label={t("From")} ismandatory={true} />
                        </div>

                        <InputContainer
                          type="email"
                          value={email}
                          placeholder={t("Enteryouremail")}
                          label="email"
                          name="email"
                          disabled={false}
                          maxLength={60}
                          showIcon={false}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            // setforgetPwd({ ...forgetPwd, email: event.target.value });
                          }}
                        />
                      </div>
                      <div className="mt-4  ">
                        <TextView label={t("Issue")} ismandatory={true} />

                        <div className=" border-mBorderColor h-14 rounded-lg p-3 w-full border bg-white mt-3 flex-row justify-between flex">
                          <div className="items-center justify-center flex  w-full">
                            <div className="relative  w-full">
                              <button
                                className="custom-dropdown-toggle"
                                onClick={toggleDropdown}
                              >
                                <p
                                  className={`font-PlusJakartaSansRegular font-normal text-mTextColorLogin text-sm  3xl:text-sm `}
                                >
                                  {selectedOption}
                                </p>
                              </button>

                              {/* Dropdown menu */}
                              <ul
                                className={`custom-dropdown-menu   ${
                                  isOpen ? "show" : ""
                                }`}
                              >
                                {masterdataList.ticket_type.map((option) => (
                                  <li
                                    key={option.name}
                                    className={`custom-dropdown-option ${
                                      selectedOption === option.name
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleOptionClick(option.name)
                                    }
                                  >
                                    <p
                                      className={`font-PlusJakartaSansRegular font-normal text-mTextColorLogin text-sm  3xl:text-sm ${
                                        selectedOption === option.name
                                          ? "text-white"
                                          : "text-mTextColorLogin"
                                      }`}
                                    >
                                      {option.name}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <Icondrop className="w-6 h-6 flex-shrink-0" />
                        </div>
                      </div>

                      <div className=" h-full w-full">
                        <TextView label={t("Yourmessage")} ismandatory={true} />

                        <div className="mt-2 border border-mBorderColor rounded-md bg-white">
                          <textarea
                            id="message"
                            rows="5"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            // className="block h-28 resize-none overflow-y-auto w-full border-black  text-sm break-words text-mTextColor placeholder-mTextColorLogin px-2 py-3 text-start justify-start items-start  rounded-md   font-PlusJakartaSansRegular border-none focus:border-none  bg-white border-4 !outline-none"
                            className="h-32 w-full resize-none overflow-y-auto  border-mBorderColor p-2 rounded-md text-sm  3xl:text-sm break-words text-mTextColor placeholder-mTextColorLogin text-start justify-start items-start font-PlusJakartaSansRegular   bg-white !outline-none"
                            placeholder={"How can we help you ?"}
                          ></textarea>
                        </div>
                      </div>

                      <div className="">
                        <div>
                          <div className="flex justify-between items-center">
                            <div>
                              <TextView label={t("UploadFiles")} />
                            </div>
                          </div>
                        </div>
                        <div className="min-h-44 h-auto mt-2 border-mGreyFour cursor-pointer text-start flex text-xs  lg:text-sm  rounded-md w-full  border bg-mLightColorOne items-center justify-center flex-col">
                          {UploadFiles.length === 0 ? (
                            <div
                              className=" cursor-pointer flex-grow justify-center items-center flex  h-full w-full "
                              onClick={onChooseFile}
                            >
                              <div className="flex h-full">
                                <UploadIcon className=" " />
                                <p className="font-PlusJakartaSansSemiBold text-sm  3xl:text-sm text-mButtonColor ml-3">
                                  {t("UploadFile")}
                                </p>
                              </div>

                              <input
                                type="file"
                                style={{
                                  display: "none ",
                                }}
                                ref={inputRef}
                                onChange={handleFileChange}
                                accept=".png,.jpg,.jpeg,"
                              />
                            </div>
                          ) : (
                            <div className="horizontal-list justify-center items-center flex">
                              {UploadFiles.map((item, index) => (
                                <div
                                  key={item.id}
                                  className="justify-center items-center flex p-3 "
                                >
                                  <div className="h-full w-full bg-white p-1  border-mGreyFive rounded-lg">
                                    <div className="w-full flex justify-end items-center ">
                                      <Docclose
                                        className="w-5 h-5 text-right "
                                        onClick={() => {
                                          onDeleteImage(index);
                                        }}
                                      />
                                    </div>

                                    <div className="w-full flex flex-row justify-center items-center mt-2">
                                      {item.file_type === "png" ? (
                                        <img
                                          src={
                                            "data:image/png;base64," +
                                            item.file_attach
                                          }
                                          alt="Base64 Example"
                                        />
                                      ) : item.file_type === "jpg" ||
                                        item.file_type === "jpeg" ? (
                                        <img
                                          src={
                                            "data:image/png;base64," +
                                            item.file_attach
                                          }
                                          alt="Base64 Example"
                                        />
                                      ) : (
                                        <Watermark className="w-14 h-14  " />
                                      )}
                                    </div>
                                    <p className="mt-1 font-OpenSauceSansRegular text-base text-mGreySeven text-center line-clamp-2">
                                      {item.file_name}.{item.file_type}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mt-4">
                        <PrimaryButton
                          label={t("SubmitTicket")}
                          onClick={() => submitticketClick()}
                          onDisable={isDisabled}
                        ></PrimaryButton>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HelpSupport;
