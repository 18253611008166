/* eslint-disable quotes */

export const GOOGLE_AUTO_SEARCH_API = `https://maps.googleapis.com/maps/api/place/autocomplete/json`;

export const GOOGLE_LAT_LONG_API = `https://maps.googleapis.com/maps/api/place/details/json`;

export const GOOGLE_PLACES_API_KEY = "AIzaSyCsSMOQKo0RE0mKvmqjQWMGhHmVVqqEmsU";

// export const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
// export const passwordPattern = /^.*(?=.{8,})/;

export const Fonts = {
  regular: "OpenSauceSans-Regular",
  light: "OpenSauceSans-Light",
  medium: "OpenSauceSans-Medium",
  bold: "OpenSauceSans-Bold",
  semi_bold: "OpenSauceSans-SemiBold",
};

export const Color = {
  mButtonColor: "#2A7A5A",
  mArcColor: "#345243",
  needleColor: "#888888",
  trackColor: "#0D0D0D1A",
};
export const Images = {
  upload_icon: require("../assets/Images/ic_cloud.png"),
  add_file: require("../assets/Images/ic_file.png"),
  invoice: require("../assets/Images/ic_invoice.png"),
  logout: require("../assets/Images/ic_logout.png"),
  review: require("../assets/Images/ic_review.png"),
  like: require("../assets/Images/ic_like.png"),
  likeclick: require("../assets/Images/ic_likeclick.png"),
  dislike: require("../assets/Images/ic_dislike.png"),
  dislikeclick: require("../assets/Images/ic_dislikeclick.png"),
  gridview: require("../assets/Images/gridview.png"),
  listview: require("../assets/Images/listview.png"),
  google: require("../assets/Images/ic_google.png"),
  delete: require("../assets/Images/delete.png"),

  zenpivot: require("../assets/Images/zenpivot.png"),
  applogo: require("../assets/Images/applogo.png"),
  PitchScorebg: require("../assets/Images/PitchScorebg.png"),
  companyImage: require("../assets/Images/companyImage.png"),
  userImage: require("../assets/Images/userImage.png"),
  share: require("../assets/Images/share.png"),
  linkedin: require("../assets/Images/linkedin.png"),
  twitter: require("../assets/Images/twitter.png"),
  whatsapp: require("../assets/Images/whatsapp.png"),
  gmail: require("../assets/Images/gmail.png"),
  infobtn: require("../assets/Images/infobtn.png"),

  loginbg: require("../assets/Images/loginbgg.png"),
  forgetpwd: require("../assets/Images/forgetpwd.png"),
  pitchdeckbg: require("../assets/Images/pitchdeckbgcopy.png"),
  scorebg: require("../assets/Images/scorebg.png"),
  questionbg: require("../assets/Images/questionbg.png"),
  investorbg: require("../assets/Images/investorbg.png"),
  profileimage: require("../assets/Images/ic_profile.png"),
  crownimage: require("../assets/Images/ic_crownimage.png"),
  folderimage: require("../assets/Images/new_ic_folder.png"),
  image: require("../assets/Images/image.png"),
  avatar: require("../assets/Images/avatar.png"),
  nodata: require("../assets/Images/ic_nodata.png"),
  Pdf: require("../assets/Images/new_ic_pdf.png"),
  Word: require("../assets/Images/new_ic_world.png"),
  Excel: require("../assets/Images/new_ic_excel.png"),
  Image: require("../assets/Images/new_ic_image.png"),
  icsend: require("../assets/Images/ic_send.png"),
  phonecall: require("../assets/Images/phone_call.png"),
};

export const Icons = {};

export const isValidMobileNumber = (number) => {
  // Regular expression for mobile number validation
  const mobileRegex = /^[6-9][0-9]{9}$/;
  return mobileRegex.test(number);
};

export const isValidEmail = (email) => {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const isValidPassword = (password) => {
  // Regular expression for email validation
  const emailRegex =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#/$&*~]).{8,}$/;
  return emailRegex.test(password);
};

export const isValidScore = (score) => {
  // return score === null
  //   ? ""
  //   : score.includes(":")
  //   ? score.split(":")[1].includes("/")
  //     ? score
  //         .split(":")[1]
  //         .split("/")[0]
  //         .replace(/\s*\(.*?\)/, "")
  //         .trim()
  //     : score
  //         .split(":")[1]
  //         .replace(/\s*\(.*?\)/, "")
  //         .trim()
  //   : score.includes("/")
  //   ? score
  //       .split("/")[0]
  //       .replace(/\s*\(.*?\)/, "")
  //       .trim()
  //   : score.replace(/\s*\(.*?\)/, "").trim();

  return score === null || score === undefined
    ? ""
    : score.split("/")[0].trim();
};

export const isValidScore1 = (score) => {
  return score === null
    ? ""
    : score.includes(":")
    ? score.split(":")[1].includes("/")
      ? score
          .split(":")[1]
          .split("/")[0]
          .replace(/\s*\(.*?\)/, "")
          .trim()
      : score
          .split(":")[1]
          .replace(/\s*\(.*?\)/, "")
          .trim()
    : score.includes("/")
    ? score
        .split("/")[0]
        .replace(/\s*\(.*?\)/, "")
        .trim()
    : score.replace(/\s*\(.*?\)/, "").trim();
};

export const isEmptyObject = (obj) => {
  return Object.entries(obj).length === 0;
};

export const ProgressBar = ({ progress }) => {
  return (
    <div className="bg-mBorderColor rounded-md">
      <div
        className="progress-bar"
        style={{
          width: `${progress}%`,
          backgroundColor: progress < 100 ? "#3A7D50" : "#3A7D50",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <span
          className="progress-text font-PlusJakartaSansSemiBold text-base"
          style={{}}
        >
          {progress}%
        </span>
      </div>
    </div>
  );
};

export const formatTextWithLineBreaks = (text) => {
  return text.split("\n").map((line, index) => (
    <span key={index}>
      {line}
      {index < text.split("\n").length - 1 && <br />}{" "}
      {/* Add line break except for the last line */}
    </span>
  ));
};

export const mTimerValue = 90000;
