import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";
import { ReactComponent as UploadIcon } from "../../assets/Images/ic_upload.svg";
import TextView from "../../Components/TextView";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import {
  invitePeople,
  shareFile,
  shareVault,
  updatenewFile,
  updateVault,
} from "../../Services/allAPI";
import { toast } from "react-toastify";
import Loader from "../../Utils/Loader";
import "../../Components/Autocomplete/Autocompletestyle.css";
import { Images } from "../../Utils/Constants";
import TextViewlight from "../../Components/TextViewlight";
import DashboardTitle from "../../Components/DashboardTitle";
import { ReactComponent as Docclose } from "../../assets/Images/new_ic_docclose.svg";
import { ReactComponent as Icondrop } from "../../assets/Images/ic_dropdown.svg";

const ShareFile = ({
  show,
  onConfirm,
  onCancel,
  invitepeople,
  selectedItem,
  isShareValut,
  valutId,
}) => {
  const { t } = useTranslation("");
  const [valutname, setValutName] = useState("");

  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);

  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const newVaultClick = () => {
    // alert(JSON.stringify(selectedPeople));

    if (isShareValut) {
      mShareValut();
    } else {
      mShareFile();
    }
  };

  // Service Page API call
  const mShareFile = async () => {
    setLoading(true);
    const response = await shareFile({
      user_name: UserInfoData.user_email,
      vault_id: valutId,
      invite_people: JSON.stringify(selectedPeople),
      file_id: selectedItem.doc_id,
      share_link: "http://localhost:3002/valutdetails/" + selectedItem.doc_id,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  // Service Page API call
  const mShareValut = async () => {
    setLoading(true);
    const response = await shareVault({
      user_name: UserInfoData.user_email,
      vault_id: valutId,
      invite_people: JSON.stringify(selectedPeople),
      file_id: "",
      share_link: "http://localhost:3002/valutdetails/" + valutId,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  // Filter start

  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedPeople, setselectedPeople] = useState([]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);

    // Filter suggestions based on input value
    const filteredSuggestions = invitepeople.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredSuggestions(filteredSuggestions);
  };

  const handleSelect = (value) => {
    setInputValue("");
    setFilteredSuggestions([]);

    let myObject = {
      email_id: value.email_id,
      full_name: value.full_name,
      view_status: "Can view only",
      image: value.image,
      id: value.id,
    }; // create an object

    if (selectedPeople.length === 0) {
      setselectedPeople([...selectedPeople, myObject]);
    }

    const exists = selectedPeople.some(
      (person) => person.email_id === value.email_id
    );

    if (!exists) {
      setselectedPeople([...selectedPeople, myObject]); // Add new item if it doesn't exist
    } else {
    }
  };

  // Filter end

  const onDeleteImage = (index) => {
    setselectedPeople(selectedPeople.filter((person, i) => i !== index));
  };

  const isDisabled = selectedPeople.length === 0;

  const copylink = () => {
    var link = "http://localhost:3002/valutdetails/" + valutId;
    if (isShareValut) {
      link = "http://localhost:3002/valutdetails/" + valutId;
    } else {
      link = "http://localhost:3002/valutdetails/" + selectedItem.doc_id;
    }
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link Copied");
        // Reset copied state after 2 seconds
        // setTimeout(() => {
        //   setCopied(false);
        // }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  // Drop down start
  const Dropdownitems = ["Can view only", "Can edit"];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Can view only");

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleDropdown = (index, email, SelecItem) => {
    setActiveMenu(activeMenu === index ? null : index);
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option, index) => {
    const updatedItems = selectedPeople.map((item, index1) => {
      if (index === index1) {
        return { ...item, view_status: option }; // Update the name of the matched item
      }
      return item; // Return unchanged items
    });

    //Update the state with the new array
    setselectedPeople(updatedItems);

    setSelectedOption(option);
    setActiveMenu(null);
    setIsOpen(false);
  };

  const dialogRef = useRef();

  // Close the dialog when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onCancel(); // Call onClose if the click is outside the dialog
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onCancel]);

  if (!show) return null; // Don't render if the dialog is not open

  // Drop down end
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  z-50">
      {loading ? (
        <div className="h-2/4">
          <Loader />
        </div>
      ) : (
        <div
          className={`bg-mDashboardBg  rounded-lg mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4`}
          ref={dialogRef}
        >
          <div className="justify-between flex">
            <div>
              <p className="font-PlusJakartaSansSemiBold text-lg text-mTextColor">
                {t("ShareFile")}
              </p>
            </div>

            <div>
              <button onClick={onCancel}>
                <CloseIcon className="mb-4 " />
              </button>
            </div>
          </div>

          <div className="mt-5">
            <div className="mt-4">
              <DashboardTitle label={t("InvitePeople")} />
            </div>
            <div className="mt-2">
              <div className="autocomplete-container absolute">
                <input
                  className="autocomplete-input font-PlusJakartaSansMedium"
                  type="text"
                  // style={{
                  //   display: "none",
                  // }}
                  value={inputValue}
                  onChange={handleChange}
                  placeholder="Type to search people..."
                />
                <ul className="autocomplete-suggestions">
                  {filteredSuggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="autocomplete-suggestion"
                      onClick={() => handleSelect(suggestion)}
                    >
                      <div className="w-full h-full">
                        <div className="flex-row flex h-full">
                          <img
                            className="rounded-full bg-white w-9 h-9"
                            src={
                              suggestion.image.length === 0
                                ? Images.avatar
                                : suggestion.image
                            }
                            alt=""
                          />
                          <div className="h-9 flex  ml-2 justify-center items-center">
                            <TextViewlight label={suggestion.name} />
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="w-full h-px bg-mBorderColor mt-10"></div>

          <div className="mt-4">
            <div className="mt-4 ">
              {selectedPeople.length === 0 ? (
                <div></div>
              ) : (
                <DashboardTitle label={t("Peoplewithaccess")} />
              )}

              <div className="mt-4"></div>
              {selectedPeople.map((item, index) => (
                <div
                  className="bg-white mt-2  p-4 rounded-lg grid grid-cols-1 gap-5"
                  key={item.email_id}
                >
                  <div className="w-full h-full flex">
                    <div className="flex-row flex h-full w-2/3">
                      <div className="relative w-11 h-11">
                        <img
                          className="rounded-full bg-white w-11 h-11"
                          src={
                            item.image.length === 0 ? Images.avatar : item.image
                          }
                          alt=""
                        />
                        <button
                          className="absolute top-0 right-8 bg-white  rounded-full "
                          onClick={() => {
                            onDeleteImage(index);
                          }}
                        >
                          <Docclose className="w-5 h-5 text-gray-700" />
                        </button>
                      </div>

                      {/* <img
                        className="rounded-full bg-white w-9 h-9"
                        src={
                          item.image.length === 0 ? Images.avatar : item.image
                        }
                        alt=""
                      /> */}
                      <div className="h-9 flex  ml-2 justify-center items-center">
                        <TextViewlight label={item.full_name} />
                      </div>
                    </div>

                    <div className="h-full w-1/3 ">
                      <div className="  w-full h-full bg-white justify-center items-center flex">
                        <div className="items-center justify-center flex  w-full">
                          <div className="relative  w-full">
                            <button
                              className="custom-dropdown-toggle"
                              onClick={() =>
                                toggleDropdown(
                                  index,
                                  item.email_id,
                                  selectedOption
                                )
                              }
                            >
                              <p
                                className={`font-PlusJakartaSansRegular font-normal text-mTextColorLogin text-sm `}
                              >
                                {/* {selectedOption} */}
                                {item.view_status}
                              </p>
                            </button>

                            {/* Dropdown menu */}

                            <ul
                              className={`custom-dropdown-menu   ${
                                activeMenu === index && isOpen ? "show" : ""
                              }`}
                            >
                              {Dropdownitems.map((option) => (
                                <li
                                  key={option}
                                  className={`custom-dropdown-option ${
                                    item.view_status === option
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleOptionClick(option, index)
                                  }
                                >
                                  <p
                                    className={`font-PlusJakartaSansRegular font-normal text-mTextColorLogin text-sm ${
                                      item.view_status === option
                                        ? "text-white"
                                        : "text-mTextColorLogin"
                                    }`}
                                  >
                                    {option}
                                  </p>
                                </li>
                              ))}
                            </ul>

                            {/* {activeMenu === index && (
                              
                            )} */}
                          </div>
                        </div>
                        <Icondrop className="w-6 h-6 flex-shrink-0" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-full flex mt-10">
            <div
              className="h-14 w-1/2  bg-mButtonColorligth rounded-lg items-center justify-center flex cursor-pointer mr-4"
              onClick={copylink}
            >
              <p className="ml-2 font-PlusJakartaSansSemiBold text-mButtonColor text-base">
                {" "}
                {t("CopyLink")}{" "}
              </p>
            </div>

            <div className=" w-1/2 ml-4">
              <PrimaryButton
                label={"Done"}
                onClick={newVaultClick}
                onDisable={isDisabled}
              ></PrimaryButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareFile;
