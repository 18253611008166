import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import {
  Color,
  Images,
  isEmptyObject,
  isValidScore,
  isValidScore1,
  mTimerValue,
  ProgressBar,
} from "../../Utils/Constants";
import {
  getscorecard,
  mStartupdashboard,
  mUploadDeckDashboard,
} from "../../Services/allAPI";
import { toast } from "react-toastify";

import DashboardDialog from "./DashboardDialog";
import { useSelector } from "react-redux";
import HeaderMenu from "../../Components/HeaderMenu";
import DashboardTitle from "../../Components/DashboardTitle";
import GaugeChart from "react-gauge-chart";
import PdfUploader from "../../Components/PdfUploader";
import SecondaryButton from "../../Components/SecondaryButton";
import { ReactComponent as ChatIcon } from "../../assets/Images/ic_chat.svg";
import { ReactComponent as CompanyIcon } from "../../assets/Images/ic_company_green.svg";
import { toPng } from "html-to-image"; // Correctly import toPng
import { AlertDialog } from "../../Components/AlertDialog";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  const { t } = useTranslation("");
  const cardRef = useRef(null);

  const [imageUrl, setImageUrl] = useState("");

  const message = `I am happy to share my Deck review scoring provided by the theprevc.\n Check yours now by signing up : app.theprevc.com \n\n #deckreview #theprevc \n\n`;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [servicesdate, setServicesData] = useState([]);
  const [percentage, setpercentage] = useState(0);
  const [base64, setBase64] = useState("");
  const [FileName, setFileName] = useState("");
  const [summarydetails, setSummaryDetails] = useState({});
  const [deckdetails, setDeckdetails] = useState({});
  const [mDeckName, setmDeckName] = useState("");
  const [mDeckId, setmDeckId] = useState("");

  const [balancedeck, setbalancedeck] = useState(0);
  // Set Progressbar

  const [progress, setProgress] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const [animate, setAnimate] = useState(true);
  const user = useSelector((state) => state.userInfo.user);
  const timerRef = useRef(null);
  const [isHidden, setIsHidden] = useState(false);
  const [isHiddenMsg, setisHiddenMsg] = useState("asfsg");
  const [isExtraTime, setisExtraTime] = useState(false);
  const [isDeckstatus, setisDeckstatus] = useState(false);
  const [Deckresponse, setDeckresponse] = useState("");

  //Check is subscription end or not

  const [subscriptionstatus, setsubscriptionstatus] = useState(false);

  // alert("Get User :" + user);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const isClickDisbled = isRunning && progress !== 100;
  // const isClickDisbled = true;

  //
  useEffect(() => {
    DashboardAPICall();
  }, []);

  useEffect(() => {
    if (deckdetails) {
      downloadImage();
    }
  }, [deckdetails]);

  const validateDeckReview = (deckReview) => {
    if (deckReview === null || deckReview === undefined) {
      // alert("aa");
      return false;
    } else if (typeof deckReview === "string") {
      //alert("bb");
      return false;
    } else if (
      typeof deckReview === "object" &&
      !Array.isArray(deckReview) &&
      !isEmptyObject(deckReview)
    ) {
      //alert("cc");
      return true;
    } else {
      return false;
    }
  };

  // Dashboard API call
  const DashboardAPICall = async () => {
    setLoading(true);
    const response = await mStartupdashboard({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);

      setProgress(0);
      setIsRunning(false);

      if (response?.data.message.status) {
        // if (!isEmptyObject(response?.data.message.deck_review)) {

        localStorage.setItem(
          "SubscriptionStatus",
          response?.data.message.subscription_status
        );
        localStorage.setItem(
          "SubscriptionEndDate",
          response?.data.message.subscription_expiry_date
        );
        setbalancedeck(response?.data.message.balance_deck);
        setsubscriptionstatus(response?.data.message.subscription_status);

        if (validateDeckReview(response?.data.message.deck_review)) {
          if (response?.data.message.user_last_deck.length !== 0) {
            setmDeckName(
              response?.data.message.user_last_deck.deck_details.deck_name
            );
            setmDeckId(response?.data.message.user_last_deck.deck_details.id);
          }

          setIsRunning(false);
          setisHiddenMsg("");
          setisExtraTime(false);

          setisDeckstatus(response?.data.message.deck_status);
          setDeckresponse(response?.data.message.deck_response);

          setpercentage(
            isValidScore1(
              response?.data.message.deck_review.summary.overall_rating
            )
          );
          setDeckdetails(response?.data.message.deck_review.summary);

          setSummaryDetails(response?.data.message.deck_review.summary);
        } else {
          setIsRunning(false);
          setisHiddenMsg("");
          setisExtraTime(false);

          setisDeckstatus(response?.data.message.deck_status);
          setDeckresponse(response?.data.message.deck_response);

          setSummaryDetails({});
          setpercentage(0);
          setDeckdetails({});
        }

        setServicesData(response?.data.message.our_services);
      } else {
        toast.error(response?.data.message.status);
        setisExtraTime(true);
        setIsRunning(true);
        setProgress(0);

        setisHiddenMsg("This deck takes some more time to process");
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const handleItemClick = (mDeck_Id) => {
    if (!isDeckstatus) {
      setIsAlertOpen(true);
    } else {
      navigate("/deckdetails", {
        state: {
          mDeck_Id: mDeck_Id,
        },
      });
    }
  };

  const closeAlert = () => setIsAlertOpen(false);
  const Opendialog = () => {
    // Handle the cancel action

    setShowDialog(true);
  };

  const handleCancel = () => {
    // Handle the cancel action
    setShowDialog(false);
  };

  const handleDeckSucess = () => {
    // Handle the cancel action

    setShowDialog(false);
    DashboardAPICall();
  };

  const handleFileSelect1 = async (file) => {
    //  setSelectedFile(file);
    console.log(file);

    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const base64String = await convertToBase64(file);
    setBase64(base64String);

    console.log("Base64 String:", base64String);

    var mCropImage = "";

    if (base64String.length === 0) {
      mCropImage = base64String.replace("data:application/pdf;base64,", "");
    } else {
      mCropImage = base64String.replace("data:application/pdf;base64,", "");
    }

    // console.log(mCropImage);
    setFileName(file.name);
    setLoading(true);
    const response = await mUploadDeckDashboard({
      deck_name: file.name,
      deck_doc: mCropImage,
      user_name: UserInfoData.user_email,
    });
    console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        //setdeckId(response?.data.message.deck_id);
        setIsRunning(true);

        // navigate("/signup", {
        //   state: {
        //     mdeckId: response?.data.message.deck_id,
        //     mdeckName: file.name,
        //   },
        // });
      } else {
        toast.error(response?.data.message.message);
        setLoading(false);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);

      console.log("Error");
    }
  };

  //Function to convert file to Base64
  const convertToBase64 = (file) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const ClickChat = () => {
    if (!isClickDisbled) {
      navigate("/chat");
    }
  };

  const proceedClick = () => {
    navigate("/service");
  };

  // Deck upload to server start

  // One min interval
  useEffect(() => {
    console.log(isRunning);
    if (!isRunning) return;

    console.log("Running");

    const intervalTime = mTimerValue / 100; // Update every 0.6 seconds (1% of 60 seconds)

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          console.log("Running Working");
          clearInterval(interval);
          DashboardAPICall();
          console.log("Interval : " + prevProgress);
          return 100;
        }
        return prevProgress + 1;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isRunning, isExtraTime]);

  // Download Image
  const [showPopup, setShowPopup] = useState(false);

  const downloadImage = async () => {
    if (cardRef.current === null) return;
    toPng(cardRef.current)
      .then((dataUrl) => {
        setImageUrl(dataUrl.replace("data:image/png;base64,", ""));
        setAnimate(false);
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "deck-score-card.png";
        // link.click();
        // alert(link.url);

        uploadImage(dataUrl);
      })
      .catch((err) => console.error("Failed to generate image", err));
  };

  // Download Image

  const uploadImage = async (file) => {
    const response = await getscorecard({
      user_name: UserInfoData.user_email,
      file_name: "Deck Score",
      file_type: "png",
      score_card_image: file.replace("data:image/png;base64,", ""),
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);

      if (response?.data.message.status) {
        const data = response?.data.message.score_card_details;
        toast.success(response?.data.message.message);

        setImageUrl(data.file);
        setIsHidden(true);
        // setIsHidden(false);
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }

    // Assuming your server returns the image URL
  };

  //const imageUrl1 = "https://core.zenpivot.in/files/Deck_Score68af1a.png";
  const pageUrl = "https://your-domain.com/share-scorecard.html";
  const title = "Check out my Deck Review Score!";
  const description = "Here is my scorecard from the Deck Review app!";

  <>
    <Helmet>
      {/* HTML Meta Tags */}
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={pageUrl} />

      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />

      {/* Page Title */}
      <title>{title}</title>
    </Helmet>

    {/* Page Content */}
  </>;

  // Share view Click
  // Click Menu open
  const toggleMenu = () => {
    setAnimate(false);
    setIsOpen(!isOpen);
  };

  // Social media share URLs Twitter
  const shareOnTwitter = () => {
    setIsOpen(false);
    const twitterUrl = `https://twitter.com/intent/tweet?url=${imageUrl}&text=${message} &title=${"TEst"}`;
    window.open(twitterUrl, "_blank");
  };

  // Social media share URLs whatsapp
  const ShareOnwhatsapp = () => {
    setIsOpen(false);
    // const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    //   message
    // )}%20${encodeURIComponent(imageUrl)}`;
    // window.open(whatsappShareUrl, "_blank");

    // const pageUrl = "https://core.zenpivot.in/files/Deck_Score68af1a.png";
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      message
    )}%20${encodeURIComponent(imageUrl)}`;
    window.open(whatsappUrl, "_blank");
  };

  // Social media share URLs Gmail
  const shareOnMail = () => {
    setIsOpen(false);
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      message
    )}&body=${encodeURIComponent(message)}%0A%0A${encodeURIComponent(
      imageUrl
    )}`;
    window.open(mailtoLink, "_blank");
  };

  // Social media share URLs LinkedIn
  const shareOnLinkedIn = () => {
    setIsOpen(false);

    const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${imageUrl}&text=${message} `;
    window.open(linkedInShareUrl, "_blank");
  };

  // Close the menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(isOpen);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div
            className="flex h-screen overflow-hidden bg-mDashboardBg  bg-no-repeat bg-cover"
            ref={menuRef}
          >
            {/* Sidebar */}
            <SideMenu
              mFrom={0}
              OpenDialog={Opendialog}
              mClickstatus={isClickDisbled}
            />

            {/* Main content */}
            <div className="flex flex-col flex-grow overflow-hidden ">
              {/* Top Navigation */}

              <HeaderMenu
                title={t("Dashboard")}
                mClickstatus={isClickDisbled}
                isLoadNotification={true}
              />

              {/* Scrollable content  blur-sm*/}
              <main className="flex-grow overflow-auto  mt-1 mb-3 scrollbar-hide p-4">
                {/* Adjusted padding top to avoid overlap */}

                {isEmptyObject(summarydetails) ? (
                  <div>
                    <div className="w-full h-auto mt-5 flex flex-row">
                      <div className="w-1/2 ">
                        <div className="">
                          <DashboardTitle label={t("UploadPitchDeck")} />
                        </div>
                        <div className="mt-9">
                          <PdfUploader
                            onFileSelect={handleFileSelect1}
                            hidelogin={0}
                            mClickstatus={isClickDisbled}
                            isBorder={false}
                            subscriptionstatus={subscriptionstatus}
                            balancedeck={balancedeck}
                          />
                        </div>
                      </div>
                      <div className="w-1/2 ml-5 mr-3">
                        {isRunning ? (
                          <div className="mt-10">
                            <ProgressBar progress={progress} />
                            <p className="font-PlusJakartaSansRegular text-mTextColor font-normal text-sm mt-2">
                              {FileName}
                            </p>

                            <p className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-3">
                              {isHiddenMsg}
                            </p>
                          </div>
                        ) : (
                          <div>
                            {isDeckstatus.length === 0 ? (
                              <div></div>
                            ) : !isDeckstatus ? (
                              <>
                                <div>
                                  <div className="w-full justify-between flex flex-row">
                                    <div>
                                      <p className="font-PlusJakartaSansSemiBold font-normal text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg">
                                        {"Not a Pitch Deck"}
                                      </p>
                                    </div>
                                    <div
                                      className="cursor-pointer "
                                      onClick={() => handleItemClick()}
                                    >
                                      <p className="font-PlusJakartaSansSemiBold font-normal text-mButtonColor text-xs md:text-sm lg:text-base 3xl:text-lg">
                                        {t("ViewSummary")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-5 3xl:mt-20 blur-sm">
                                  <div className="w-full h-36 mt-7 flex-row justify-center items-center py-5 flex ">
                                    <div
                                      className=" w-full md:w-1/2 lg:w-3/5 h-full justify-center items-center lg:justify-start lg:items-start flex gauge-container"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <GaugeChart
                                        id="gauge-chart-custom"
                                        nrOfLevels={1} // Sets the number of arc levels
                                        arcsLength={[
                                          percentage / 10,
                                          1 - percentage / 10,
                                        ]}
                                        colors={[
                                          Color.mButtonColor,
                                          Color.trackColor,
                                        ]} // Sets color for the arc
                                        arcWidth={0.25} // Sets arc width, reduce this for thinner arcs
                                        percent={percentage / 10} // Sets the percentage the gauge is filled
                                        needleColor={Color.mArcColor} // Sets the needle color
                                        needleBaseColor={Color.needleColor} // Sets the needle base color
                                        cornerRadius={0} // Removes edge radius
                                        arcPadding={0}
                                        animate={animate} // Control animation
                                        hideText={true} // Removes padding between arcs
                                      />
                                    </div>
                                    <div className="hidden lg:flex lg:w-2/5 h-full items-center justify-center flex-row">
                                      <div className="flex-col w-[85%]">
                                        <p className="font-PlusJakartaSansSemiBold text-6xl items-center justify-center flex">
                                          {0}
                                        </p>
                                        <p className="ml-2  font-PlusJakartaSansRegular items-center justify-center flex font-normal text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                          {t("OverallScore")}
                                        </p>
                                      </div>
                                      <div className="h-auto flex w-[15%] justify-start">
                                        {imageUrl.length === 0 ? (
                                          <div></div>
                                        ) : (
                                          <div onClick={() => toggleMenu()}>
                                            <img
                                              src={Images.share}
                                              alt="Upload Icon"
                                              className={`w-8 h-8 `}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="block lg:hidden h-full items-start justify-center flex-col border border-mBorderColor rounded-lg ml-3 p-4 bg-white">
                                    <p className="font-PlusJakartaSansSemiBold text-3xl  text-mTextColor">
                                      {0}
                                    </p>
                                    <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                      {t("OverallScore")}
                                    </p>
                                  </div>

                                  <div className="w-full h-28 mt-5 lg:mt-10 3xl:mt-20 flex-row flex ">
                                    <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                                      <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                        {0}
                                      </p>
                                      <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                        {t("Teamexperience")}
                                      </p>
                                    </div>
                                    <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                                      <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                        {0}
                                      </p>
                                      <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                        {t("Marketpotential")}
                                      </p>
                                    </div>
                                    <div className=" hidden lg:block flex-1 h-full items-start justify-center  flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                                      <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                        {0}
                                      </p>
                                      <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                        {t("Flowandclarity")}
                                      </p>
                                    </div>
                                  </div>
                                  <div className=" block lg:hidden mt-4 flex-1 h-full items-start justify-center  flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                                    <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                      {0}
                                    </p>
                                    <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                      {t("Flowandclarity")}
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-auto mt-5 flex flex-col lg:flex-row">
                    <div className="w-full  lg:w-1/2 ">
                      <div className="">
                        <DashboardTitle label={t("UploadPitchDeck")} />
                      </div>
                      <div className="mt-9">
                        <PdfUploader
                          onFileSelect={handleFileSelect1}
                          hidelogin={0}
                          mClickstatus={isClickDisbled}
                          isBorder={false}
                          subscriptionstatus={subscriptionstatus}
                          balancedeck={balancedeck}
                        />
                      </div>
                    </div>
                    {isRunning ? (
                      <div className="w-full  lg:w-1/2 lg:ml-5 mr-3">
                        {isRunning ? (
                          <div className="mt-10">
                            <ProgressBar progress={progress} />
                            <p className="font-PlusJakartaSansRegular text-mTextColor font-normal text-sm mt-2">
                              {FileName}
                            </p>
                            <p className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-3">
                              {isHiddenMsg}
                            </p>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    ) : (
                      <div className="w-full mt-5 lg:mt-0 lg:w-1/2 lg:ml-5  mr-3 ">
                        <div>
                          <div className="w-full justify-between flex flex-row">
                            <div>
                              <p className="font-PlusJakartaSansSemiBold font-normal text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg">
                                {/* {deckdetails.deck_name} */}
                                {mDeckName}
                              </p>
                            </div>
                            <div className="flex">
                              <div
                                className="cursor-pointer h-6 justify-center items-center flex"
                                onClick={() => handleItemClick(mDeckId)} //deckdetails.id
                              >
                                <p className="font-PlusJakartaSansSemiBold font-normal text-mButtonColor text-xs md:text-sm lg:text-base 3xl:text-lg">
                                  {t("ViewSummary")}
                                </p>
                              </div>
                              <div
                                className="ml-3 cursor-pointer"
                                onClick={() => toggleMenu()}
                              >
                                <img
                                  src={Images.share}
                                  alt="Upload Icon"
                                  className={`w-6 h-6 `}
                                />
                                {isOpen && (
                                  <div
                                    className=" absolute right-3 flex rounded-sm px-3 py-2 border border-mButtonColorligth shadow-sm  bg-white"
                                    ref={menuRef}
                                  >
                                    <img
                                      onClick={() => shareOnLinkedIn()}
                                      src={Images.linkedin}
                                      alt="Upload Icon"
                                      className={`w-7 h-7 cursor-pointer`}
                                    />
                                    <img
                                      onClick={() => shareOnTwitter()}
                                      src={Images.twitter}
                                      alt="Upload Icon"
                                      className={`w-7 h-7 ml-3 cursor-pointer`}
                                    />
                                    <img
                                      onClick={() => ShareOnwhatsapp()}
                                      src={Images.whatsapp}
                                      alt="Upload Icon"
                                      className={`w-7 h-7 ml-3 cursor-pointer`}
                                    />
                                    <img
                                      onClick={() => shareOnMail()}
                                      src={Images.gmail}
                                      alt="Upload Icon"
                                      className={`w-7 h-7 ml-3 cursor-pointer`}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 3xl:mt-20">
                          <div className="w-full h-36 mt-7 flex-row justify-center items-center py-5 flex ">
                            <div
                              className=" w-full md:w-1/2 lg:w-3/5 h-full justify-center items-center lg:justify-start lg:items-start flex gauge-container"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <GaugeChart
                                id="gauge-chart-custom"
                                nrOfLevels={1} // Sets the number of arc levels
                                arcsLength={[
                                  percentage / 10,
                                  1 - percentage / 10,
                                ]}
                                colors={[Color.mButtonColor, Color.trackColor]} // Sets color for the arc
                                arcWidth={0.25} // Sets arc width, reduce this for thinner arcs
                                percent={percentage / 10} // Sets the percentage the gauge is filled
                                needleColor={Color.mArcColor} // Sets the needle color
                                needleBaseColor={Color.needleColor} // Sets the needle base color
                                cornerRadius={0} // Removes edge radius
                                arcPadding={0}
                                animate={animate} // Control animation
                                hideText={true} // Removes padding between arcs
                              />
                            </div>
                            <div className="hidden lg:flex lg:w-2/5 h-full items-center justify-center flex-row">
                              <div className="flex-col w-full">
                                <p className="font-PlusJakartaSansSemiBold text-6xl items-center justify-center flex">
                                  {isValidScore1(summarydetails.overall_rating)}
                                </p>
                                <p className="ml-2  font-PlusJakartaSansRegular items-center justify-center flex font-normal text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                  {t("OverallScore")}
                                </p>
                              </div>
                              <div className="h-auto hidden  w-[15%] justify-start">
                                {imageUrl.length === 0 ? (
                                  <div></div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="block lg:hidden h-full items-start justify-center flex-col border border-mBorderColor rounded-lg ml-3 p-4 bg-white">
                            <p className="font-PlusJakartaSansSemiBold text-3xl  text-mTextColor">
                              {isValidScore1(summarydetails.overall_rating)}
                              {/* {
                                  summarydetails.overall_rating
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                            </p>
                            <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                              {t("OverallScore")}
                            </p>
                          </div>

                          <div className="w-full h-28 mt-5 lg:mt-10 3xl:mt-20 flex-row flex ">
                            <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                              <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                {isValidScore(summarydetails.team)}
                                {/* {
                                  summarydetails.team_exp
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                {t("Teamexperience")}
                              </p>
                            </div>
                            <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                              <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                {isValidScore(summarydetails.market_potential)}
                                {/* {
                                  summarydetails.market_potential
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                {t("Marketpotential")}
                              </p>
                            </div>
                            <div className=" hidden lg:block flex-1 h-full items-start justify-center  flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                              <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                {isValidScore(
                                  summarydetails.product_market_fit
                                )}
                                {/* {
                                  summarydetails.flow_and_clarity
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                                {t("Flowandclarity")}
                              </p>
                            </div>
                          </div>
                          <div className=" block lg:hidden mt-4 flex-1 h-full items-start justify-center  flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                            <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                              {isValidScore(summarydetails.product_market_fit)}
                              {/* {
                                  summarydetails.flow_and_clarity
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                            </p>
                            <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                              {t("Flowandclarity")}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="h-64 bg-mDashboardBg"></div>

                <div className="mt-5 hidden">
                  <DashboardTitle label={t("OurSpecialservices")} />
                  <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-6 w-full mt-5">
                    {servicesdate.map((item) => (
                      <div
                        className="bg-white p-4 rounded-lg grid grid-cols-1 gap-5"
                        key={item.id}
                      >
                        <DashboardTitle label={item.service_name} />

                        <div>
                          <p className="font-PlusJakartaSansRegular text-base text-mTextColor">
                            {t("Description")}
                          </p>

                          <p className="font-PlusJakartaSansRegular text-sm text-mTextColor mt-1 leading-6 line-clamp-3 min-h-16">
                            {item.description}
                          </p>
                        </div>

                        <DashboardTitle
                          label={t("rupess") + " " + item.pricing_format}
                        />

                        <SecondaryButton
                          label={t("Proceed")}
                          onClick={proceedClick}
                        ></SecondaryButton>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="absolute items-center justify-center hidden">
                  <button
                    className={`floating-button w-[50px] h-[50px]  ${
                      isClickDisbled ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={ClickChat}
                  >
                    <ChatIcon className="w-6 h-6  flex-shrink-0 " />
                    {/* You can replace this with any icon */}
                  </button>
                </div>
                {/*  */}
                {/* Create share image */}
                {isEmptyObject(summarydetails) ? (
                  <div></div>
                ) : (
                  <div
                    ref={cardRef}
                    className={`flex ${
                      isHidden ? "hidden" : "block"
                    } relative w-2/3 h-screen bg-white justify-center items-center  flex-col`}
                  >
                    <div className="absolute top-5 right-1 w-96 h-96 bg-gradient-to-l from-green-100 to-transparent rounded-full filter blur-3xl"></div>
                    <div className="absolute top-64 left-1 w-96 h-80 bg-gradient-to-r from-green-100 to-transparent rounded-full filter blur-3xl"></div>

                    <div className="flex  h-12 justify-center items-center">
                      <p className="text-[32px]  font-semibold leading-normal font-HelveticaNowDisplayBold text-mTextColor ">
                        {t("PitchDecktxt")}
                      </p>
                      <div className="bg-mButtonColor justify-center items-center flex px-2 ml-2">
                        <p className="text-[24px] font-semibold  font-HelveticaNowDisplayBold text-white ">
                          {t("Score")}
                        </p>
                      </div>
                    </div>
                    <div className="flex h-24 ">
                      <p className="text-[58px]  font-semibold leading-normal font-HelveticaNowDisplayBold text-mTextColor h-[38px]">
                        {t("appname")}
                      </p>
                      <p className="text-[58px]  font-semibold leading-normal font-HelveticaNowDisplayBold text-mButtonColor h-[38px]">
                        .
                      </p>
                    </div>
                    <div className="flex mt-5">
                      <div className="flex">
                        <img
                          src={Images.userImage}
                          alt="Upload Icon"
                          className={`w-14 h-14 `}
                        />

                        <div className="ml-4 justify-center items-start flex flex-col">
                          {/* <p className="font-PlusJakartaSansRegular text-mTextColorLogin  text-base  ">
                            {t("UserName")}
                          </p> */}
                          <p className="font-PlusJakartaSansSemiBold text-mTextColor text-2xl  ">
                            {UserInfoData.user_name}
                          </p>
                        </div>
                      </div>
                      <div className="flex ml-28">
                        <div className="w-14 h-14 justify-center items-center flex bg-mligthgreen rounded-full">
                          <CompanyIcon className="w-9 h-9  flex-shrink-0 " />
                        </div>

                        <div className="ml-4 justify-center items-start flex flex-col">
                          {/* <p className="font-PlusJakartaSansRegular text-mTextColorLogin  text-base  ">
                            {t("CompanyName")}
                          </p> */}
                          <p className="font-PlusJakartaSansSemiBold text-mTextColor text-2xl  ">
                            {UserInfoData.company_name}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5 w-full justify-center items-center flex-col flex ">
                      <div className="w-2/3  h-36 mt-7 flex-row text-center justify-center items-center py-5 flex ">
                        <div
                          className=" w-full md:w-1/2 lg:w-3/5 h-full justify-center items-center lg:justify-start lg:items-start flex gauge-container"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <GaugeChart
                            id="gauge-chart-custom"
                            nrOfLevels={1} // Sets the number of arc levels
                            arcsLength={[percentage / 10, 1 - percentage / 10]}
                            colors={[Color.mButtonColor, Color.trackColor]} // Sets color for the arc
                            arcWidth={0.25} // Sets arc width, reduce this for thinner arcs
                            percent={percentage / 10} // Sets the percentage the gauge is filled
                            needleColor={Color.mArcColor} // Sets the needle color
                            needleBaseColor={Color.needleColor} // Sets the needle base color
                            cornerRadius={0} // Removes edge radius
                            arcPadding={0}
                            hideText={true} // Removes padding between arcs
                          />
                        </div>
                        <div className=" hidden lg:block lg:w-2/5 h-full items-center justify-center  flex-col ">
                          <p className="font-PlusJakartaSansSemiBold text-6xl items-center justify-center flex">
                            {isValidScore1(summarydetails.overall_rating)}
                            {/* {
                                  summarydetails.overall_rating
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                          </p>
                          <p className="ml-2 w-full font-PlusJakartaSansRegular items-center justify-center flex font-normal text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                            {t("OverallScore")}
                          </p>
                        </div>
                      </div>

                      <div className="block lg:hidden h-full items-start justify-center flex-col border border-mBorderColor rounded-lg ml-3 p-4 bg-white">
                        <p className="font-PlusJakartaSansSemiBold text-3xl  text-mTextColor">
                          {isValidScore1(summarydetails.overall_rating)}
                          {/* {
                                  summarydetails.overall_rating
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                        </p>
                        <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                          {t("OverallScore")}
                        </p>
                      </div>

                      <div className="w-3/4 h-28 mt-5 lg:mt-10 3xl:mt-20 flex-row flex ">
                        <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                          <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                            {isValidScore(summarydetails.team)}
                            {/* {
                                  summarydetails.team_exp
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                          </p>
                          <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                            {t("Teamexperience")}
                          </p>
                        </div>
                        <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                          <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                            {isValidScore(summarydetails.market_potential)}
                            {/* {
                                  summarydetails.market_potential
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                          </p>
                          <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                            {t("Marketpotential")}
                          </p>
                        </div>
                        <div className=" hidden lg:block flex-1 h-full items-start justify-center  flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                          <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                            {isValidScore(summarydetails.product_market_fit)}
                            {/* {
                                  summarydetails.flow_and_clarity
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                          </p>
                          <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                            {t("Flowandclarity")}
                          </p>
                        </div>
                      </div>
                      <div className=" block lg:hidden mt-4 flex-1 h-full items-start justify-center  flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                        <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                          {isValidScore(summarydetails.product_market_fit)}
                          {/* {
                                  summarydetails.flow_and_clarity
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                        </p>
                        <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base 3xl:text-lg mt-3">
                          {t("Flowandclarity")}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </main>
            </div>
          </div>

          <AlertDialog
            isOpen={isAlertOpen}
            onClose={closeAlert}
            message={Deckresponse}
            isShowDes={true}
          />

          {showDialog ? (
            <DashboardDialog
              show={showDialog}
              onClose={handleCancel}
              onDeckSucess={handleDeckSucess}
              Userid={UserInfoData.user_id}
            />
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
    // <div className="p-6">
    //   <div
    //     className="bg-gray-200 p-10 text-center rounded-lg shadow-md"
    //     ref={divRef}
    //   >
    //     <h1 className="text-3xl font-bold mb-4">Shareable Banner</h1>
    //     <p className="text-lg text-gray-700">
    //       This content will be shared as an image!
    //     </p>
    //   </div>

    //   <button
    //     className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg"
    //     onClick={handleShare}
    //   >
    //     Share as Image
    //   </button>
    // </div>
  );
};

export default Dashboard;
