import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Utils/Loader";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../assets/Images/ic_back.svg";
import { ReactComponent as SparklesIcon } from "../../assets/Images/ic_sparkles.svg";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { Color, Images, isValidEmail } from "../../Utils/Constants";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import GaugeChart from "react-gauge-chart";
import { getDeckscore } from "../../Services/allAPI";
import LogoText from "../../Components/LogoText";

const Deckscore = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [deckScore, setdeckScore] = useState({});

  // Get Deck id
  const location = useLocation();
  const mGetdeckId = location.state?.mdeckId || "";
  const mGetdeckName = location.state?.mdeckName || "";

  const [percentage, setpercentage] = useState(0);

  const mBackClick = () => {
    navigate("/question");
  };

  const ProceedClick = () => {};

  const skipclick = () => {
    navigate("/login");
  };

  useEffect(() => {
    getDeckScoreCall();
  }, []);

  const getDeckScoreCall = async () => {
    setLoading(true);
    const response = await getDeckscore({
      // deck_id: mGetdeckId,
      deck_id: "Deck-Review-0014",
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setdeckScore(response?.data.message.deck_score);

        setpercentage(
          response?.data.message.deck_score.overall_rating
            .split(":")[1]
            .split("/")[0] / 10
        );

        //console.log("API Response -> " + serviceDetails.process_steps[0].steps);
      } else {
        toast.error(response?.data.message.status);
        console.log(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }

    //  await AsyncStorage.setItem('userInfo', JSON.stringify(response?.data));
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="min-h-screen flex flex-row">
          <div className="hidden lg:block w-3/5 bg-mwhite  flex-col items-start justify-start p-5">
            <div className="mt-3 ml-3">
              {/* <img
                src={Images.zenpivot}
                alt="google Icon"
                className=" h-9 w-28"
              /> */}
              <LogoText label={t("appname")} />
            </div>

            <div className="w-full h-auto justify-center items-center flex">
              <img src={Images.scorebg} alt="login" className="w-2/3 " />
            </div>
          </div>
          <div className="lg:w-2/5 w-full pl-2 py-5 lg:pl-20 lg:py-10 items-center justify-center lg:items-start lg:justify-start bg-mBackground  flex-col flex ">
            <div className="w-11/12 h-full ">
              <div className="grid gap-5 grid-cols-4   w-full h-2 mt-5 items-center justify-center ">
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
                <div className=" h-1 bg-mTextColorLogin rounded-full"></div>
              </div>

              <div className="mt-4  w-full justify-between flex">
                <div>
                  <p className="text-mTextColor font-PlusJakartaSansMedium text-sm 3xl:text-base">
                    Step 3 / 4
                  </p>
                </div>

                <div onClick={() => skipclick()}>
                  <p className="text-mTextColor font-PlusJakartaSansMedium text-sm cursor-pointer 3xl:text-base">
                    {t("skip")}
                  </p>
                </div>
              </div>

              <div className="mt-10 w-full p-2  items-start justify-start flex-col flex">
                <div className="flex flex-row items-center justify-center">
                  <BackIcon
                    className="w-6 h-6  flex-shrink-0 cursor-pointer"
                    onClick={() => mBackClick()}
                  />{" "}
                  <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-base">
                    {t("GoBack")}
                  </p>
                </div>

                <div>
                  <p className="font-PlusJakartaSansSemiBold text-4xl mt-10">
                    {t("PitchDeckScores")}
                  </p>
                </div>

                {Object.keys(deckScore).length > 0 ? (
                  <div>
                    <div className="w-full h-44 mt-10 flex-row flex ">
                      <div className="w-3/5 h-full ">
                        <div style={{ width: "300px", margin: "0 auto" }}>
                          <GaugeChart
                            id="gauge-chart-custom"
                            nrOfLevels={1} // Sets the number of arc levels
                            arcsLength={[percentage / 10, 1 - percentage / 10]}
                            colors={[Color.mButtonColor, Color.trackColor]} // Sets color for the arc
                            arcWidth={0.25} // Sets arc width, reduce this for thinner arcs
                            percent={percentage / 10} // Sets the percentage the gauge is filled
                            needleColor={Color.mArcColor} // Sets the needle color
                            needleBaseColor={Color.needleColor} // Sets the needle base color
                            cornerRadius={0} // Removes edge radius
                            arcPadding={0}
                            hideText={true} // Removes padding between arcs
                          />
                        </div>
                      </div>
                      <div className="w-2/5 h-full items-center justify-center flex flex-col">
                        <p className="font-PlusJakartaSansSemiBold text-7xl ">
                          {deckScore.overall_rating.split(":")[1].split("/")[0]}
                        </p>
                        <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base mt-3">
                          {t("OverallScore")}
                        </p>
                      </div>
                    </div>

                    <div className="w-full h-28 mt-10 flex-row flex ">
                      <div className="w-1/2 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg mr-3 px-5">
                        <p className="font-PlusJakartaSansSemiBold text-5xl ">
                          {" "}
                          {deckScore.team_exp.split(":")[1].split("/")[0]}
                        </p>
                        <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base mt-3">
                          {t("Teamexperience")}
                        </p>
                      </div>
                      <div className="w-1/2 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 px-5">
                        <p className="font-PlusJakartaSansSemiBold text-5xl ">
                          {" "}
                          {
                            deckScore.market_potential
                              .split(":")[1]
                              .split("/")[0]
                          }
                        </p>
                        <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base mt-3">
                          {t("Marketpotential")}
                        </p>
                      </div>
                    </div>

                    <div className="w-full h-28 mt-5  flex flex-col items-start justify-center bg-white border border-mBorderColor rounded-lg mr-3 px-5">
                      <p className="font-PlusJakartaSansSemiBold text-5xl ">
                        {deckScore.flow_and_clarity.split(":")[1].split("/")[0]}
                      </p>
                      <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base mt-3">
                        {t("Flowandclarity")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="w-full mt-10">
                  <PrimaryButton
                    label={t("Proceed")}
                    onClick={ProceedClick}
                  ></PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Deckscore;
