import React from "react";

// Custom Text view
const Titletext = ({ label, changecolor }) => (
  <p
    className={`font-PlusJakartaSansSemiBold text-[16px] md:text-[20px] xl:text-[22px]  3xl:text-[24px]  
      ${changecolor ? "text-white" : "text-mTextColor"} `}
  >
    {label}
  </p>
);

export default Titletext;
