import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { ReactComponent as SparklesIcon } from "../assets/Images/ic_sparkles_green.svg";
import { useNavigate } from "react-router-dom";
import TextView from "./TextView";

const PdfUploader = ({
  onFileSelect,
  Onlogin,
  hidelogin,
  isBorder,
  mClickstatus,
  subscriptionstatus,
  balancedeck,
}) => {
  var isDisable = false;

  if (subscriptionstatus) {
    if (balancedeck > 0) {
      isDisable = true;
    } else {
      isDisable = false;
    }
  } else {
    isDisable = true;
  }

  //alert(balancedeck);
  const onDrop = useCallback(
    (acceptedFiles) => {
      // Ensure that only PDF files are accepted
      const pdfFiles = acceptedFiles.filter(
        (file) => file.type === "application/pdf"
      );
      if (pdfFiles.length > 0) {
        // alert(pdfFiles[0]);
        onFileSelect(pdfFiles[0]); // Pass the selected file back to the parent component
      } else {
        /// alert("Accpet only PDF");
      }
    },
    [onFileSelect]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,

    accept: {
      "application/pdf": [".pdf"], // Accept only PDF files
    },
    disabled: balancedeck > 0 ? false : true,
    // disabled: !subscriptionstatus || balancedeck === 0 ? false : true,
    // disabled: !subscriptionstatus && balancedeck === 0,
  });

  const { t } = useTranslation("");
  const navigate = useNavigate();

  const mBackClick = () => {
    navigate("/login");
  };

  return (
    <div
      className={`mt-8 border    ${mClickstatus ? "pointer-events-none" : ""} ${
        mClickstatus ? "cursor-not-allowed" : "cursor-pointer"
      } ${
        isBorder
          ? "h-72 3xl:h-96 border-mBorderColor"
          : "h-72 3xl:h-96 border-white"
      } w-full rounded-2xl bg-white justify-center items-center flex flex-col`}
      {...getRootProps()}
    >
      <div className="h-14 3xl:h-14 w-3/4 lg:w-1/2 rounded-lg bg-mButtonColorligth items-center justify-center flex px-4 py-3">
        <SparklesIcon className="w-6 h-6 3xl:w-8 3xl:h-8 flex-shrink-0 cursor-pointer" />
        <p className="ml-2 font-PlusJakartaSansSemiBold text-mButtonColor text-base 3xl:text-lg">
          {" "}
          {t("UploadPitchDeck")}{" "}
        </p>
      </div>
      <input
        {...getInputProps()}
        className=""
        id="file"
        type="file"
        accept=".pdf"
        style={{ display: "none" }}
      />
      <div className="w-1/2 mt-4">
        <div className="flex items-center before:flex-1 before:border-t before:border-mBorderColor after:flex-1 after:border-t after:border-mBorderColor">
          <p className="mx-2 mb-0 text-center font-PlusJakartaSansRegular text-sm  3xl:text-base  text-mTextColorLogin">
            {t("or")}
          </p>
        </div>
      </div>
      <div className="w-1/2 mt-4">
        <div className="mx-2 justify-center items-center w-full flex">
          <TextView isCenter={true} label={t("draganddrop")} />
        </div>
      </div>

      {!subscriptionstatus || balancedeck === 0 ? (
        <div class="flex flex-col justify-end mt-4">
          <p class=" font-PlusJakartaSansRegular font-normal text-mTextColor text-sm  xl:text-base 3xl:text-lg text-center p-4">
            Your subscription end. Please purchase
          </p>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default PdfUploader;
