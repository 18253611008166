import { createSlice } from "@reduxjs/toolkit";

// const userData = JSON.parse(localStorage.getItem("UserInfo")) || {
//   user: null,
// };

const initialState = {
  // const user = useSelector((state) => state.userInfo.user);

  // user: userData,

  //initial to set login user info
  user: localStorage.getItem("UserInfo")
    ? JSON.parse(localStorage.getItem("UserInfo"))
    : null,
};

// Create slice
export const userSlice = createSlice({
  name: "user",
  initialState,

  reducers: {
    // Set user data
    setUser: (state, action) => {
      state.user = action.payload;
    },

    // Remove user data
    removeUser: (state) => {
      state.user = null;
      localStorage.removeItem("UserInfo");
    },
  },
});

export const { setUser, removeUser } = userSlice.actions;

export default userSlice.reducer;
