import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DashboardTitle from "../../Components/DashboardTitle";

import { toast } from "react-toastify";
import { ReactComponent as MenuIcon } from "../../assets/Images/ic_menudot.svg";
import { ReactComponent as MenuEditIcon } from "../../assets/Images/ic_menuedit.svg";
import { ReactComponent as MenuDeleteIcon } from "../../assets/Images/ic_menudelete.svg";
import Menutext from "../../Components/Menutext";
import Menutitletext from "../../Components/Menutitletext";
import { Images } from "../../Utils/Constants";
import ServiceviewDialog from "../ServicePage/ServiceviewDialog";
import DeleteDialog from "../../Components/DeleteDialog";
import {
  mCRMBoard,
  mCRMDelete,
  mCRMList,
  mCRMStatus,
  mDeleteCRM,
  mgetCRMBoard,
  mMoveCRMBoard,
} from "../../Services/allAPI";
import Titletext from "../../Components/Titletext";
import Loader from "../../Utils/Loader";
import axios from "axios";
import CRMListDetails from "./CRMListDetails";

const CRMList = () => {
  const [dashboarddate, setDashboardData] = useState([]);
  const menuRef = useRef(null);
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [ShowInvestorDetails, setShowInvestorDetails] = useState(false);
  const [SelectInvestor, setSelectInvestor] = useState({});

  //const [crmdata, setCRMdata] = useState([]);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  let CRMdataItems = [];

  // Data call

  useEffect(() => {
    CRMListAPICall();
  }, []);

  // Service Page API call
  const CRMListAPICall = async () => {
    setLoading(true);
    const response = await mCRMList({
      user_name: UserInfoData.user_email,
    });
    //alert(response.status);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        const dealItem = {
          title: "Deal",
          items: response?.data.message.investors_board.deal,
        };
        CRMdataItems.push(dealItem);

        const contactedItem = {
          title: "Contacted",
          items: response?.data.message.investors_board.contacted,
        };

        CRMdataItems.push(contactedItem);

        const evaluatingItem = {
          title: "Evaluating",
          items: response?.data.message.investors_board.evaluating,
        };

        CRMdataItems.push(evaluatingItem);

        const diligenceItem = {
          title: "Diligence",
          items: response?.data.message.investors_board.diligence,
        };

        CRMdataItems.push(diligenceItem);

        const investmentcommitteeItem = {
          title: "Investment Committee",
          items: response?.data.message.investors_board.investment_committee,
        };

        CRMdataItems.push(investmentcommitteeItem);

        const investedItem = {
          title: "Invested",
          items: response?.data.message.investors_board.invested,
        };

        CRMdataItems.push(investedItem);

        setColumns(CRMdataItems);

        // console.log(CRMdataItems);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  //
  // Close the menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const [columns, setColumns] = useState(CRMdataItems);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);

      // console.log("Source Index:", result.draggableId);
      // console.log("Destination Index:", destColumn.title);

      // toast.success(result.draggableId);
      // toast.success(destColumn.title);

      ChangeCRMStatusAPICall(result.draggableId, destColumn.title);

      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  // Service Page API call
  const ChangeCRMStatusAPICall = async (investorid, movedstatus) => {
    setLoading(true);
    const response = await mCRMStatus({
      user_name: UserInfoData.user_email,
      crm_id: investorid,
      status: movedstatus,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        // console.log(CRMdataItems);
        toast.success(response?.data.message.message);
        CRMListAPICall();
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const [gridshow, setGridShow] = useState(true);

  const listclick = () => {
    setGridShow(false);
  };

  const gridclick = () => {
    setGridShow(true);
  };

  // Drag and drop end

  const menuClick = (item) => {
    setActiveMenu(activeMenu === item.id ? null : item.id);
  };
  const clickEdit = (item, event) => {
    // alert(`Edit ${item.Task}`);
    setSelectInvestor(item);
    setActiveMenu(null);
    setShowInvestorDetails(true);
  };

  const clickClose = () => {
    setShowInvestorDetails(false);
  };

  const clickRefresh = () => {
    setShowInvestorDetails(false);
    CRMListAPICall();
  };

  const clickDelete = (item, event) => {
    setActiveMenu(null);
    setSelectInvestor(item);
    setIsDialogOpen(true);
  };
  const [activeMenu, setActiveMenu] = useState(null);

  //Delete dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const confirmDelete = () => {
    // Perform delete action here
    console.log("Item deleted");
    closeDialog();

    DeleteAPICall();
  };

  // Service Page API call
  const DeleteAPICall = async () => {
    setLoading(true);
    const response = await mCRMDelete({
      user_name: UserInfoData.user_email,
      crm_id: SelectInvestor.id,
      delete: 1,
      status: SelectInvestor.status,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        clickRefresh();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <p className="ml-5 font-PlusJakartaSansRegular text-mTextColorLogin text-sm">
            *Drag and drop the card to change its status
          </p>

          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            <div className="flex  md:mr-5">
              <div className="m-2 md:flex w-full md:min-h-svh ">
                {Object.entries(columns).map(([columnId, column], index) => {
                  return (
                    <Droppable key={columnId} droppableId={columnId}>
                      {(provided, snapshot) => (
                        <div
                          className="min-h-28 flex flex-col bg-white w-full md:min-w-80 rounded-2xl p-2 md:mr-4 mb-4 md:mb-1"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <div className="p-2">
                            <DashboardTitle label={column.title} />
                          </div>
                          {column.items.length !== 0 ? (
                            column.items.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    className="p-2  rounded-lg mb-2 relative"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {/* bg-mMenuSelect */}
                                    <div className="flex min-h-28 flex-col justify-start items-start p-4 bg-mMenuSelect mt-3 rounded-xl w-full">
                                      <div
                                        className="flex-row flex h-full w-full justify-center items-center"
                                        onClick={() => clickEdit(item)}
                                      >
                                        <img
                                          src={Images.Pdf}
                                          alt="Profile"
                                          className="cursor-pointer w-11 h-11"
                                        />
                                        <div className="h-full flex w-full ml-2 flex-col">
                                          <Menutext label={item.deal_name} />

                                          <div className="mt-1">
                                            <Menutitletext
                                              label={item.sector}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="mt-4 flex flex-row justify-between items-center w-full ">
                                        <div>
                                          <p
                                            className={`font-PlusJakartaSansRegular ${
                                              column.title === "Shortlisted"
                                                ? "text-Shortlisted"
                                                : column.title === "Contacted"
                                                ? "text-Contacted"
                                                : column.title === "Pitched"
                                                ? "text-Pitched"
                                                : "text-Won"
                                            } text-mTextColorLogin text-base`}
                                          >
                                            {column.title}
                                          </p>
                                        </div>
                                        <div className="cursor-pointer">
                                          <MenuIcon
                                            className="w-6 h-6 flex-shrink-0 "
                                            onClick={() => menuClick(item)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {activeMenu === item.id && (
                                      <div
                                        className="absolute right-0 mt-1 w-40 bg-white p-3 rounded-xl shadow shadow-mChatbg z-10"
                                        ref={menuRef}
                                      >
                                        <ul>
                                          <li
                                            className="px-2 py-2  cursor-pointer"
                                            onClick={() => clickEdit(item)}
                                          >
                                            <div className="flex flex-row justify-start items-center">
                                              <MenuEditIcon className="w-5 h-5 flex-shrink-0 " />
                                              <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base ml-3">
                                                {t("EditDetails")}{" "}
                                              </p>
                                            </div>
                                          </li>
                                          <li
                                            className="px-2 py-2 mt-2 cursor-pointer"
                                            onClick={() => clickDelete(item)}
                                          >
                                            <div className="flex flex-row justify-start items-center">
                                              <MenuDeleteIcon className="w-5 h-5 flex-shrink-0 " />
                                              <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base ml-3">
                                                {t("Delete")}{" "}
                                              </p>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))
                          ) : (
                            <div className="w-full h-full justify-center items-center flex mt-5 md:mt-0">
                              <Titletext label={t("nodata")} />
                            </div>
                          )}

                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  );
                })}
              </div>
            </div>
          </DragDropContext>

          {ShowInvestorDetails ? (
            <CRMListDetails
              show={ShowInvestorDetails}
              onCancel={clickClose}
              clickRefresh={clickRefresh}
              getInvestors={SelectInvestor}
            />
          ) : (
            <div></div>
          )}

          {/* Delete Confirmation Dialog */}
          <DeleteDialog
            isOpen={isDialogOpen}
            onCancel={closeDialog}
            onConfirm={confirmDelete}
            title={"Delete Confirmation"}
            message={" Are you sure you want to delete this item? "}
          />
        </div>
      )}
    </>
  );
};

export default CRMList;
