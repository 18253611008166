import React from "react";

// App Logo in text formet
const LogoText = ({ label }) => (
  <div className="flex">
    <p className="text-[28px] 3xl:text-[32px] font-semibold leading-normal font-HelveticaNowDisplayBold text-mTextColor h-[38px]">
      {/* Zen pivot */}
      {label}
    </p>
    <p className="text-[28px] 3xl:text-[32px] font-semibold leading-normal font-HelveticaNowDisplayBold text-mButtonColor h-[38px]">
      .
    </p>
  </div>
);

export default LogoText;
