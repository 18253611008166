import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import { Images } from "../../Utils/Constants";
import { mGetprofile, setProfileImage } from "../../Services/allAPI";
import { toast } from "react-toastify";
import HeaderMenu from "../../Components/HeaderMenu";
import ProfileSideMenu from "../../Components/ProfileSideMenu";

import Profileinfo from "./Profileinfo";
import Companyinfo from "./Companyinfo";
import Accountinfo from "./Accountinfo";
import SubscriptionInfo from "./SubscriptionInfo";
import Profileinfoedit from "./Profileinfoedit";
import Companyinfoedit from "./Companyinfoedit";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/slice/userSlice";

const ProfilePage = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const [isEditAbout, setisEditAbout] = useState(false);

  const [showView, setshowView] = useState(0);

  const [profileEditShow, setprofileEditShow] = useState(false);
  const [companyEditShow, setcompanyEditShow] = useState(false);

  const [profileInformation, setProfileInformation] = useState([]);
  const [companyInformation, setCompanyInformation] = useState([]);
  const [UploadFiles, setUploadFiles] = useState([]);
  const dispatch = useDispatch();

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  //
  useEffect(() => {
    ProfilePageAPICall();
  }, []);

  // Service Page API call
  const ProfilePageAPICall = async () => {
    setLoading(true);
    const response = await mGetprofile({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      setUserImage("");

      if (response?.data.message.status) {
        setProfileInformation(response?.data.message.profile_information);
        setCompanyInformation(response?.data.message.company_information);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const handleClick = (itemName) => {
    // alert(`You clicked on ${itemName}`);
    if (itemName === 0) {
      if (UserInfoData.user_type === "Investor") {
        setshowView(0);
      } else {
        setshowView(0);
      }
    } else if (itemName === 1) {
      if (UserInfoData.user_type === "Investor") {
        setshowView(3);
      } else {
        setshowView(1);
      }
    } else if (itemName === 2) {
      //setshowView(2);
      setshowView(3);
    } else if (itemName === 3) {
      setshowView(3);
    } else if (itemName === 4) {
      setshowView(0);
    }
  };

  const clickClose = () => {
    ProfilePageAPICall();
    setprofileEditShow(false);
  };

  const clickOutsideClose = () => {
    setprofileEditShow(false);
  };

  const Editclick = () => {
    setprofileEditShow(true);
    setisEditAbout(false);
  };

  const EditAboutclick = () => {
    setprofileEditShow(true);
    setisEditAbout(true);
  };

  const CompEditclick = () => {
    setcompanyEditShow(true);
    setisEditAbout(false);
  };

  const CompEditAboutclick = () => {
    setcompanyEditShow(true);
    setisEditAbout(true);
  };

  const clickCompClose = () => {
    ProfilePageAPICall();
    setcompanyEditShow(false);
  };

  const clickCompOutsideClose = () => {
    setcompanyEditShow(false);
  };

  // Image Change
  const [userimage, setUserImage] = useState("");
  //const [uploadimage, setUploadImage] = useState("");
  const inputRef = useRef();
  const onChooseImg = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    // const selectedFile = e.target.files[0];
    // setFile(selectedFile);
    // console.log("file : " + file);
    // if (selectedFile) {
    //   convertToBase64(selectedFile);
    // }
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      // reader.addEventListener("load", () => setImgSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      const file = e.target.files[0];
      if (file) {
        const fileName = file.name.split(".");
        const fileExtension = file.name.split(".").pop();
        console.log(file);
        console.log(fileName[0]);
        console.log(fileExtension);
        convertToBase64(file, fileName[0], fileExtension);
      }
    }
  };

  const convertToBase64 = (file, fileName, fileExtension) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    console.log(fileExtension);
    reader.onloadend = () => {
      setUserImage(reader.result);
      var attachFile = "";
      if (fileExtension === "png") {
        attachFile = reader.result.replace("data:image/png;base64,", "");
      } else if (fileExtension === "jpg" || fileExtension === "jpeg") {
        attachFile = reader.result.replace("data:image/jpeg;base64,", "");
      }
      // setUploadImage(attachFile);
      // console.log(attachFile);
      let myObject = {
        file_type: fileExtension,
        file: attachFile,
        file_name: fileName,
      }; // create an object

      // setUploadFiles([...UploadFiles, myObject]);
      setUploadFiles((prevUploadFiles) => [...prevUploadFiles, myObject]);
      console.log("UploadFiles" + JSON.stringify(UploadFiles));
      console.log("UploadFiles" + userimage);
      uploadImage(fileExtension, attachFile, fileName);
    };
    reader.onerror = (error) => {
      console.error("Error: ", error);
    };
  };

  // upload Image API call
  const uploadImage = async (fileExtension, attachFile, fileName) => {
    setLoading(true);
    const response = await setProfileImage({
      user_name: UserInfoData.user_email,
      file_name: fileName,
      file_type: fileExtension,
      profile_image: attachFile,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);

      if (response?.data.message.status) {
        dispatch(setUser({ user: response?.data.message.userinfo }));
        localStorage.setItem(
          "UserInfo",
          JSON.stringify(response?.data.message.userinfo)
        );
        toast.success(response?.data.message.message);
        ProfilePageAPICall();
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const removeClick = () => {
    setUserImage("");

    uploadImage("", "", "");
  };
  // Image change End
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex h-screen overflow-hidden  bg-no-repeat bg-cover ">
            {/* Sidebar */}
            <SideMenu mFrom={-1} mProfile={0} />

            {/* Main content bg-mDashboardBg */}
            <div className="flex flex-col flex-grow overflow-hidden  bg-mDashboardBg">
              {/* Top Navigation */}

              <HeaderMenu title={t("profile")} notShowsubscription={true} />

              {/* Scrollable content scrollbar-hide */}
              <main className="flex-grow  overflow-auto  mt-2 mb-2 mr-2 p-3 md:p-5 lg:p-8">
                <div className="h-full">
                  <div className="w-full py-1 lg:py-2 items-center justify-start flex ">
                    <div>
                      {userimage.length === 0 ? (
                        profileInformation.length > 0 ? (
                          profileInformation[0].profile_image.length > 0 ? (
                            <img
                              src={profileInformation[0].profile_image}
                              alt="Profile"
                              className="w-12 h-12 lg:w-16 lg:h-16 rounded-full ml-1 lg:ml-3"
                            />
                          ) : (
                            <img
                              src={Images.profileimage}
                              alt="Profile"
                              className="w-12 h-12 lg:w-16 lg:h-16 rounded-full ml-1 lg:ml-3"
                            />
                            // <p>64</p>
                          )
                        ) : (
                          <img
                            src={Images.profileimage}
                            alt="Profile"
                            className="w-12 h-12 lg:w-16 lg:h-16 rounded-full ml-1 lg:ml-3"
                          />
                        )
                      ) : (
                        <img
                          src={userimage}
                          alt="Profile"
                          className="w-12 h-12 lg:w-16 lg:h-16 rounded-full ml-1 lg:ml-3 "
                        />
                      )}
                    </div>

                    <div
                      className="h-9 lg:h-11 ml-2 lg:ml-6 p-1 lg:p-3 bg-mButtonColorligth rounded-lg items-center justify-center flex  cursor-pointer"
                      onClick={() => onChooseImg()}
                    >
                      <div
                        className="  justify-center items-center flex flex-col cursor-pointer absolute "
                        onClick={() => onChooseImg()}
                      >
                        <input
                          className=" h-44 w-full absolute"
                          id="file"
                          ref={inputRef}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </div>
                      <p className="ml-2 font-PlusJakartaSansSemiBold text-mButtonColor text-base 3xl:text-base ">
                        {" "}
                        {t("Change")}{" "}
                      </p>
                    </div>

                    <div
                      className="h-9 lg:h-11 ml-2 lg:ml-6 p-1 lg:p-3 bg-white rounded-lg items-center justify-center flex cursor-pointer"
                      onClick={() => removeClick()}
                    >
                      <p className="ml-2 font-PlusJakartaSansSemiBold text-mTextColorLogin text-base 3xl:text-base ">
                        {t("Remove")}
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 lg:mt-10 h-full w-full flex-col lg:flex-row flex rounded-xl ">
                    <div className="w-full ml-2 lg:h-screen  lg:w-1/5 bg-white rounded-md lg:rounded-xl">
                      <ProfileSideMenu
                        mFrom={showView}
                        handleClick={handleClick}
                      />
                    </div>
                    <div className="w-full lg:w-4/5 mt-3 lg:mt-0  h-full ml-2 lg:ml-4 ">
                      {showView === 0 ? (
                        <Profileinfo
                          mEditclick={Editclick}
                          mEditAboutclick={EditAboutclick}
                          getprofileInformation={profileInformation}
                        />
                      ) : showView === 1 ? (
                        <Companyinfo
                          mcompEditclick={CompEditclick}
                          mcompEditAboutclick={CompEditAboutclick}
                          companyInformation={companyInformation}
                        />
                      ) : showView === 2 ? (
                        <SubscriptionInfo />
                      ) : (
                        <Accountinfo />
                      )}
                    </div>
                  </div>
                </div>
              </main>
              {profileEditShow ? (
                <Profileinfoedit
                  show={profileEditShow}
                  onCancel={clickClose}
                  isEditAbout={isEditAbout}
                  clickCompOutsideClose={clickOutsideClose}
                  profileInformation={profileInformation}
                />
              ) : (
                <div></div>
              )}

              {companyEditShow ? (
                <Companyinfoedit
                  show={companyEditShow}
                  onCancel={clickCompClose}
                  isEditAbout={isEditAbout}
                  clickCompOutsideClose={clickCompOutsideClose}
                  companyInformation={companyInformation}
                />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
