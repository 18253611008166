import React from "react";

// Custom Text

const DashboardTitle = ({ label, issmall, changecolor }) => (
  <p
    className={`
      ${
        issmall
          ? "text-sm font-PlusJakartaSansRegular"
          : "font-PlusJakartaSansSemiBold text-base xl:text-lg 3xl:text-lg"
      }  ${changecolor ? "text-white" : "text-mTextColor"} text-mTextColor`}
  >
    {label}
  </p>
);

export default DashboardTitle;
