import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import { Images } from "../../Utils/Constants";
import { mGetDashboard, mServices } from "../../Services/allAPI";
import { toast } from "react-toastify";

import Headingtext from "../../Components/Headingtext";
import HeaderMenu from "../../Components/HeaderMenu";
import DashboardTitle from "../../Components/DashboardTitle";
import SecondaryButton from "../../Components/SecondaryButton";
import CreateNewVault from "../Valut/CreateNewVault";
import ServiceviewDialog from "./ServiceviewDialog";
import Titletext from "../../Components/Titletext";
import MyServiceviewDialog from "./MyServiceviewDialog";
import { UserAlertDialog } from "../../Components/UserAlertDialog";

const ServicePage = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [servicedata, setServiceData] = useState([]);
  const [myservicedata, setMyServiceData] = useState([]);
  const [ShowserviceDetails, setShowserviceDetails] = useState(false);
  const [Showselectedservice, setShowselectedservice] = useState({});

  const [ShowmyserviceDetails, setShowmyserviceDetails] = useState(false);
  const [Showselectedmyservice, setShowselectedmyservice] = useState({});

  //Set user Alert Msg
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  //
  useEffect(() => {
    ServicePageAPICall();
    if (!UserInfoData.view_status) {
      setIsAlertOpen(true);
    }
  }, []);

  //Set user Alert close
  const closeAlert = () => setIsAlertOpen(false);

  //Set user Alert reload page
  const Confirm = () => {
    setIsAlertOpen(false);
    window.location.reload();
  };

  // Service Page API call
  const ServicePageAPICall = async () => {
    setLoading(true);
    const response = await mServices({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setServiceData(response?.data.message.our_services);
        setMyServiceData(response?.data.message.my_services);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const handleItemClick = (mDeck_Id) => {
    navigate("/deckdetails", {
      state: {
        mDeck_Id: mDeck_Id,
      },
    });
  };

  const viewClick = (item) => {
    setShowselectedservice(item);
    console.log(item);
    setShowserviceDetails(true);
  };

  const myserviceClick = (item) => {
    setShowselectedmyservice(item);
    console.log(item);
    setShowmyserviceDetails(true);
  };

  const clickClose = () => {
    setShowserviceDetails(false);
  };

  const myserviceClose = () => {
    setShowmyserviceDetails(false);
  };

  const newserviceCreate = () => {
    ServicePageAPICall();
    setShowserviceDetails(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex h-screen overflow-hidden  bg-no-repeat bg-cover">
            {/* Sidebar */}
            <SideMenu mFrom={4} mProfile={-1} />

            {/* Main content */}
            <div className="flex flex-col flex-grow overflow-hidden  bg-mDashboardBg">
              {/* Top Navigation */}

              <HeaderMenu title={t("Services")} />

              {/* Scrollable content scrollbar-hide */}
              <main
                className={`flex-grow  overflow-auto  mt-2 mb-2 mr-2 p-2 md:p-6 lg:p-8 ${
                  !UserInfoData.view_status ? "blur-sm pointer-events-none" : ""
                }`}
              >
                {servicedata.length !== 0 ? (
                  <div>
                    <div>
                      <DashboardTitle label={t("OurServices")} />

                      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-6 w-full mt-5">
                        {servicedata.map((item) => (
                          <div
                            className="bg-white p-4 rounded-sm md:rounded-md lg:rounded-lg grid grid-cols-1 gap-5"
                            key={item.id}
                          >
                            <DashboardTitle label={item.service_name} />

                            <div>
                              <p className="font-PlusJakartaSansRegular text-base text-mTextColor">
                                {t("Description")}
                              </p>

                              <p className="font-PlusJakartaSansRegular text-sm text-mTextColor mt-1 leading-6 line-clamp-3 min-h-16">
                                {item.description}
                              </p>
                            </div>

                            <DashboardTitle
                              label={t("rupess") + " " + item.pricing_format}
                            />

                            <SecondaryButton
                              label={t("View")}
                              onClick={() => viewClick(item)}
                            ></SecondaryButton>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="mt-10">
                      {myservicedata.length === 0 ? (
                        <div></div>
                      ) : (
                        <DashboardTitle label={t("MyServices")} />
                      )}

                      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4  gap-6 w-full mt-5">
                        {myservicedata.map((item) => (
                          <div
                            className="bg-white p-4 rounded-lg grid grid-cols-1 gap-5"
                            key={item.id}
                          >
                            <DashboardTitle label={item.service_name} />

                            <div>
                              <p className="font-PlusJakartaSansRegular text-base text-mTextColor">
                                {t("Description")}
                              </p>

                              <p className="font-PlusJakartaSansRegular text-sm text-mTextColor mt-1 leading-6 line-clamp-3 min-h-16">
                                {item.description}
                              </p>
                            </div>

                            <DashboardTitle
                              label={t("rupess") + " " + item.pricing_format}
                            />

                            <SecondaryButton
                              label={t("View")}
                              onClick={() => myserviceClick(item)}
                            ></SecondaryButton>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="justify-center items-center flex-col flex w-full h-full ">
                    <img
                      className="rounded-lg bg-white "
                      src={Images.nodata}
                      alt="review"
                    />
                    <div className="mt-10">
                      {" "}
                      <Titletext label={t("nodata")} />
                    </div>
                  </div>
                )}
              </main>

              {ShowserviceDetails ? (
                <ServiceviewDialog
                  show={ShowserviceDetails}
                  onConfirm={newserviceCreate}
                  service={Showselectedservice}
                  onCancel={clickClose}
                />
              ) : (
                <div></div>
              )}

              {ShowmyserviceDetails ? (
                <MyServiceviewDialog
                  show={ShowmyserviceDetails}
                  // onConfirm={newserviceCreate}
                  service={Showselectedmyservice}
                  onCancel={myserviceClose}
                />
              ) : (
                <div></div>
              )}

              <UserAlertDialog
                isOpen={isAlertOpen}
                onClose={closeAlert}
                Confirm={Confirm}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServicePage;
