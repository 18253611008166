import React from "react";
// Custom button view
const PrimaryButton = ({ label, onClick, onDisable }) => {
  return (
    <button
      className={`  text-white text-base 3xl:text-lg p-4 rounded-lg w-full h-14 3xl:h-16 mx-auto font-PlusJakartaSansSemiBold ${
        onDisable ? "bg-mButtonDisable cursor-not-allowed" : "bg-mButtonColor"
      }`}
      onClick={onClick}
      disabled={onDisable}
    >
      {label}
    </button>
  );
};

export default PrimaryButton;
