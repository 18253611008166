import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";
import { ReactComponent as UploadIcon } from "../../assets/Images/ic_upload.svg";
import TextView from "../../Components/TextView";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { updatenewFile, updateVault } from "../../Services/allAPI";
import { toast } from "react-toastify";
import Loader from "../../Utils/Loader";

import { ReactComponent as Docclose } from "../../assets/Images/new_ic_docclose.svg";

import "../../Components/HorizontalListView/HorizontalListView.css";
import { Images } from "../../Utils/Constants";

const CreateNewFile = ({
  onConfirm,
  onCancel,
  show,
  isEdit,
  Title,
  selectedItem,
  valutdetails,
  buttonname,
}) => {
  const { t } = useTranslation("");
  const [valutname, setValutName] = useState(valutdetails.vault_name);
  const [description, setDescription] = useState(valutdetails.description);

  const [loading, setLoading] = useState(false);

  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const newVaultClick = () => {
    if (isEdit) {
      onUpdateNewFile();
    } else {
      onLoadEdit();
    }
  };

  // Edit API call
  const onLoadEdit = async () => {
    setLoading(true);
    const response = await updateVault({
      user_name: UserInfoData.user_email,
      vault_id: valutdetails.id,
      vault_name: valutname,
      description: description,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        // setDashboardData(response?.data.message.deck_details);
        toast.success(response?.data.message.message);
        onConfirm();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  // Update new File API call
  const onUpdateNewFile = async () => {
    setLoading(true);
    const response = await updatenewFile({
      user_name: UserInfoData.user_email,
      vault_id: valutdetails.id,
      file_name: UploadFiles[0].file_name,
      file_type: UploadFiles[0].file_type,
      file: UploadFiles[0].file,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        // setDashboardData(response?.data.message.deck_details);
        toast.success(response?.data.message.message);
        onConfirm();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  //Upload New file start
  const inputRef = useRef();
  const [UploadFiles, setUploadFiles] = useState([]);

  const handleFileChange = (e) => {
    // const selectedFile = e.target.files[0];
    // setFile(selectedFile);
    // console.log("file : " + file);
    // if (selectedFile) {
    //   convertToBase64(selectedFile);
    // }
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();

      // reader.addEventListener("load", () => setImgSrc(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      const file = e.target.files[0];
      if (file) {
        const fileName = file.name.split(".");
        const fileExtension = file.name.split(".").pop();
        console.log(file);
        console.log(fileName[0]);
        console.log(fileExtension);
        convertToBase64(file, fileName[0], fileExtension);
      }
    }
  };

  const convertToBase64 = (file, fileName, fileExtension) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    console.log(fileExtension);
    reader.onloadend = () => {
      var attachFile = "";
      if (fileExtension === "pdf") {
        attachFile = reader.result.replace("data:application/pdf;base64,", "");
      } else if (fileExtension === "docx" || file === "doc") {
        attachFile = reader.result.replace(
          "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,",
          ""
        );
      } else if (fileExtension === "png") {
        attachFile = reader.result.replace("data:image/png;base64,", "");
      } else if (fileExtension === "jpg" || fileExtension === "jpeg") {
        attachFile = reader.result.replace("data:image/jpeg;base64,", "");
      } else if (fileExtension === "xlsx") {
        attachFile = reader.result.replace(
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          ""
        );
      }
      console.log(attachFile);
      let myObject = {
        file_type: fileExtension,
        file: attachFile,
        file_name: fileName,
      }; // create an object

      setUploadFiles([...UploadFiles, myObject]);
      console.log(UploadFiles);
    };
    reader.onerror = (error) => {
      console.error("Error: ", error);
    };
  };

  const onChooseFile = () => {
    inputRef.current.click();
  };

  const onDeleteImage = (index) => {
    setUploadFiles(UploadFiles.filter((person, i) => i !== index));
  };

  // UPload new file end

  const isDisabled =
    !valutname || !description || (isEdit && UploadFiles.length === 0);

  const dialogRef = useRef();

  // Close the dialog when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onCancel(); // Call onClose if the click is outside the dialog
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onCancel]);

  if (!show) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  z-50">
      {loading ? (
        <div className="h-1/3">
          <Loader />
        </div>
      ) : (
        <div
          className={`bg-mDashboardBg ${
            isEdit ? "h-90" : ""
          } rounded-lg mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4`}
          ref={dialogRef}
        >
          <div className="justify-between flex">
            <div>
              <p className="font-PlusJakartaSansSemiBold text-lg text-mTextColor">
                {Title}
              </p>
            </div>

            <div>
              <button onClick={onCancel}>
                <CloseIcon className="mb-4 " />
              </button>
            </div>
          </div>

          <div className="mt-5">
            <div className="mt-4">
              <TextView label={t("Filename")} />
            </div>
            <InputContainer
              type="text"
              value={valutname}
              placeholder={t("EnterVaultName")}
              label="text"
              name="text"
              disabled={false}
              maxLength={100}
              showIcon={false}
              onChange={(e) => {
                setValutName(e.target.value);
              }}
            />
          </div>
          <div className="mt-4">
            <div className="mt-4">
              <TextView label={t("Description")} />
            </div>
            <div className="h-32 mt-2 border-mGreyFour text-start justify-start items-start flex text-xs  lg:text-sm  rounded-md w-full  border bg-mLightColorOne">
              <textarea
                id="message"
                rows="5"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                maxLength={3000}
                className="block  w-full border-black  text-sm h-full break-words text-mTextColor px-2 py-3 text-start justify-start items-start  rounded-md   font-PlusJakartaSansRegular border-none focus:border-none  bg-mLightColorOne border-4 !outline-none"
                placeholder={t("EnterDescription")}
              ></textarea>
            </div>
          </div>
          {!isEdit ? (
            <div></div>
          ) : (
            <div className="mt-4">
              <div className="mt-4">
                <TextView label={t("UploadFile")} />
              </div>
              <div className="min-h-40 mt-2 border-mGreyFour text-start flex text-xs  lg:text-sm  rounded-md w-full  border bg-mLightColorOne items-center justify-center flex-col">
                {UploadFiles.length === 0 ? (
                  <div
                    className="w-full h-full justify-center items-center flex flex-col"
                    onClick={onChooseFile}
                  >
                    <div className="flex ">
                      <UploadIcon className=" " />
                      <p className="font-PlusJakartaSansSemiBold text-lg text-mButtonColor ml-3">
                        {t("UploadFile")}
                      </p>
                    </div>

                    <input
                      type="file"
                      style={{
                        display: "none",
                      }}
                      ref={inputRef}
                      onChange={handleFileChange}
                      accept=".pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,"
                    />
                  </div>
                ) : (
                  <div className="horizontal-list">
                    {UploadFiles.map((item, index) => (
                      <div
                        key={item.id}
                        className="justify-center items-center flex p-2 "
                      >
                        <div className="w-32 bg-white p-1 border border-mGreyFive rounded-lg">
                          <div className="w-full flex justify-end items-center ">
                            <Docclose
                              className="w-5 h-5 text-right "
                              onClick={() => {
                                onDeleteImage(index);
                              }}
                            />
                          </div>

                          <div className="w-full flex flex-row justify-center items-center mt-2">
                            {item.file_type === "pdf" ? (
                              <img
                                src={Images.Pdf}
                                alt="Profile"
                                className="cursor-pointer w-11 h-11"
                              />
                            ) : item.file_type === "docx" ||
                              item.file_type === "doc" ? (
                              <img
                                src={Images.Word}
                                alt="Profile"
                                className="cursor-pointer w-11 h-11"
                              />
                            ) : item.file_type === "xlsx" ? (
                              <img
                                src={Images.Excel}
                                alt="Profile"
                                className="cursor-pointer w-11 h-11"
                              />
                            ) : item.file_type === "png" ||
                              item.file_type === "jpg" ||
                              item.file_type === "jpeg" ? (
                              <img
                                src={Images.Image}
                                alt="Profile"
                                className="cursor-pointer w-11 h-11"
                              />
                            ) : (
                              <img
                                src={Images.Image}
                                alt="Profile"
                                className="cursor-pointer w-11 h-11"
                              />
                            )}
                          </div>
                          <p className="mt-1 font-OpenSauceSansRegular text-sm text-mGreySeven text-center line-clamp-2">
                            {item.file_name}.{item.file_type}
                          </p>
                          <div className="h-4"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="mt-10">
            <PrimaryButton
              label={buttonname}
              onClick={newVaultClick}
              onDisable={isDisabled}
            ></PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateNewFile;
