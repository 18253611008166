import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
// import "./ScrollableView.css";

import { ReactComponent as CloseIcon } from "../../assets/Images/new_ic_close.svg";
import PdfUploader from "../../Components/PdfUploader";
import { mUploadDeckDashboard } from "../../Services/allAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { mTimerValue } from "../../Utils/Constants";

const DashboardDialog = ({ onClose, Userid, onDeckSucess }) => {
  const { t } = useTranslation("");
  const [FileName, setFileName] = useState("");
  const [base64, setBase64] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Set Progressbar
  const [progress, setProgress] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const buttonRef = useRef(null);

  const handleFileSelect1 = async (file) => {
    //  setSelectedFile(file);
    console.log(file);

    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const base64String = await convertToBase64(file);
    setBase64(base64String);

    console.log("Base64 String:", base64String);

    var mCropImage = "";

    if (base64String.length === 0) {
      mCropImage = base64String.replace("data:application/pdf;base64,", "");
    } else {
      mCropImage = base64String.replace("data:application/pdf;base64,", "");
    }

    // console.log(mCropImage);
    setFileName(file.name);
    setLoading(false);
    const response = await mUploadDeckDashboard({
      deck_name: file.name,
      deck_doc: mCropImage,
      user_name: Userid,
    });
    console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setIsRunning(true);
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  //Function to convert file to Base64
  const convertToBase64 = (file) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // One min interval
  useEffect(() => {
    if (!isRunning) return;

    const intervalTime = mTimerValue / 100; // Update every 0.6 seconds (1% of 60 seconds)

    const interval = setInterval(() => {
      if (buttonRef.current) {
        buttonRef.current.click();
      }
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);

          return 100;
        }

        return prevProgress + 1;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isRunning]);

  return (
    <div className="fixed inset-0  flex items-center justify-center bg-black bg-opacity-50  z-50">
      {/* max-w-sm */}
      <div className="bg-white scroll-view scrollbar-hide  overflow-y-auto h-90 rounded-lg overflow-hidden shadow-xl w-2/5  px-6 py-6 flex-col flex">
        <div className="justify-end items-end flex ">
          <div className="w-full flex">
            <div className="w-5/6">
              <p className="text-left font-OpenSauceSansSemiBold  text-2xl ">
                {t("uploaddeck")}
              </p>
            </div>

            <div className="w-1/6 justify-center items-center flex">
              <button onClick={onClose}>
                <CloseIcon className="mb-4 " />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-8 h-full w-full justify-center items-center flex flex-col ">
          <PdfUploader onFileSelect={handleFileSelect1} hidelogin={1} />

          <div className="mt-3"></div>

          {progress >= 100 ? (
            <div ref={buttonRef} onClick={onDeckSucess}></div>
          ) : (
            <div></div>
          )}

          {isRunning ? (
            <div className="flex-col flex p-3 w-full bg-purple-200 rounded-lg">
              <div className="font-OpenSauceSansRegular text-base text-black items-center justify-center w-full flex">
                <p>{FileName}</p>
              </div>

              <div className="mt-2"></div>
              {/* Progress bar start */}
              <div
                style={{
                  width: "100%",

                  margin: "0 auto",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    backgroundColor: "#e0e0df",
                    borderRadius: "5px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      width: `${progress}%`,
                      height: "100%",
                      backgroundColor: progress < 100 ? "#76c7c0" : "#4caf50",
                      transition: "width 1s ease-out",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#fff",
                      fontWeight: "normal",
                    }}
                  >
                    {progress}%
                  </div>
                </div>
              </div>

              {/* Progress bar End */}
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardDialog;
