import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Utils/Loader";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../assets/Images/ic_back.svg";
import { ReactComponent as EmailIcon } from "../../assets/Images/ic_email.svg";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import OTPInput from "react-otp-input";
import { useNavigate, useLocation } from "react-router-dom";
import { Images } from "../../Utils/Constants";
import { mResetpassword } from "../../Services/allAPI";
import { toast } from "react-toastify";
import Headingtext from "../../Components/Headingtext";
import TextView from "../../Components/TextView";
import LogoText from "../../Components/LogoText";

const ResetPasswordOTP = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [code, setCode] = useState("");
  const handleChange = (code) => {
    setCode(code);
    // const regex = /^[0-9\b]+$/;
    // if (code === "" || regex.test(code)) {
    //   setCode(code);
    //   console.log(code);
    // }
  };

  const location = useLocation();
  const email = location.state?.mEmail || "";

  //Send OTP Click

  const savePwdClick = () => {
    ForgetPwdOTPCall();
  };

  const ForgetPwdOTPCall = async () => {
    // navigate("/resetpasswordotp");
    setLoading(true);
    const response = await mResetpassword({
      email: email,
      otp: code,
      new_password: password,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        navigate("/login");
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  // Password Show and hide
  const PasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const mBackClick = () => {
    navigate("/resetpassword");
  };

  // Check Disable button
  const isDisabled = !password || !(code.length === 4);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="min-h-screen flex flex-row">
          <div className="hidden lg:block w-3/5 bg-mwhite flex-col items-start justify-start p-5">
            <div className="mt-3 ml-3">
              {/* <img
                src={Images.zenpivot}
                alt="google Icon"
                className=" h-9 w-28"
              /> */}
              <LogoText label={t("appname")} />
            </div>

            <div className="w-full h-auto justify-center items-center flex">
              <img src={Images.forgetpwd} alt="login" className="w-2/3 " />
            </div>
          </div>
          <div className="lg:w-2/5 w-full pl-5 py-5 lg:pl-20 lg:py-10 bg-mBackground items-center justify-start flex ">
            <div className="grid gap-10 grid-cols-1 w-11/12">
              <div className="flex flex-row items-center justify-start">
                <BackIcon
                  className="w-6 h-6 flex-shrink-0 cursor-pointer"
                  onClick={mBackClick}
                />{" "}
                <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-base">
                  {t("GoBack")}
                </p>
              </div>
              <div>
                <Headingtext label={t("ResetPassword")} />
              </div>
              <div>
                <TextView label={t("VerifyOTP")} ismandatory={true} />

                {/* <div className="mt-2">
                  <OTPInput
                    value={code}
                    onChange={handleChange}
                    inputType="tel"
                    numInputs={4}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    renderInput={(props) => (
                      <input {...props} placeholder={"-"} />
                    )}
                    inputStyle={{
                      border: "1px solid rgba(13, 13, 13, 0.10)",
                      borderRadius: "8px",
                      width: "110px",
                      height: "56px",
                      fontSize: "16px",
                      marginRight: "10px",
                      color: "rgba(13, 13, 13, 0.70)",
                      fontWeight: "400",
                    }}
                    focusStyle={{
                      border: "1px solid blue",
                      boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
                    }}
                  />
                </div> */}

                <div className="mt-2">
                  <OTPInput
                    value={code}
                    onChange={handleChange}
                    inputType="tel"
                    numInputs={4}
                    isInputNum={true}
                    shouldAutoFocus={true}
                    renderInput={(props) => (
                      <input {...props} placeholder="-" />
                    )}
                    inputStyle={{
                      border: "1px solid rgba(13, 13, 13, 0.10)",
                      borderRadius: "8px",
                      width: "100%",
                      height: "3.5rem", // Adjusting for better responsiveness
                      fontSize: "1rem", // 16px in rem
                      color: "rgba(13, 13, 13, 0.70)",
                      fontWeight: "400",
                      marginRight: "10px",
                    }}
                    focusStyle={{
                      border: "1px solid blue",
                      boxShadow: "0 0 5px rgba(0, 0, 255, 0.5)",
                    }}
                    containerStyle={{
                      display: "flex",
                      justifyContent: "center", // Centering the OTP input fields
                      gap: "10px", // Adding spacing between inputs
                    }}
                  />
                </div>
              </div>
              <div>
                <TextView label={t("NewPassword")} ismandatory={true} />
                <InputContainer
                  type={showPassword ? "text" : "password"}
                  value={password}
                  placeholder={t("EnterNewPassword")}
                  label="password"
                  name="password"
                  disabled={false}
                  maxLength={60}
                  secureTextEntry={true}
                  PasswordVisibility={PasswordVisibility}
                  setshowPassword={showPassword}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    // setLogin({ ...login, password: e.target.value });
                  }}
                />
              </div>
              <PrimaryButton
                label={t("SavePassword")}
                onClick={savePwdClick}
                onDisable={isDisabled}
              ></PrimaryButton>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ResetPasswordOTP;
