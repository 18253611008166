import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import { mInvestordb } from "../../Services/allAPI";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import HeaderMenu from "../../Components/HeaderMenu";
import BoardView from "./BoardView";
import SuggestedInvestors from "./SuggestedStartupInvestors";
import SuggestedStartupInvestors from "./SuggestedStartupInvestors";
import { ReactComponent as Filter } from "../../assets/Images/ic_filter.svg";
import { UserAlertDialog } from "../../Components/UserAlertDialog";

const StartupCRM = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [boardView, setBoardView] = useState(true);
  const [values, setValues] = useState([1, 10]);
  const [isChecked, setisChecked] = useState(false);
  const [investordb, setinvestordb] = useState([]);
  const [showDialog, setshowDialog] = useState(false);

  const user = useSelector((state) => state.userInfo.user);

  console.log("Get User :" + user);

  //Set user Alert Msg
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  //
  useEffect(() => {
    if (!UserInfoData.view_status) {
      setIsAlertOpen(true);
    }
  }, []);

  //Set user Alert close
  const closeAlert = () => setIsAlertOpen(false);

  //Set user Alert reload page
  const Confirm = () => {
    setIsAlertOpen(false);
    window.location.reload();
  };

  const onBoardClick = () => {
    // Handle the cancel action
    setBoardView(false);
    setshowDialog(false);
  };
  const onInvestorsClick = () => {
    // Handle the cancel action
    setBoardView(true);
  };

  const mFilterView = () => {
    // setBoardView(true);
    setshowDialog(true);
  };

  const clickClose = () => {
    setshowDialog(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex h-screen overflow-hidden bg-mDashboardBg  bg-no-repeat bg-cover">
            {/* Sidebar */}
            <SideMenu mFrom={3} mProfile={-1} />

            {/* Main content */}
            <div className="flex flex-col flex-grow overflow-hidden ">
              {/* Top Navigation */}

              <HeaderMenu title={t("InvestorsCRM")} />

              {/* <div
                className={`overflow-hidden ${
                  !UserInfoData.view_status ? "blur-sm pointer-events-none" : ""
                }`}
              > */}
              <div className="p-3 lg:p-4 mt-4 flex h-auto ">
                <div
                  className={`${
                    boardView ? "bg-mButtonColorligth" : "bg-white"
                  } rounded-md px-6 py-2 md:py-3 inline-block max-w-xs justify-center items-center   cursor-pointer`}
                  onClick={() => onInvestorsClick()}
                >
                  <p
                    className={`font-PlusJakartaSansRegular text-center flex w-full   ${
                      boardView ? "text-mButtonColor" : "text-mTextColorLogin"
                    }  text-sm lg:text-base `}
                  >
                    {t("SuggestedInvestors")}
                  </p>
                </div>

                <div
                  className={` ${
                    boardView ? "bg-white" : "bg-mButtonColorligth"
                  } rounded-md px-6 lg:px-10 py-2 md:py-3 lg:inline-block max-w-xs cursor-pointer ml-3 justify-center items-center flex lg:ml-10`}
                  onClick={() => onBoardClick()}
                >
                  <p
                    className={`font-PlusJakartaSansRegular text-center ${
                      boardView ? "text-mTextColorLogin" : "text-mButtonColor"
                    }  text-sm lg:text-base  `}
                  >
                    {t("Board")}
                  </p>
                </div>
                {boardView ? (
                  <div
                    className="ml-2 mr-2 lg:hidden justify-center flex items-center h-auto"
                    onClick={() => mFilterView()}
                  >
                    <Filter className="w-5 h-5 flex-shrink-0 " />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              {/* Scrollable content */}
              <main className="flex-grow overflow-auto  mt-1 mb-3 px-3 lg:px-4 ">
                {/* Adjusted padding top to avoid overlap */}
                {boardView || showDialog ? (
                  <SuggestedStartupInvestors
                    onShowDialog={showDialog}
                    onDialogCancel={clickClose}
                  />
                ) : (
                  <BoardView />
                )}
              </main>
              {/* </div> */}

              <UserAlertDialog
                isOpen={isAlertOpen}
                onClose={closeAlert}
                Confirm={Confirm}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StartupCRM;
