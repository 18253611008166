import React from "react";

// Custom text view
const TextViewlight = ({ label, isCenter }) => (
  <p
    className={`font-PlusJakartaSansRegular font-normal leading-7 text-mTextColorLogin text-sm md:text-base xl:text-lg 3xl:text-lg ${
      isCenter ? "text-center" : ""
    }`}
  >
    {label}
  </p>
);

export default TextViewlight;
