import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";
import { ReactComponent as EmptyroundIcon } from "../../assets/Images/ic_emptyround.svg";
import { ReactComponent as UploadIcon } from "../../assets/Images/ic_upload.svg";
import TextView from "../../Components/TextView";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import DashboardTitle from "../../Components/DashboardTitle";
import { mCreateServices } from "../../Services/allAPI";
import { toast } from "react-toastify";
import Loader from "../../Utils/Loader";

const MyServiceviewDialog = ({ show, onConfirm, onCancel, service }) => {
  const { t } = useTranslation("");
  const [valutname, setValutName] = useState("");
  const [description, setDescription] = useState("");
  const isDisabled = !valutname || !description;
  const [loading, setLoading] = useState(false);

  const CreateService = () => {
    // if (!isValidEmail(email)) {
    //   toast.error(t("vaildmail"));
    //   return;
    // }
    // NormalSignupCall();
  };

  //alert(JSON.stringify(service));

  const uploaddocClick = () => {};
  const downloadinvoice = (Invoiceurl) => {
    // alert(service.service_invoice);
    window.open(service.service_invoice, "_blank");
  };

  const dialogRef = useRef();

  // Close the dialog when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onCancel(); // Call onClose if the click is outside the dialog
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onCancel]);

  if (!show) return null; // Don't render if the dialog is not open

  return (
    <>
      {loading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  z-50">
          {/* max-w-sm */}
          <div
            className="bg-mDashboardBg rounded-lg max-h-[50%] mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4"
            ref={dialogRef}
          >
            <Loader />
          </div>
        </div>
      ) : (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  z-50">
          {/* max-w-sm */}
          <div
            className="bg-mDashboardBg rounded-lg max-h-[90%] mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4"
            ref={dialogRef}
          >
            <div className="justify-between flex">
              <div>
                <p className="font-PlusJakartaSansSemiBold text-lg text-mTextColor">
                  {/* {t("ServiceProgress")} */}
                </p>
              </div>

              <div>
                <button onClick={onCancel}>
                  <CloseIcon className="mb-4 " />
                </button>
              </div>
            </div>

            <div className="relative">
              <div className="absolute top-1 left-1.5 w-0.5 bg-mBorderColor h-90"></div>

              {service.process_steps.map((service, index) => (
                <div key={service.steps} className="mb-6 relative pl-8">
                  <div className="absolute left-0 top-1 flex items-center justify-center w-4 h-4 rounded-full bg-mTextColor"></div>
                  <div className="flex justify-start items-center mb-1">
                    <div className="flex justify-between items-start mb-8  w-full">
                      <div className={`text-sm  text-black`}>
                        <div className="flex">
                          <p className="font-PlusJakartaSansSemiBold text-mTextColor text-base">
                            {service.steps}
                          </p>
                        </div>

                        {service.step_status ? (
                          service.steps === "Payment Done" ? (
                            <div>
                              <p
                                className="mt-1.5 font-PlusJakartaSansRegular text-mButtonColor text-mFourteen underline cursor-pointer"
                                onClick={() =>
                                  downloadinvoice(service.service_invoice)
                                }
                              >
                                Download Invoice
                              </p>
                            </div>
                          ) : (
                            <div></div>
                          )
                        ) : (
                          <div>
                            <p className="mt-1.5 font-PlusJakartaSansRegular text-mTextColorLogin text-mFourteen ">
                              {service.tat} Days
                            </p>

                            {service.doc_status ? (
                              <div className="mt-3">
                                <button
                                  className={`  text-white text-base px-5 rounded-full h-8 font-PlusJakartaSansSemiBold ${"bg-mButtonColor"}`}
                                  onClick={uploaddocClick}
                                >
                                  {t("uploaddoc")}
                                </button>
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className={`text-sm  text-black`}>
                        <p
                          className={`font-PlusJakartaSansRegular text-base ${
                            service.current_status === "Under Progress"
                              ? "text-mBlueOne"
                              : service.current_status === "Completed"
                              ? "text-mButtonColor"
                              : service.current_status === "Hold"
                              ? "text-orange-400"
                              : "text-mTextColor"
                          }`}
                        >
                          {service.current_status}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyServiceviewDialog;
