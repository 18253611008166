import React from "react";

// Custom Text
const Headingtext = ({ label, changecolor }) => (
  <p
    className={`font-PlusJakartaSansSemiBold text-2xl md:text-4xl ${
      changecolor ? "text-white" : "text-mTextColor"
    } `}
  >
    {label}
  </p>
);

export default Headingtext;
