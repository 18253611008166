import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";
import { ReactComponent as UploadIcon } from "../../assets/Images/ic_upload.svg";
import TextView from "../../Components/TextView";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { isValidEmail } from "../../Utils/Constants";
import { toast } from "react-toastify";
import { mEditAboutprofile, mEditpersonaldetails } from "../../Services/allAPI";

const Profileinfoedit = ({
  show,
  onCancel,
  clickCompOutsideClose,
  isEditAbout,
  profileInformation,
}) => {
  // alert(JSON.stringify(profileInformation));
  const { t } = useTranslation("");
  const [fullname, setFullName] = useState(profileInformation[0].full_name);
  const [mobileno, setMobileno] = useState(profileInformation[0].mobile_no);
  const [email, setEmailId] = useState(profileInformation[0].mail_id);
  const [linkedin, setLinkedin] = useState(profileInformation[0].linkedin);
  const [gender, setGender] = useState(profileInformation[0].gender);
  const [about, setabout] = useState(profileInformation[0].about);
  const [loading, setLoading] = useState(false);

  const isDisabled = !fullname || !mobileno || !email || !gender;
  const isAboutDisabled = !about;

  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const saveClick = () => {
    if (!isValidEmail(email)) {
      toast.error(t("vaildmail"));
      return;
    }
    SaveCall();
  };

  // Profile save API Call
  const SaveCall = async () => {
    setLoading(true);
    const response = await mEditpersonaldetails({
      user_name: UserInfoData.user_email,
      full_name: fullname,
      gender: gender,
      mobile_no: mobileno,
      mail_id: email,
      linkedin: linkedin,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        onCancel();
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const aboutsaveClick = () => {
    if (about === "") {
      toast.error("Please enter about your details");
      return;
    }
    SaveAboutCall();
  };

  //  Save about API Call
  const SaveAboutCall = async () => {
    setLoading(true);
    const response = await mEditAboutprofile({
      user_name: UserInfoData.user_email,
      about: about,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        onCancel();
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const dialogRef = useRef();

  // Close the dialog when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        clickCompOutsideClose(); // Call onClose if the click is outside the dialog
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, clickCompOutsideClose]);

  if (!show) return null; // Don't render if the dialog is not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  z-50">
      {/* max-w-sm */}

      {!isEditAbout ? (
        <div
          className="bg-mDashboardBg rounded-lg h-90  mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4 "
          ref={dialogRef}
        >
          <div className="justify-between flex">
            <div>
              <p className="font-PlusJakartaSansSemiBold text-lg text-mTextColor">
                {t("update")} {t("PersonalInformation")}
              </p>
            </div>

            <div>
              <button onClick={onCancel}>
                <CloseIcon className="mb-4 " />
              </button>
            </div>
          </div>

          <div className="mt-5">
            <div className="mt-4">
              <TextView label={t("FullName")} ismandatory={true} />
            </div>
            <InputContainer
              type="text"
              value={fullname}
              placeholder={t("enter") + t("FullName")}
              label="text"
              name="text"
              disabled={false}
              maxLength={200}
              showIcon={false}
              onChange={(e) => {
                setFullName(e.target.value);
              }}
            />
          </div>
          <div className="mt-5">
            <div className="mt-4">
              <TextView label={t("Gender")} ismandatory={true} />
            </div>

            <div className="h-14  p-3 w-full justify-start flex items-center">
              <div className="flex flex-row">
                <input
                  type="radio"
                  value="Male"
                  checked={gender === "Male"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <p className="font-PlusJakartaSansRegular font-normal text-mTextColor text-base ml-2">
                  Male
                </p>
              </div>
              <div className="ml-5 flex flex-row">
                <input
                  type="radio"
                  value="Female"
                  checked={gender === "Female"}
                  onChange={(e) => setGender(e.target.value)}
                />
                <p className="font-PlusJakartaSansRegular font-normal text-mTextColor text-base ml-2">
                  Female
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <div className="mt-4">
              <TextView label={t("MobileNumber")} ismandatory={true} />
            </div>
            <InputContainer
              type="tel"
              value={mobileno}
              placeholder={t("enter") + t("MobileNumber")}
              label="mobile"
              name="mobile"
              disabled={false}
              maxLength={10}
              showIcon={false}
              onChange={(e) => {
                setMobileno(e.target.value);
              }}
            />
          </div>
          <div className="mt-5">
            <div className="mt-4">
              <TextView label={t("MailID")} ismandatory={true} />
            </div>
            <InputContainer
              type="email"
              value={email}
              placeholder={t("enter") + t("MailID")}
              label="email"
              name="email"
              disabled={false}
              maxLength={200}
              showIcon={false}
              onChange={(e) => {
                setEmailId(e.target.value);
              }}
            />
          </div>

          <div className="mt-5">
            <div className="mt-4">
              <TextView label={t("LinkedIn")} />
            </div>
            <InputContainer
              type="text"
              value={linkedin}
              placeholder={t("enter") + t("LinkedIn")}
              label="text"
              name="text"
              disabled={false}
              maxLength={200}
              showIcon={false}
              onChange={(e) => {
                setLinkedin(e.target.value);
              }}
            />
          </div>
          <div className="mt-5">
            <PrimaryButton
              label={t("save")}
              onClick={saveClick}
              onDisable={isDisabled}
            ></PrimaryButton>
          </div>
        </div>
      ) : (
        <div
          className="bg-mDashboardBg rounded-lg mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4 "
          ref={dialogRef}
        >
          <div className="justify-between flex">
            <div>
              <p className="font-PlusJakartaSansSemiBold text-lg text-mTextColor">
                {t("update")} {t("PersonalInformation")}
              </p>
            </div>

            <div>
              <button onClick={onCancel}>
                <CloseIcon className="mb-4 " />
              </button>
            </div>
          </div>

          <div className="mt-5">
            <div className="mt-4">
              <TextView label={t("About")} ismandatory={true} />
            </div>
            <div className="h-32 mt-2 border-mGreyFour text-start justify-start items-start flex text-xs  lg:text-sm  rounded-md w-full  border bg-mLightColorOne">
              <textarea
                id="message"
                rows="5"
                value={about}
                onChange={(e) => setabout(e.target.value)}
                maxLength={3000}
                className="block  w-full border-black  text-sm h-full break-words text-mTextColor px-2 py-3 text-start justify-start items-start  rounded-md   font-PlusJakartaSansRegular border-none focus:border-none  bg-mLightColorOne border-4 !outline-none"
                placeholder={t("enter") + t("About")}
              ></textarea>
            </div>
          </div>

          <div className="mt-5">
            <PrimaryButton
              label={t("save")}
              onClick={aboutsaveClick}
              onDisable={isAboutDisabled}
            ></PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profileinfoedit;
