import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export const AlertDialog = ({ isOpen, onClose, message, isShowDes }) => {
  const dialogRef = useRef();
  const { t } = useTranslation("");

  // Close the dialog when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose(); // Call onClose if the click is outside the dialog
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null; // Don't render if the dialog is not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        ref={dialogRef} // Attach the ref to the dialog
        className="bg-white flex flex-col w-[95%] lg:w-1/3 items-start justify-center p-4 rounded shadow-lg"
      >
        {isShowDes ? (
          <div>
            <h2 className="text-lg font-PlusJakartaSansSemiBold">
              Description
            </h2>
          </div>
        ) : (
          <div></div>
        )}

        <p className="mt-2 font-PlusJakartaSansRegular text-base">{message}</p>

        <div className="w-full justify-center items-center flex">
          <button
            onClick={onClose}
            className="mt-4 px-10 py-2 bg-mButtonColor font-PlusJakartaSansRegular text-base text-white rounded"
          >
            {t("ok")}
          </button>
        </div>
      </div>
    </div>
  );
};
