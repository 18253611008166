import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import {
  Color,
  isEmptyObject,
  isValidScore,
  mTimerValue,
  ProgressBar,
} from "../../Utils/Constants";
import { mStartupdashboard, mUploadDeckDashboard } from "../../Services/allAPI";
import { toast } from "react-toastify";

import DashboardDialog from "./DashboardDialog";
import { useSelector } from "react-redux";
import HeaderMenu from "../../Components/HeaderMenu";
import DashboardTitle from "../../Components/DashboardTitle";
import GaugeChart from "react-gauge-chart";
import PdfUploader from "../../Components/PdfUploader";
import SecondaryButton from "../../Components/SecondaryButton";
import { ReactComponent as ChatIcon } from "../../assets/Images/ic_chat.svg";
import { toPng } from "html-to-image"; // Correctly import toPng

const InvestorDashboard = () => {
  const { t } = useTranslation("");

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [servicesdate, setServicesData] = useState([]);
  const [percentage, setpercentage] = useState(0);
  const [base64, setBase64] = useState("");
  const [FileName, setFileName] = useState("");
  const [summarydetails, setSummaryDetails] = useState({});
  const [deckdetails, setDeckdetails] = useState({});
  // Set Progressbar
  const [progress, setProgress] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const user = useSelector((state) => state.userInfo.user);

  // alert("Get User :" + user);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const isClickDisbled = isRunning && progress !== 100;
  // const isClickDisbled = true;

  //
  useEffect(() => {
    DashboardAPICall();
  }, []);

  // Dashboard API call
  const DashboardAPICall = async () => {
    setLoading(true);
    const response = await mStartupdashboard({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        if (response?.data.message.user_last_deck.length !== 0) {
          setIsRunning(false);

          // setpercentage(
          //   overall_rating.includes(":")
          //     ? overall_rating.split(":")[1].includes("/")
          //       ? overall_rating.split(":")[1].split("/")[0] / 10
          //       : overall_rating.split(":")[1] / 10
          //     : overall_rating
          // );

          setpercentage(
            isValidScore(
              response?.data.message.user_last_deck.summary_details
                .overall_rating
            )
          );
          setDeckdetails(response?.data.message.user_last_deck.deck_details);
          setSummaryDetails(
            response?.data.message.user_last_deck.summary_details
          );
        }

        setServicesData(response?.data.message.our_services);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const handleItemClick = (mDeck_Id) => {
    navigate("/deckdetails", {
      state: {
        mDeck_Id: mDeck_Id,
      },
    });
  };

  const Opendialog = () => {
    // Handle the cancel action

    setShowDialog(true);
  };

  const handleCancel = () => {
    // Handle the cancel action
    setShowDialog(false);
  };

  const handleDeckSucess = () => {
    // Handle the cancel action

    setShowDialog(false);
    DashboardAPICall();
  };

  const handleFileSelect1 = async (file) => {
    //  setSelectedFile(file);
    console.log(file);

    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const base64String = await convertToBase64(file);
    setBase64(base64String);

    console.log("Base64 String:", base64String);

    var mCropImage = "";

    if (base64String.length === 0) {
      mCropImage = base64String.replace("data:application/pdf;base64,", "");
    } else {
      mCropImage = base64String.replace("data:application/pdf;base64,", "");
    }

    // console.log(mCropImage);
    setFileName(file.name);
    setLoading(false);
    const response = await mUploadDeckDashboard({
      deck_name: file.name,
      deck_doc: mCropImage,
      user_name: UserInfoData.user_email,
    });
    console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        //setdeckId(response?.data.message.deck_id);
        setIsRunning(true);
        // navigate("/signup", {
        //   state: {
        //     mdeckId: response?.data.message.deck_id,
        //     mdeckName: file.name,
        //   },
        // });
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  //Function to convert file to Base64
  const convertToBase64 = (file) => {
    console.log(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const ClickChat = () => {
    if (!isClickDisbled) {
      navigate("/chat");
    }
  };

  const proceedClick = () => {};

  // Deck upload to server start

  // One min interval
  useEffect(() => {
    if (!isRunning) return;

    const intervalTime = mTimerValue / 100; // Update every 0.6 seconds (1% of 60 seconds)

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          DashboardAPICall();
          console.log("Interval : " + prevProgress);
          return 100;
        }
        return prevProgress + 1;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isRunning]);

  // Time progress running or not

  // Deck upload to server end

  // const divRef = useRef();

  // // Function to convert div to PNG and share
  // const handleShare = async () => {
  //   try {
  //     const dataUrl = await toPng(divRef.current); // Use toPng here
  //     const blob = await (await fetch(dataUrl)).blob();

  //     // Check if Web Share API is available
  //     if (navigator.share) {
  //       const file = new File([blob], "image.png", { type: blob.type });
  //       navigator
  //         .share({
  //           title: "Check out this image!",
  //           files: [file],
  //         })
  //         .then(() => console.log("Shared successfully"))
  //         .catch((error) => console.error("Error sharing:", error));
  //     } else {
  //       // Fallback: Download the image locally
  //       // saveAs(blob, "image.png");
  //       alert(
  //         "Web Share API is not supported in this browser. Image downloaded instead."
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error generating image:", error);
  //   }
  // };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex h-screen overflow-hidden bg-mDashboardBg  bg-no-repeat bg-cover">
            {/* Sidebar */}
            <SideMenu
              mFrom={0}
              OpenDialog={Opendialog}
              mClickstatus={isClickDisbled}
            />

            {/* Main content */}
            <div className="flex flex-col flex-grow overflow-hidden ">
              {/* Top Navigation */}

              <HeaderMenu
                title={t("Dashboard")}
                mClickstatus={isClickDisbled}
                isLoadNotification={true}
              />

              {/* Scrollable content */}
              <main className="flex-grow overflow-auto  mt-1 mb-3 scrollbar-hide p-4">
                {/* Adjusted padding top to avoid overlap */}

                {isEmptyObject(summarydetails) ? (
                  <div>
                    <div className="w-full h-auto mt-5 flex flex-row">
                      <div className="w-1/2 ">
                        <div className="">
                          <DashboardTitle label={t("UploadPitchDeck")} />
                        </div>
                        <div className="mt-9">
                          <PdfUploader
                            onFileSelect={handleFileSelect1}
                            hidelogin={0}
                            isBorder={false}
                          />
                        </div>
                      </div>
                      <div className="w-1/2 ml-5 mr-3">
                        {isRunning ? (
                          <div className="mt-10">
                            <ProgressBar progress={progress} />
                            <p className="font-PlusJakartaSansRegular text-mTextColor font-normal text-sm mt-2">
                              {FileName}
                            </p>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full h-auto mt-5 flex flex-col lg:flex-row">
                    <div className="w-full  lg:w-1/2 ">
                      <div className="">
                        <DashboardTitle label={t("UploadPitchDeck")} />
                      </div>
                      <div className="mt-9">
                        <PdfUploader
                          onFileSelect={handleFileSelect1}
                          hidelogin={0}
                          isBorder={false}
                        />
                      </div>
                    </div>
                    {isRunning ? (
                      <div className="w-full  lg:w-1/2 lg:ml-5 mr-3">
                        {isRunning ? (
                          <div className="mt-10">
                            <ProgressBar progress={progress} />
                            <p className="font-PlusJakartaSansRegular text-mTextColor font-normal text-sm mt-2">
                              {FileName}
                            </p>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    ) : (
                      <div className="w-full mt-5 lg:mt-0 lg:w-1/2 lg:ml-5  mr-3 ">
                        <div>
                          <div className="w-full justify-between flex flex-row">
                            <div>
                              <p className="font-PlusJakartaSansSemiBold font-normal text-mTextColor text-xs md:text-sm lg:text-base xl:text-lg 3xl:text-2xl">
                                {deckdetails.name}
                              </p>
                            </div>
                            <div
                              className="cursor-pointer "
                              onClick={() => handleItemClick(deckdetails.id)}
                            >
                              <p className="font-PlusJakartaSansSemiBold font-normal text-mButtonColor text-xs md:text-sm lg:text-base xl:text-lg 3xl:text-2xl">
                                {t("ViewSummary")}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mt-5 3xl:mt-20">
                          <div className="w-full h-36 mt-7 flex-row justify-center items-center py-5 flex ">
                            <div
                              className=" w-full md:w-1/2 lg:w-3/5 h-full justify-center items-center lg:justify-start lg:items-start flex gauge-container"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <GaugeChart
                                id="gauge-chart-custom"
                                nrOfLevels={1} // Sets the number of arc levels
                                arcsLength={[
                                  percentage / 10,
                                  1 - percentage / 10,
                                ]}
                                colors={[Color.mButtonColor, Color.trackColor]} // Sets color for the arc
                                arcWidth={0.25} // Sets arc width, reduce this for thinner arcs
                                percent={percentage / 10} // Sets the percentage the gauge is filled
                                needleColor={Color.mArcColor} // Sets the needle color
                                needleBaseColor={Color.needleColor} // Sets the needle base color
                                cornerRadius={0} // Removes edge radius
                                arcPadding={0}
                                hideText={true} // Removes padding between arcs
                              />
                            </div>
                            <div className=" hidden lg:block lg:w-2/5 h-full items-center justify-center  flex-col ">
                              <p className="font-PlusJakartaSansSemiBold text-6xl items-center justify-center flex">
                                {isValidScore(summarydetails.overall_rating)}
                                {/* {
                                  summarydetails.overall_rating
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="ml-2 w-full font-PlusJakartaSansRegular items-center justify-center flex font-normal text-xs md:text-sm lg:text-base xl:text-lg 3xl:text-2xl mt-3">
                                {t("OverallScore")}
                              </p>
                            </div>
                          </div>

                          <div className="block lg:hidden h-full items-start justify-center flex-col border border-mBorderColor rounded-lg ml-3 p-4 bg-white">
                            <p className="font-PlusJakartaSansSemiBold text-3xl  text-mTextColor">
                              {isValidScore(summarydetails.overall_rating)}
                              {/* {
                                  summarydetails.overall_rating
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                            </p>
                            <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base xl:text-base 3xl:text-2xl mt-3">
                              {t("OverallScore")}
                            </p>
                          </div>

                          <div className="w-full h-28 mt-5 lg:mt-10 3xl:mt-20 flex-row flex ">
                            <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                              <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                {isValidScore(summarydetails.team_exp)}
                                {/* {
                                  summarydetails.team_exp
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base xl:text-base 3xl:text-2xl mt-3">
                                {t("Teamexperience")}
                              </p>
                            </div>
                            <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                              <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                {isValidScore(summarydetails.market_potential)}
                                {/* {
                                  summarydetails.market_potential
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base xl:text-base 3xl:text-2xl mt-3">
                                {t("Marketpotential")}
                              </p>
                            </div>
                            <div className=" hidden lg:block flex-1 h-full items-start justify-center  flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                              <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                                {isValidScore(summarydetails.flow_and_clarity)}
                                {/* {
                                  summarydetails.flow_and_clarity
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base xl:text-base 3xl:text-2xl mt-3">
                                {t("Flowandclarity")}
                              </p>
                            </div>
                          </div>
                          <div className=" block lg:hidden mt-4 flex-1 h-full items-start justify-center  flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                            <p className="font-PlusJakartaSansSemiBold text-3xl text-mTextColor">
                              {isValidScore(summarydetails.flow_and_clarity)}
                              {/* {
                                  summarydetails.flow_and_clarity
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                            </p>
                            <p className="font-PlusJakartaSansRegular text-mTextColor text-xs md:text-sm lg:text-base xl:text-base 3xl:text-2xl mt-3">
                              {t("Flowandclarity")}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="absolute items-center justify-center flex hidden">
                  <button
                    className={`floating-button w-[50px] h-[50px] 3xl:w-[70px] 3xl:h-[70px] ${
                      isClickDisbled ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={ClickChat}
                  >
                    <ChatIcon className="w-6 h-6 3xl:w-8 3xl:h-8 flex-shrink-0 " />
                    {/* You can replace this with any icon */}
                  </button>
                </div>
              </main>
            </div>
          </div>

          {showDialog ? (
            <DashboardDialog
              show={showDialog}
              onClose={handleCancel}
              onDeckSucess={handleDeckSucess}
              Userid={UserInfoData.user_id}
            />
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
    // <div className="p-6">
    //   <div
    //     className="bg-gray-200 p-10 text-center rounded-lg shadow-md"
    //     ref={divRef}
    //   >
    //     <h1 className="text-3xl font-bold mb-4">Shareable Banner</h1>
    //     <p className="text-lg text-gray-700">
    //       This content will be shared as an image!
    //     </p>
    //   </div>

    //   <button
    //     className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-lg"
    //     onClick={handleShare}
    //   >
    //     Share as Image
    //   </button>
    // </div>
  );
};

export default InvestorDashboard;
