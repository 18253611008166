import { BASE_URL } from "./baseURL";
import { commonAPI } from "./commonApi";

export const mUploadDeck = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_upload.upload_deck_pdf`,
    user
  );
};

// Login
export const mLogin = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.login.user_login`,
    user
  );
};

// Register
export const mRegister = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_upload.deck_register`,
    user
  );
};

// Dashboard
export const mGetDashboard = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_upload.get_dashboard_data`,
    user
  );
};

// Dashboard
export const mGetslideimages = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_upload.get_slide_images`,
    user
  );
};

// User Feedback
export const mUserFeedback = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_upload.update_user_feedback`,
    user
  );
};

// upload deck in Dashboard
export const mUploadDeckDashboard = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_upload.upload_deck_in_dashboard`,
    user
  );
};

// get pdf and download count
export const mGetpdfanddownloadcount = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_upload.get_pdf_and_download_count`,
    user
  );
};

// get pdf and download count
export const mGetMaster = async (user) => {
  console.log(user);
  return await commonAPI(
    "GET",
    `${BASE_URL}/api/method/deck_review.custom_api.masters.get_user_type_as_masters`
  );
};

// Google Login
export const mGooglelogin = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.goggle_signup.create_google_signup`,
    user
  );
};

// Sign up
export const mSignup = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.sign_up.deck_sign_up`,
    user
  );
};

// Send otp to mail
export const mSendotptomail = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.reset_password.send_otp_to_mail`,
    user
  );
};

// Reset password
export const mResetpassword = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.reset_password.reset_password`,
    user
  );
};

// Startup dashboard
export const mStartupdashboard = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.dashboard.get_lastest_deck`,
    user
  );
};

// Service
export const mServices = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.services.get_our_services`,
    user
  );
};

// Create Service
export const mCreateServices = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.services.create_my_services`,
    user
  );
};

// Startup dashboard
export const mInvestordb = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.startup_crm.get_investor_db`,
    user
  );
};

// Get profile details
export const mGetprofile = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.profile.get_profile_information`,
    user
  );
};

//  Edit profile personal details
export const mEditpersonaldetails = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.profile.profile_edit`,
    user
  );
};

//  Edit profile about
export const mEditAboutprofile = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.profile.profile_edit_about`,
    user
  );
};

//  Edit profile company details
export const mEditcompanydetails = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.profile.edit_company_inforamtion`,
    user
  );
};

//  Edit profile about
export const mEditAboutcompany = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.profile.edit_about_company`,
    user
  );
};

// //  Edit profile about
// export const mgetCRMBoard = async (user) => {
//   console.log(user);
//   return await commonAPI(
//     "POST",
//     `${BASE_URL}/api/method/deck_review.custom_api.startup_crm.get_crm_board`,
//     user
//   );
// };

//  get Investor db
export const mgetInvestordb = async (user) => {
  console.log(user);
  return await commonAPI(
    "GET",
    `${BASE_URL}/api/method/deck_review.custom_api.investor_crm.get_recommended_investors`
  );
};

//   Investor Shortlist db
export const mInvestorShortlist = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.startup_crm.make_investor_to_crm`,
    user
  );
};

//   CRM Board
export const mCRMBoard = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.startup_crm.get_investors_board`,
    user
  );
};

//   Move CRM Board
export const mMoveCRMBoard = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.startup_crm.move_crm_status`,
    user
  );
};

//   Move CRM Board
export const mEditCRM = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.startup_crm.edit_crm`,
    user
  );
};

//   Delete CRM Board
export const mDeleteCRM = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.startup_crm.delete_crm`,
    user
  );
};

//   Delete CRM Board
export const mGetallMaster = async (user) => {
  console.log(user);
  return await commonAPI(
    "GET",
    `${BASE_URL}/api/method/deck_review.custom_api.masters.get_masters_data`
  );
};

//   FAQ Suppport
export const FAQSuppport = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.fsq.get_fsq_suppport`,
    user
  );
};

//   get Vault List
export const getVaultList = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.get_vault_list`,
    user
  );
};

//   Create Vault
export const createVault = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.create_new_valut`,
    user
  );
};

//   Delete Vault
export const deleteVault = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.delete_vault`,
    user
  );
};

//   Get Vault
export const getVault = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.get_per_vault_details`,
    user
  );
};

// Update valut
export const updateVault = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.update_vault`,
    user
  );
};

// Update new file
export const updatenewFile = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.create_new_file`,
    user
  );
};

// delete file
export const deleteFile = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.delete_file`,
    user
  );
};

// invite people
export const invitePeople = async (user) => {
  console.log(user);
  return await commonAPI(
    "GET",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.vault_invite_people`
  );
};

// share file
export const shareFile = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.share_file`,
    user
  );
};

// share vault
export const shareVault = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.vault.share_vault`,
    user
  );
};

// Subscription plan
export const getSubscriptionplan = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.subscription_plan.subscription_plan_details`,
    user
  );
};

// Subscription plan
export const buySubscription = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.subscription_plan.create_product_payment`,
    user
  );
};

// Password change
export const passwordchange = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.profile.change_user_password`,
    user
  );
};

// Create support ticket
export const createsupportticket = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.support.create_support_ticket`,
    user
  );
};

// Create support ticket
export const getSupportTicket = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.support.get_support_ticket`,
    user
  );
};

// get Deck score
export const getDeckscore = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_score.get_deck_score`,
    user
  );
};

// get Deck score
export const setProfileImage = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.profile.set_profile_image`,
    user
  );
};

//get paymentdetails
export const getpaymentdetails = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.subscription_plan.get_payment_details`,
    user
  );
};

// mNotification Details
export const mNotificationDetails = async (user) => {
  console.log(user);
  return await commonAPI(
    "GET",
    `${BASE_URL}/api/method/deck_review.custom_api.product_notification.get_notification_details`
  );
};

//get user notifications
export const getusernotifications = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.product_notification.get_user_notifications`,
    user
  );
};

//get user notifications
export const paymentordercreate = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.payment_order.create_order`,
    user
  );
};
//Get Deals
export const mGetDeals = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.investor_crm.get_recommended_investors`,
    user
  );
};

//Get Deals Shortlist
export const mGetDealsShortlist = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.investor_crm.crm_status_move`,
    user
  );
};

//   CRM List
export const mCRMList = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.investor_crm.get_investor_crm_status_wise`,
    user
  );
};

//   CRM delete
export const mCRMDelete = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.investor_crm.crm_status_move`,
    user
  );
};

//   CRM Status Change
export const mCRMStatus = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.investor_crm.crm_status_move`,
    user
  );
};

// get Deck score
export const getscorecard = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.score_card.process_score_card_image`,
    user
  );
};

// get deck score meter
export const getscoremeter = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_upload.get_deck_score_meter`,
    user
  );
};

// get Deck score
export const getPdfURL = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.deck_upload.get_deck_pdf`,
    user
  );
};

// get Chat List
export const getChatlist = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.chat_conversation.get_chat_list`,
    user
  );
};

export const getchatconversation = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.chat_conversation.process_chat_conversation`,
    user
  );
};

export const getchatconversationlist = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.chat_conversation.get_chat_conversation_list`,
    user
  );
};

export const userviewstatus = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.profile.update_user_view_status`,
    user
  );
};

export const notificationDelete = async (user) => {
  console.log(user);
  return await commonAPI(
    "POST",
    `${BASE_URL}/api/method/deck_review.custom_api.product_notification.del_user_notification`,
    user
  );
};
