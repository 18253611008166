import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Utils/Loader";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../assets/Images/ic_back.svg";
import { ReactComponent as EmailIcon } from "../../assets/Images/ic_email.svg";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { Images, isValidEmail } from "../../Utils/Constants";
import { toast } from "react-toastify";
import { mGetMaster, mGooglelogin, mLogin } from "../../Services/allAPI";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/slice/userSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { ReactComponent as Icondrop } from "../../assets/Images/ic_dropdown.svg";
import Headingtext from "../../Components/Headingtext";
import TextView from "../../Components/TextView";
import {
  fetchDashboardData,
  fetchMasterData,
} from "../../redux/slice/masterSlice";
import LogoText from "../../Components/LogoText";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("");
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isChecked, setisChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Get Deck id

  const location = useLocation();
  const mGetdeckId = location.state?.mdeckId || "";
  const mGetdeckName = location.state?.mdeckName || "";

  const PasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (event) => {
    setisChecked(event.target.checked);
  };

  const handlefwd = () => {
    navigate("/resetpassword");
  };

  const loginClick = () => {
    if (!isValidEmail(email)) {
      toast.error(t("vaildmail"));
      return;
    }
    LoginCall();
  };

  // Login API Call
  const LoginCall = async () => {
    setLoading(true);
    const response = await mLogin({
      email: email,
      password: password,
      deck_id: mGetdeckId,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        // if (isChecked) {
        dispatch(setUser({ user: response?.data.message.userinfo }));
        localStorage.setItem(
          "UserInfo",
          JSON.stringify(response?.data.message.userinfo)
        );
        const savedData = localStorage.getItem("UserInfo");
        // console.log(savedData);
        // }

        navigate("/dashboard");
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const ClickCreateAccount = () => {
    navigate("/signup");
  };

  // Check Disable button
  const isDisabled = !email || !password;

  // Google login start

  const [userImageBase64, setUserImageBase64] = useState(null);

  const [userDetails, setUserDetails] = useState([]);
  const [profile, setProfile] = useState([]);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select User Type");

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUserDetails(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const gmailLoginClick = () => {
    if (selectedOption === "Select User Type") {
      toast.error("Please Select User Type");
    } else {
      googleLogin();
      console.log("sghdkhg");
    }
  };

  // console.log("Google Login : " + JSON.stringify(profile));
  useEffect(() => {
    //  MasterCall();
    if (userDetails.length !== 0) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userDetails.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${userDetails.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          convertToBase64(userDetails.picture);
          setProfileLoaded(true);
          if (profile.length !== 0) {
            //
            //   SignupCall();
          }

          //SignupCall();
        })
        .catch((err) => console.log(err));
    }
  }, [userDetails]);

  useEffect(() => {
    if (profileLoaded) {
      // Convert image to base64 and then trigger the signup call
      convertToBase64(profile.picture)
        .then(() => {
          if (profile.name) {
            SignupCall(); // Call Signup API
          }
        })
        .catch((err) => console.log(err));
    }
  }, [profileLoaded, profile]);

  // Google login end

  //Convert Google user image to base64
  const convertToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        setUserImageBase64(reader.result); // This will be the base64 string
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error converting image to base64:", error);
    }
  };

  // Signup API Call
  const SignupCall = async () => {
    setLoading(true);
    //  console.log("Error" + profile.name);
    const response = await mGooglelogin({
      user_name: profile.name,
      email: profile.email,
      user_type: selectedOption,
      deck_id: "",
      profile_image: "",
      file_name: "",
      file_type: "",
      deal_name: "",
      coupon_code: "",
      company_name: "",
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        dispatch(setUser({ user: response?.data.message.user_info }));
        localStorage.setItem(
          "UserInfo",
          JSON.stringify(response?.data.message.userinfo)
        );
        const savedData = localStorage.getItem("UserInfo");
        console.log(savedData);
        //alert(JSON.stringify(savedData));

        // console.log(savedData);
        navigate("/dashboard");
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  // Dropdown Start

  const [isOpen, setIsOpen] = useState(false);
  //const [userType, setuserType] = useState([]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };
  //Dropdown end

  // window.addEventListener("popstate", () => {
  //   alert("sdsdhg");
  // });

  // Get Master data start

  useEffect(() => {
    dispatch(fetchMasterData());
  }, [dispatch]);

  const masterdataList = useSelector((state) => state.master.MasterdataList);
  let status = useSelector((state) => state.master.status);
  const error = useSelector((state) => state.master.error);

  useEffect(() => {
    if (masterdataList?.customer_group?.length > 0) {
      setSelectedOption(masterdataList.customer_group[0].name);
    }
  }, [masterdataList]);

  console.log(status);

  if (status === "loading" || status === "idle") {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  // Get master data end

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="min-h-screen overflow-y-auto bg-red-400 flex flex-row">
          <div className=" hidden lg:block w-3/5 bg-mwhite  flex-col items-start justify-start p-5">
            <div className="mt-3 ml-3">
              {/* <img
                src={Images.zenpivot}
                alt="google Icon"
                className=" h-9 w-28"
              /> */}

              <LogoText label={t("appname")} />
            </div>

            <div className="w-full h-auto justify-center items-center flex">
              <img src={Images.loginbg} alt="login" className="w-2/3 " />
            </div>
          </div>
          <div className=" lg:w-2/5 w-full pl-5 py-5 lg:pl-20 lg:py-10 bg-mBackground items-center justify-start lg:justify-center flex ">
            <div className="grid gap-7 grid-cols-1 w-11/12">
              <div>
                <Headingtext label={t("LogIn")} />
              </div>

              <div
                className="h-14 w-full border border-mBorderColor hidden rounded-lg justify-center items-center flex cursor-pointer"
                onClick={gmailLoginClick}
              >
                <img
                  src={Images.google}
                  alt="google Icon"
                  className=" w-6 h-6  cursor-pointer"
                />
                <p className="font-PlusJakartaSansRegular font-normal text-base 3xl:text-lg ml-2">
                  {" "}
                  {t("Google")}
                </p>
              </div>

              <div className="hidden flex items-center before:flex-1 before:border-t before:border-mBorderColor after:flex-1 after:border-t after:border-mBorderColor">
                <p className="mx-2 mb-0 text-center font-PlusJakartaSansRegular text-sm   text-mTextColorLogin">
                  {t("or")}
                </p>
              </div>

              <div>
                <div className=" ">
                  <TextView label={t("Joinas")} />

                  <div className="border-mBorderColor h-12 3xl:h-14 rounded-lg p-3 w-full border bg-white mt-3 flex-row justify-between flex">
                    <div className=" items-center justify-center flex  w-full">
                      <div className="relative  w-full">
                        <button
                          className="custom-dropdown-toggle"
                          onClick={toggleDropdown}
                        >
                          <p
                            className={`font-PlusJakartaSansRegular font-normal text-mTextColorLogin text-sm 3xl:text-base`}
                          >
                            {selectedOption}
                          </p>
                        </button>

                        {/* Dropdown menu */}
                        <ul
                          className={`custom-dropdown-menu  ${
                            isOpen ? "show" : ""
                          }`}
                        >
                          {masterdataList.customer_group.map((option) => (
                            <li
                              key={option.name}
                              className={`custom-dropdown-option ${
                                selectedOption === option.name ? "selected" : ""
                              }`}
                              onClick={() => handleOptionClick(option.name)}
                            >
                              <p
                                className={`font-PlusJakartaSansRegular font-normal text-mTextColorLogin text-sm 3xl:text-base ${
                                  selectedOption === option.name
                                    ? "text-white"
                                    : "text-mTextColorLogin"
                                }`}
                              >
                                {option.name}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <Icondrop className="w-6 h-6 flex-shrink-0" />
                  </div>
                </div>

                <div>
                  <div className="mt-4">
                    <TextView label={t("EMail")} ismandatory={true} />
                  </div>

                  <InputContainer
                    type="email"
                    value={email}
                    placeholder={t("Enteryouremail")}
                    label="email"
                    name="email"
                    disabled={false}
                    maxLength={60}
                    showIcon={true}
                    Icon={EmailIcon}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      // setforgetPwd({ ...forgetPwd, email: event.target.value });
                    }}
                  />
                </div>

                <div>
                  <div className="mt-4">
                    <TextView label={t("Password")} ismandatory={true} />
                  </div>
                  <InputContainer
                    type={showPassword ? "text" : "password"}
                    value={password}
                    placeholder={t("EnterPassword")}
                    label="password"
                    name="password"
                    disabled={false}
                    maxLength={60}
                    secureTextEntry={true}
                    PasswordVisibility={PasswordVisibility}
                    setshowPassword={showPassword}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      // setLogin({ ...login, password: e.target.value });
                    }}
                  />
                </div>

                <div className="w-full flex flex-row  items-center mt-8">
                  <div className="w-1/2">
                    <div className="flex flex-col">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="privacyTermsCheckbox"
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          className="form-checkbox h-4 w-4 "
                        />
                        <label
                          htmlFor="privacyTermsCheckbox"
                          className="ml-2 text-sm 3xl:text-base text-mTextColorLogin font-PlusJakartaSansRegular"
                        >
                          {t("RememberMe")}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="w-1/2">
                    <p
                      className="text-right font-PlusJakartaSansSemiBold text-sm 3xl:text-base text-mButtonColor cursor-pointer"
                      onClick={handlefwd}
                    >
                      Forget Password?
                    </p>
                  </div>
                </div>

                <div className="mt-4">
                  <PrimaryButton
                    label={t("login")}
                    onClick={loginClick}
                    onDisable={isDisabled}
                  ></PrimaryButton>
                </div>
                <div className="flex-row flex justify-center mt-4">
                  <p className="text-center font-PlusJakartaSansRegular text-sm 3xl:text-base text-mTextColorLogin">
                    {t("noaccount")}
                  </p>
                  <button
                    className="text-center font-PlusJakartaSansSemiBold text-sm 3xl:text-base text-mButtonColor ml-2"
                    onClick={ClickCreateAccount}
                  >
                    {t("Signup")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Login;
