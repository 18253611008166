import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import { mInvestordb } from "../../Services/allAPI";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import HeaderMenu from "../../Components/HeaderMenu";
import Deals from "./Deals";
import CRMList from "./CRMList";

const InvestorCRM = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [boardView, setBoardView] = useState(true);
  const [values, setValues] = useState([1, 10]);
  const [isChecked, setisChecked] = useState(false);
  const [investordb, setinvestordb] = useState([]);

  const user = useSelector((state) => state.userInfo.user);

  console.log("Get User :" + user);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  //
  useEffect(() => {}, []);

  const onBoardClick = () => {
    // Handle the cancel action
    setBoardView(false);
  };
  const onInvestorsClick = () => {
    // Handle the cancel action
    setBoardView(true);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex h-screen overflow-hidden bg-mDashboardBg  bg-no-repeat bg-cover">
            {/* Sidebar */}
            <SideMenu mFrom={2} mProfile={-1} />

            {/* Main content */}
            <div className="flex flex-col flex-grow overflow-hidden ">
              {/* Top Navigation */}

              <HeaderMenu title={t("InvestorsCRM")} />

              <div className="p-4 mt-4 flex">
                <div
                  className={`${
                    boardView ? "bg-mButtonColorligth" : "bg-white"
                  } rounded-md px-6 py-3 inline-block max-w-xs  cursor-pointer`}
                  onClick={() => onInvestorsClick()}
                >
                  <p
                    className={`font-PlusJakartaSansRegular ${
                      boardView ? "text-mButtonColor" : "text-mTextColorLogin"
                    }  text-base `}
                  >
                    {t("Deals")}
                  </p>
                </div>

                <div
                  className={` ${
                    boardView ? "bg-white" : "bg-mButtonColorligth"
                  } rounded-md px-10 py-3 inline-block max-w-xs cursor-pointer  ml-5 lg:ml-10 mr-2`}
                  onClick={() => onBoardClick()}
                >
                  <p
                    className={`font-PlusJakartaSansRegular ${
                      boardView ? "text-mTextColorLogin" : "text-mButtonColor"
                    }  text-base `}
                  >
                    {t("crm")}
                  </p>
                </div>
              </div>

              {/* Scrollable content */}
              <main className="flex-grow overflow-auto  mt-1 mb-3  px-4 ">
                {/* Adjusted padding top to avoid overlap */}
                {boardView ? <Deals /> : <CRMList />}
              </main>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InvestorCRM;
