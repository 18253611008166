import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../Utils/Loader";
import {
  getPdfURL,
  getscoremeter,
  mGetpdfanddownloadcount,
  mGetslideimages,
  mUserFeedback,
} from "../../Services/allAPI";
import { toast } from "react-toastify";
import PrimaryButton from "../../Components/PrimaryButton";
import {
  Color,
  Images,
  isEmptyObject,
  isValidScore,
} from "../../Utils/Constants";
import { ReactComponent as ChatIcon } from "../../assets/Images/ic_chat.svg";
import { toPng } from "html-to-image"; // Correctly import toPng

import GaugeChart from "react-gauge-chart";
import HeaderMenu from "../../Components/HeaderMenu";
import { AlertDialog } from "../../Components/AlertDialog";

const Deckdetails = () => {
  const { t } = useTranslation("");
  const navigate = useNavigate();
  const maxChars = 300;
  const [loading, setLoading] = useState(false);
  const [getSlideDetails, setGetSlideDetails] = useState([]);
  const [Postion, setPostion] = useState(0);
  const [summarydetails, setSummaryDetails] = useState({});
  const [deckreview, setdeckreview] = useState({});
  const [percentage, setpercentage] = useState(0);
  const [deckpdfurl, setDeckpdfurl] = useState("");
  const [likeselect, setLikeselect] = useState(false);
  const [dislikeselect, setDislikeClick] = useState(false);
  const [editOpen, seteditOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [animate, setAnimate] = useState(true);
  const [showDialog, setShowDialog] = useState(false);
  const [getllmResponse, setGetllmResponse] = useState("");

  const scoremeterRef = useRef(null);

  var mSubmitClick = false;

  const location = useLocation();
  const mDeck_Id = location.state?.mDeck_Id || "Deck-Review-0317";

  useEffect(() => {
    downloadPDF();
    getParticularDeck();
  }, []);

  const validateDeckReview = (deckReview) => {
    if (deckReview === null || deckReview === undefined) {
      // alert("aa");
      return false;
    } else if (typeof deckReview === "string") {
      //alert("bb");
      return false;
    } else if (
      typeof deckReview === "object" &&
      !Array.isArray(deckReview) &&
      !isEmptyObject(deckReview)
    ) {
      //alert("cc");
      return true;
    } else {
      return false;
    }
  };

  //Get Particular Deck API call
  const getParticularDeck = async () => {
    setLoading(true);
    const response = await mGetslideimages({
      doc_name: mDeck_Id,
      // doc_name: "Deck-Review-0333",
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        if (validateDeckReview(response?.data.message.deck_review)) {
          setGetSlideDetails(response?.data.message.deck_details);

          setGetllmResponse(response?.data.message.llm_response);
          setdeckreview(response?.data.message.deck_review);
          setSummaryDetails(response?.data.message.deck_review.summary);

          setAnimate(true);
          // setpercentage(
          //   response?.data.message.summary_details[0].overall_rating
          //     .split(":")[1]
          //     .split("/")[0] / 10
          // );

          setpercentage(
            isValidScore(
              response?.data.message.deck_review.summary.overall_rating
            )
          );
        } else {
          toast.error("Details not found");
        }
        //console.log("API Response -> " + serviceDetails.process_steps[0].steps);
      } else {
        toast.error(response?.data.message.status);
        console.log(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const handleItemClick = (mDeck_Id, mPostion) => {
    setAnimate(false);
    setPostion(mPostion);
    setDislikeClick(false);
    setLikeselect(false);
    setInputValue("");
  };

  const likeClick = () => {
    seteditOpen(true);
    setLikeselect(true);
    setDislikeClick(false);
    mSubmitClick = true;
    Submit();
  };

  const dislikeClick = () => {
    seteditOpen(true);
    setDislikeClick(true);
    setLikeselect(false);
    mSubmitClick = true;
    Submit();
  };

  const Submit = async () => {
    const deckdetailsdata = {
      title: getSlideDetails[Postion].slide_no,
      user_feedback: inputValue,
      like: likeselect ? 1 : 0,
      dislike: dislikeselect ? 1 : 0,
      user_feedback_status: 1,
    };

    setLoading(true);
    const response = await mUserFeedback({
      deck_id: mDeck_Id,
      user_feedback: inputValue,
      like: likeselect ? 1 : 0,
      dislike: dislikeselect ? 1 : 0,
      user_feedback_status: 1,
      //deck_risk_table: JSON.stringify([deckdetailsdata]),
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);

        if (mSubmitClick) {
          mSubmitClick = false;
        } else {
          setDislikeClick(false);
          setLikeselect(false);
          setInputValue("");
        }

        //console.log("API Response -> " + serviceDetails.process_steps[0].steps);
      } else {
        toast.error(response?.data.message.status);
        console.log(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  // Open PDF File
  const openUrl = () => {
    if (deckpdfurl.length !== 0) {
      window.open(deckpdfurl, "_blank");
      GetpdfdownloadCall(mDeck_Id);
    }
  };

  const downloadPDF = async () => {
    // setLoading(true);
    const response = await getPdfURL({
      deck_id: mDeck_Id,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setDeckpdfurl(response?.data.message.deck_pdf);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const GetpdfdownloadCall = async (Deck_Id) => {
    setLoading(true);
    const response = await mGetpdfanddownloadcount({
      doc_name: Deck_Id,
      pdf_downlad_count: 1,
    });

    console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
      } else {
        toast.error(response?.data.message.message);
        // console.log(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const mBackClick = () => {
    // navigate("/mydecks");
    navigate(-1); // Navigate back to the previous page
  };

  const ClickChat = () => {
    //navigate("/mydecks");
    navigate(-1); // Navigate back to the previous page
  };

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [description, setdescription] = useState("");

  const infoclick = (description) => {
    setAnimate(false);
    setIsAlertOpen(true);
    setdescription(description);
  };

  const closeAlert = () => setIsAlertOpen(false);

  // Send Image to server start

  useEffect(() => {
    if (deckreview) {
      downloadScoreCard();
    }
  }, [deckreview]);

  const downloadScoreCard = async () => {
    if (scoremeterRef.current === null) return;
    toPng(scoremeterRef.current)
      .then((dataUrl) => {
        setAnimate(false);
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "scoremeter-card.png";
        // link.click();
        // alert(link.url);

        uploadScorecardImage(dataUrl);
      })
      .catch((err) => console.error("Failed to generate image", err));
  };

  const uploadScorecardImage = async (file) => {
    const response = await getscoremeter({
      deck_id: mDeck_Id,
      file_name: "score meter",
      file_type: "png",
      file: file.replace("data:image/png;base64,", ""),
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);

      if (response?.data.message.status) {
        const data = response?.data.message.score_card_details;
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }

    // Assuming your server returns the image URL
  };

  // Send Image to server End

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="xl:overflow-hidden overflow-auto h-screen bg-mDashboardBg fixed inset-0">
          <div>
            <HeaderMenu
              title={t("summary")}
              isShowing={true}
              onClick={mBackClick}
              isCallBtn={true}
            />
          </div>
          <div className="hidden xl:block">
            <div className="h-screen flex">
              <div className="w-72 h-full overflow-y-scroll p-4 scrollbar-hide mb-2">
                <div className=" mb-4 ml-3">
                  {getSlideDetails.map((item, i) => (
                    <div key={item.id} className="mb-3 ">
                      <div
                        className={`${
                          Postion === i ? " bg-transparent" : "bg-white"
                        }  ${
                          Postion === i ? " border-mGreyFour" : "border-white"
                        } rounded-lg p-5 border cursor-pointer`}
                        onClick={() => handleItemClick(item.id, i)}
                      >
                        <p className="text-left font-PlusJakartaSansRegular line-clamp-2  font-normal text-mTextColor text-sm 3xl:text-xl">
                          {item.title}
                        </p>

                        <div className="border-mGreyFour rounded-md mt-2 p-2 ">
                          <img
                            alt="name"
                            src={item.slide_image}
                            className=" "
                          />
                        </div>
                      </div>
                      <div className="h-3 "></div>
                    </div>
                  ))}
                  <div className="h-20"></div>
                </div>
              </div>
              <div className="w-full h-full flex">
                <div className="w-3/5 h-full overflow-y-scroll p-4 scrollbar-hide mb-2">
                  <div className="content">
                    {getSlideDetails.length > 0 ? (
                      <div>
                        <div className=" hidden rounded-2xl bg-white border-mBorderColor border justify-center items-center ">
                          <div className="p-1">
                            <img
                              alt="name"
                              src={getSlideDetails[Postion].slide_image}
                              className=" "
                            />
                          </div>
                        </div>

                        <div className=" rounded-2xl bg-white mt-5 p-6">
                          <div className="grid grid-cols-1 gap-7 w-full">
                            {/* <p className="text-left font-PlusJakartaSansRegular text-mTextColorLogin text-sm 3xl:text-lg mt-3">
                              {formatTextWithLineBreaks(getllmResponse)}
                            </p> */}
                            {/* <p>afashl</p> */}

                            {/* {deckreview.map((item) => (
                             
                            ))} */}
                            <div
                              className="bg-white p-4 rounded-sm md:rounded-md lg:rounded-lg grid grid-cols-1 gap-5"
                              key={deckreview.id}
                            >
                              {deckreview.type_of_risk.map((typeofriskitem) => (
                                <div
                                  className="bg-white p-4 rounded-sm md:rounded-md lg:rounded-lg grid grid-cols-1 gap-4"
                                  key={deckreview.id}
                                >
                                  <p className="font-PlusJakartaSansSemiBold text-base text-mButtonColor">
                                    {typeofriskitem.title}
                                    {" ( "}
                                    {typeofriskitem.risk_score}
                                    {" ) "}
                                  </p>

                                  <p className="font-PlusJakartaSansRegular text-base text-mTextColor">
                                    {typeofriskitem.explanation}
                                  </p>

                                  <div className="ml-5">
                                    <p className="font-PlusJakartaSansSemiBold text-base text-mTextColor">
                                      Key factors influencing
                                    </p>

                                    {/* <p className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-1">
                                      {typeofriskitem.key_factors_influencing}
                                    </p> */}
                                    <div className="mt-2">
                                      <ul className="list-disc pl-5 space-y-2">
                                        {typeofriskitem.key_factors_influencing
                                          .split("\n")
                                          .map((line, index) => (
                                            <li
                                              key={index}
                                              className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-1"
                                            >
                                              <p className="font-PlusJakartaSansRegular text-base text-mTextColorLogin mt-1">
                                                {" "}
                                                {line.trim()}{" "}
                                              </p>
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  </div>

                                  <div className="ml-5">
                                    <p className="font-PlusJakartaSansSemiBold text-base text-mTextColor">
                                      Strategies to manage product risk
                                    </p>

                                    {/* <p className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-1">
                                      {
                                        typeofriskitem.strategies_to_manage_product_risk
                                      }
                                    </p> */}
                                    <div className="mt-2">
                                      <ul className="list-disc pl-5 space-y-2">
                                        {typeofriskitem.strategies_to_manage_product_risk
                                          .split("\n")
                                          .map((line, index) => (
                                            <li
                                              key={index}
                                              className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-1"
                                            >
                                              <p className="font-PlusJakartaSansRegular text-base text-mTextColorLogin mt-1">
                                                {" "}
                                                {line.trim()}{" "}
                                              </p>
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <p className="text-left hidden font-PlusJakartaSansSemiBold text-mTextColor text-lg 3xl:text-xl">
                              Slide-by-slide analysis
                            </p>

                            <div>
                              <p className="hidden text-left font-PlusJakartaSansRegular text-mTextColor text-base 3xl:text-xl">
                                {t("feedback")}
                              </p>

                              <p className="text-left font-PlusJakartaSansRegular text-mTextColorLogin text-sm 3xl:text-lg mt-3">
                                {getSlideDetails[Postion].feedback}
                              </p>
                            </div>

                            <div>
                              <p className="hidden text-left font-PlusJakartaSansRegular text-mTextColor text-base 3xl:text-xl">
                                {t("suggestion")}
                              </p>

                              <p className="text-left font-PlusJakartaSansRegular text-mTextColorLogin text-sm 3xl:text-lg mt-3">
                                {getSlideDetails[Postion].suggestion}
                              </p>
                            </div>

                            <div className="">
                              <div className="w-full flex-row flex ">
                                <div className="w-4/5">
                                  <p className="text-left font-PlusJakartaSansRegular text-mTextColor text-base 3xl:text-xl">
                                    {t("helpful")}
                                  </p>
                                </div>
                                <div className="w-1/5 justify-center items-center flex p-1">
                                  <div className="w-1/2 justify-center items-center flex">
                                    <img
                                      onClick={likeClick}
                                      src={
                                        likeselect
                                          ? Images.likeclick
                                          : Images.like
                                      }
                                      alt="Upload Icon"
                                      className=" w-5 h-5 cursor-pointer "
                                    />
                                  </div>
                                  <div className="w-1/2 justify-center items-center flex">
                                    <img
                                      onClick={dislikeClick}
                                      src={
                                        dislikeselect
                                          ? Images.dislikeclick
                                          : Images.dislike
                                      }
                                      alt="Upload Icon"
                                      className=" w-5 h-5  cursor-pointer"
                                    />
                                  </div>
                                </div>
                              </div>

                              {dislikeselect || likeselect ? (
                                <div className="mt-5">
                                  <div className="mt-2 border border-mBorderColor rounded-md bg-mCardbgColor min-h-24">
                                    <textarea
                                      id="message"
                                      rows="5"
                                      value={inputValue}
                                      onChange={(e) =>
                                        setInputValue(e.target.value)
                                      }
                                      maxLength={maxChars}
                                      className="block  w-full border-black h-full text-sm 3xl:text-xl break-words text-mTextColor placeholder-mTextColorLogin px-2 py-3 text-start justify-start items-start  rounded-md   font-PlusJakartaSansRegular border-none focus:border-none  bg-mCardbgColor border-4 !outline-none"
                                      placeholder={
                                        "how can we improve? let us know."
                                      }
                                    ></textarea>
                                  </div>
                                  <div className="justify-end items-end flex ">
                                    <p className="mt-2 text-mTextColorLogin font-PlusJakartaSansRegular  text-xs 3xl:text-xl">
                                      max: {inputValue.length} / {maxChars}
                                    </p>
                                  </div>
                                  <div className="w-full justify-end items-start flex mt-6">
                                    <div className="w-1/3">
                                      <PrimaryButton
                                        label={t("submit")}
                                        onClick={() => {
                                          Submit();
                                        }}
                                      ></PrimaryButton>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div className="h-20"></div>
                  </div>
                </div>
                <div className="w-2/5 h-full overflow-y-scroll py-4 pr-4 scrollbar-hide mb-2">
                  <div className="content">
                    {!isEmptyObject(summarydetails) ? (
                      <div>
                        <div className=" flex flex-col w-full bg-white  rounded-2xl p-6 justify-center items-center">
                          <div className="justify-between flex-row flex w-full ">
                            <div className="flex">
                              <p className="font-PlusJakartaSansSemiBold text-mTextColor text-lg 3xl:text-2xl">
                                {t("summary")}
                              </p>
                              <div
                                className="ml-2 mt-1 cursor-pointer  justify-center items-center flex"
                                onClick={() => infoclick(summarydetails.notes)}
                              >
                                <img
                                  src={Images.infobtn}
                                  alt="Profile"
                                  className="cursor-pointer w-5 h-5"
                                />
                                {/* <SharefileIcon className="w-4 h-4 md:w-6 md:h-6 mt-1 ml-1 flex-shrink-0 " /> */}
                              </div>
                            </div>
                            <div onClick={() => openUrl()}>
                              <p className="font-PlusJakartaSansSemiBold text-mButtonColor text-base 3xl:text-xl cursor-pointer">
                                {t("DownloadReport")}
                              </p>
                            </div>
                          </div>

                          <div className="w-full mt-5 flex-row justify-center items-center py-5 flex ">
                            <div
                              ref={scoremeterRef}
                              className="w-3/5 h-full justify-start flex items-start gauge-container"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <GaugeChart
                                id="gauge-chart-custom"
                                nrOfLevels={1} // Sets the number of arc levels
                                arcsLength={[
                                  percentage / 10,
                                  1 - percentage / 10,
                                ]}
                                colors={[Color.mButtonColor, Color.trackColor]} // Sets color for the arc
                                arcWidth={0.25} // Sets arc width, reduce this for thinner arcs
                                percent={percentage / 10} // Sets the percentage the gauge is filled
                                needleColor={Color.mArcColor} // Sets the needle color
                                needleBaseColor={Color.needleColor} // Sets the needle base color
                                cornerRadius={0} // Removes edge radius
                                arcPadding={0}
                                animate={animate} // Control animation
                                hideText={true} // Removes padding between arcs
                              />
                            </div>
                            <div className="w-2/5 h-full items-center justify-center flex flex-col ">
                              <p className="font-PlusJakartaSansSemiBold text-6xl ">
                                {isValidScore(summarydetails.overall_rating)}
                                {/* {
                                  summarydetails[0].overall_rating
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-xl mt-3">
                                {t("OverallScore")}
                              </p>
                            </div>
                          </div>
                          <div className="w-full h-28 mt-10 flex-row flex ">
                            <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                              <p className="font-PlusJakartaSansSemiBold text-3xl 3xl:text-4xl text-mTextColor">
                                {isValidScore(summarydetails.team)}
                                {/* {
                                  summarydetails[0].team_exp
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="font-PlusJakartaSansRegular text-mTextColor text-sm 3xl:text-xl mt-3">
                                {t("Teamexperience")}
                              </p>
                            </div>
                            <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                              <p className="font-PlusJakartaSansSemiBold text-3xl 3xl:text-4xl text-mTextColor">
                                {isValidScore(summarydetails.market_potential)}
                                {/* {
                                  summarydetails[0].market_potential
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="font-PlusJakartaSansRegular text-mTextColor text-sm 3xl:text-xl mt-3">
                                {t("Marketpotential")}
                              </p>
                            </div>
                            <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                              <p className="font-PlusJakartaSansSemiBold text-3xl 3xl:text-4xl text-mTextColor">
                                {isValidScore(
                                  summarydetails.product_market_fit
                                )}
                                {/* {
                                  summarydetails[0].flow_and_clarity
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                              </p>
                              <p className="font-PlusJakartaSansRegular text-mTextColor text-sm 3xl:text-xl mt-3">
                                {t("Flowandclarity")}
                              </p>
                            </div>
                          </div>

                          <div className="w-full h-px bg-mBorderColor mt-10"></div>

                          <div className="grid grid-cols-1 gap-10 w-full mt-10">
                            <div>
                              <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base 3xl:text-xl text-mTextColor ">
                                {/* {t("areasforimprovement")} */}
                                Summary
                              </p>

                              <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt- leading-6">
                                {summarydetails.summary}
                              </p>
                            </div>

                            <div>
                              <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base 3xl:text-xl text-mTextColor ">
                                {t("mostpromisingaspects")}
                              </p>

                              {/* <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt-3 leading-6">
                                {summarydetails.most_promising_aspects}
                              </p> */}
                              <ul className="list-disc pl-5 space-y-2">
                                {summarydetails.most_promising_aspects
                                  .split("\n")
                                  .map((line, index) => (
                                    <li
                                      key={index}
                                      className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt-3 leading-6"
                                    >
                                      <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt-3 leading-6">
                                        {" "}
                                        {line.trim()}{" "}
                                      </p>
                                    </li>
                                  ))}
                              </ul>
                            </div>

                            <div className="hidden">
                              <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base 3xl:text-xl text-mTextColor ">
                                {t("recommendations")}
                              </p>

                              <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt-3 leading-6">
                                {summarydetails.notes}
                              </p>
                            </div>
                          </div>
                          <div className="absolute items-center justify-center flex hidden">
                            <button
                              className={`floating-button w-[50px] h-[50px] 3xl:w-[70px] 3xl:h-[70px] `}
                              onClick={ClickChat}
                            >
                              <ChatIcon className="w-6 h-6 3xl:w-8 3xl:h-8 flex-shrink-0 " />
                              {/* You can replace this with any icon */}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                  <div className="h-20"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:hidden block  p-3 overflow-auto">
            <div className="horizontal-list">
              {getSlideDetails.map((item, i) => (
                <div key={item.id} className="h-auto min-w-44 w-auto">
                  <div
                    className={`${
                      Postion === i ? " bg-transparent" : "bg-white"
                    }  ${
                      Postion === i ? " border-mGreyFour" : "border-white"
                    } rounded-lg p-5 border cursor-pointer`}
                    onClick={() => handleItemClick(item.id, i)}
                  >
                    <p
                      className="text-left font-PlusJakartaSansRegular font-normal  text-mTextColor text-sm"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item.title}
                    </p>

                    <div className="border-mGreyFour rounded-md mt-2 p-2  ">
                      <img alt="name" src={item.slide_image} className=" " />
                    </div>
                  </div>
                  <div className="h-3 "></div>
                </div>
              ))}
            </div>
            <div className="content">
              {!isEmptyObject(summarydetails) ? (
                <div>
                  <div className=" flex flex-col w-full bg-white  rounded-2xl p-6 justify-center items-center">
                    <div className="justify-between flex-row flex w-full ">
                      <div className="flex">
                        <p className="font-PlusJakartaSansSemiBold text-mTextColor text-lg 3xl:text-2xl">
                          {t("summary")}
                        </p>
                        <div
                          className="ml-2 mt-1 cursor-pointer  justify-center items-center flex"
                          onClick={() => infoclick(summarydetails.notes)}
                        >
                          <img
                            src={Images.infobtn}
                            alt="Profile"
                            className="cursor-pointer w-5 h-5"
                          />
                          {/* <SharefileIcon className="w-4 h-4 md:w-6 md:h-6 mt-1 ml-1 flex-shrink-0 " /> */}
                        </div>
                      </div>
                      <div onClick={() => openUrl()}>
                        <p className="font-PlusJakartaSansSemiBold text-mButtonColor text-base 3xl:text-xl cursor-pointer">
                          {t("DownloadReport")}
                        </p>
                      </div>
                    </div>

                    <div className="w-full mt-5 flex-row justify-center items-center py-5 flex ">
                      <div
                        className="w-3/5 h-full justify-start flex items-start gauge-container"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <GaugeChart
                          id="gauge-chart-custom"
                          nrOfLevels={1} // Sets the number of arc levels
                          arcsLength={[percentage / 10, 1 - percentage / 10]}
                          colors={[Color.mButtonColor, Color.trackColor]} // Sets color for the arc
                          arcWidth={0.25} // Sets arc width, reduce this for thinner arcs
                          percent={percentage / 10} // Sets the percentage the gauge is filled
                          needleColor={Color.mArcColor} // Sets the needle color
                          needleBaseColor={Color.needleColor} // Sets the needle base color
                          cornerRadius={0} // Removes edge radius
                          arcPadding={0}
                          animate={animate} // Control animation
                          hideText={true} // Removes padding between arcs
                        />
                      </div>
                      <div className="w-2/5 h-full items-center justify-center flex flex-col ">
                        <p className="font-PlusJakartaSansSemiBold text-6xl ">
                          {isValidScore(summarydetails.overall_rating)}
                          {/* {
                                  summarydetails[0].overall_rating
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                        </p>
                        <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-xl mt-3">
                          {t("OverallScore")}
                        </p>
                      </div>
                    </div>
                    <div className="w-full h-28 mt-10 flex-row flex ">
                      <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                        <p className="font-PlusJakartaSansSemiBold text-3xl 3xl:text-4xl text-mTextColor">
                          {isValidScore(summarydetails.team)}
                          {/* {
                                  summarydetails[0].team_exp
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                        </p>
                        <p className="font-PlusJakartaSansRegular text-mTextColor text-sm 3xl:text-xl mt-3">
                          {t("Teamexperience")}
                        </p>
                      </div>
                      <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                        <p className="font-PlusJakartaSansSemiBold text-3xl 3xl:text-4xl text-mTextColor">
                          {isValidScore(summarydetails.market_potential)}
                          {/* {
                                  summarydetails[0].market_potential
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                        </p>
                        <p className="font-PlusJakartaSansRegular text-mTextColor text-sm 3xl:text-xl mt-3">
                          {t("Marketpotential")}
                        </p>
                      </div>
                      <div className="flex-1 h-full items-start justify-center flex flex-col bg-white border border-mBorderColor rounded-lg ml-3 p-4">
                        <p className="font-PlusJakartaSansSemiBold text-3xl 3xl:text-4xl text-mTextColor">
                          {isValidScore(summarydetails.product_market_fit)}
                          {/* {
                                  summarydetails[0].flow_and_clarity
                                    .split(":")[1]
                                    .split("/")[0]
                                } */}
                        </p>
                        <p className="font-PlusJakartaSansRegular text-mTextColor text-sm 3xl:text-xl mt-3">
                          {t("Flowandclarity")}
                        </p>
                      </div>
                    </div>

                    <div className="w-full h-px bg-mBorderColor mt-10"></div>

                    <div className="grid grid-cols-1 gap-10 w-full mt-10">
                      <div>
                        <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base 3xl:text-xl text-mTextColor ">
                          {/* {t("areasforimprovement")} */}
                          Summary
                        </p>

                        <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt- leading-6">
                          {summarydetails.summary}
                        </p>
                      </div>

                      <div>
                        <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base 3xl:text-xl text-mTextColor ">
                          {t("mostpromisingaspects")}
                        </p>

                        {/* <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt-3 leading-6">
                          {summarydetails.most_promising_aspects}
                         
                        </p> */}

                        <ul className="list-disc pl-5 space-y-2">
                          {summarydetails.most_promising_aspects
                            .split("\n")
                            .map((line, index) => (
                              <li
                                key={index}
                                className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt-3 leading-6"
                              >
                                <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt-3 leading-6">
                                  {" "}
                                  {line.trim()}{" "}
                                </p>
                              </li>
                            ))}
                        </ul>
                      </div>

                      <div className="hidden">
                        <p className="ml-2 font-PlusJakartaSansRegular font-normal text-base 3xl:text-xl text-mTextColor ">
                          {t("recommendations")}
                        </p>

                        <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-lg text-mTextColorLogin mt-3 leading-6">
                          {summarydetails.notes}
                        </p>
                      </div>
                    </div>
                    <div className="absolute items-center justify-center flex hidden">
                      <button
                        className={`floating-button w-[50px] h-[50px] 3xl:w-[70px] 3xl:h-[70px] `}
                        onClick={ClickChat}
                      >
                        <ChatIcon className="w-6 h-6 3xl:w-8 3xl:h-8 flex-shrink-0 " />
                        {/* You can replace this with any icon */}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="mt-3 overflow-auto overflow-y-auto">
              <div className="content">
                {getSlideDetails.length > 0 ? (
                  <div>
                    {/* <div className=" rounded-2xl bg-white border-mBorderColor border">
                      <div className="p-1">
                        <img
                          alt="name"
                          src={getSlideDetails[Postion].slide_image}
                          className=" "
                        />
                      </div>
                    </div> */}

                    <div className=" rounded-2xl bg-white mt-5 p-6 overflow-auto">
                      <div className="grid grid-cols-1 gap-7 w-full">
                        {/* <p className="text-left font-PlusJakartaSansRegular text-mTextColorLogin text-sm 3xl:text-lg mt-3">
                          {formatTextWithLineBreaks(getllmResponse)}
                        </p> */}

                        <div
                          className="bg-white p-4 rounded-sm md:rounded-md lg:rounded-lg grid grid-cols-1 gap-5"
                          key={deckreview.id}
                        >
                          {deckreview.type_of_risk.map((typeofriskitem) => (
                            <div
                              className="bg-white p-4 rounded-sm md:rounded-md lg:rounded-lg grid grid-cols-1 gap-4"
                              key={deckreview.id}
                            >
                              <p className="font-PlusJakartaSansSemiBold text-base text-mButtonColor">
                                {typeofriskitem.title}
                                {" ( "}
                                {typeofriskitem.risk_score}
                                {" ) "}
                              </p>

                              <p className="font-PlusJakartaSansRegular text-base text-mTextColor">
                                {typeofriskitem.explanation}
                              </p>

                              <div className="ml-5 mt-3">
                                <p className="font-PlusJakartaSansSemiBold text-base text-mTextColor">
                                  Key factors influencing
                                </p>

                                {/* <p className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-1">
                                      {typeofriskitem.key_factors_influencing}

                                     
                                    </p> */}
                                <div className="mt-2">
                                  <ul className="list-disc pl-5 space-y-2">
                                    {typeofriskitem.key_factors_influencing
                                      .split("\n")
                                      .map((line, index) => (
                                        <li
                                          key={index}
                                          className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-1"
                                        >
                                          <p className="font-PlusJakartaSansRegular text-base text-mTextColorLogin mt-1">
                                            {" "}
                                            {line.trim()}{" "}
                                          </p>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>

                              <div className="ml-5">
                                <p className="font-PlusJakartaSansSemiBold text-base text-mTextColor">
                                  Strategies to manage product risk
                                </p>

                                {/* <p className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-1">
                                      {
                                        typeofriskitem.strategies_to_manage_product_risk
                                      }
                                    </p> */}
                                <div className="mt-2">
                                  <ul className="list-disc pl-5 space-y-2">
                                    {typeofriskitem.strategies_to_manage_product_risk
                                      .split("\n")
                                      .map((line, index) => (
                                        <li
                                          key={index}
                                          className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin mt-1"
                                        >
                                          <p className="font-PlusJakartaSansRegular text-base text-mTextColorLogin mt-1">
                                            {" "}
                                            {line.trim()}{" "}
                                          </p>
                                        </li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>

                        <p className="hidden text-left font-PlusJakartaSansSemiBold text-mTextColor text-lg">
                          Slide-by-slide analysis
                        </p>

                        <div>
                          <p className="text-left hidden font-PlusJakartaSansRegular text-mTextColor text-base">
                            {t("feedback")}
                          </p>

                          <p className="text-left font-PlusJakartaSansRegular text-mTextColorLogin text-sm mt-3 hidden">
                            {getSlideDetails[Postion].feedback}
                          </p>
                        </div>

                        <div>
                          <p className="text-left font-PlusJakartaSansRegular text-mTextColor text-base hidden">
                            {t("suggestion")}
                          </p>

                          <p className="text-left font-PlusJakartaSansRegular text-mTextColorLogin text-sm mt-3 hidden">
                            {getSlideDetails[Postion].suggestion}
                          </p>
                        </div>

                        <div className="">
                          <div className="w-full flex-row flex ">
                            <div className="w-3/4">
                              <p className="text-left font-PlusJakartaSansRegular text-mTextColor text-base ">
                                {t("helpful")}
                              </p>
                            </div>
                            <div className="w-1/4 justify-center items-center flex p-1">
                              <div className="w-1/2 justify-center items-center flex">
                                <img
                                  onClick={likeClick}
                                  src={
                                    likeselect ? Images.likeclick : Images.like
                                  }
                                  alt="Upload Icon"
                                  className=" w-4 h-4 cursor-pointer "
                                />
                              </div>
                              <div className="w-1/2 justify-center items-center flex">
                                <img
                                  onClick={dislikeClick}
                                  src={
                                    dislikeselect
                                      ? Images.dislikeclick
                                      : Images.dislike
                                  }
                                  alt="Upload Icon"
                                  className=" w-4 h-4 cursor-pointer"
                                />
                              </div>
                            </div>
                          </div>

                          {dislikeselect || likeselect ? (
                            <div className="mt-5">
                              <div className="mt-2 border border-mBorderColor rounded-md bg-mCardbgColor h-24">
                                <textarea
                                  id="message"
                                  rows="5"
                                  value={inputValue}
                                  onChange={(e) =>
                                    setInputValue(e.target.value)
                                  }
                                  maxLength={maxChars}
                                  className="block  w-full border-black h-full text-sm break-words text-mTextColor placeholder-mTextColorLogin px-2 py-3 text-start justify-start items-start  rounded-md   font-PlusJakartaSansRegular border-none focus:border-none  bg-mCardbgColor border-4 !outline-none"
                                  placeholder={
                                    "how can we improve? let us know."
                                  }
                                ></textarea>
                              </div>
                              <div className="justify-end items-end flex ">
                                <p className="mt-2 text-mTextColorLogin font-PlusJakartaSansRegular  text-xs">
                                  max: {inputValue.length} / {maxChars}
                                </p>
                              </div>
                              <div className="w-full justify-end items-start flex mt-6">
                                <div className="w-1/2">
                                  <PrimaryButton
                                    label={t("submit")}
                                    onClick={() => {
                                      Submit();
                                    }}
                                  ></PrimaryButton>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
                <div className="h-4"></div>
              </div>
            </div>

            <div className="w-full h-full overflow-y-scroll  pr-2 scrollbar-hide ">
              <div className="content"></div>
              <div className="h-20"></div>
            </div>
          </div>
          <AlertDialog
            isOpen={isAlertOpen}
            onClose={closeAlert}
            message={description}
            isShowDes={true}
          />
        </div>
      )}
    </>
  );
};

export default Deckdetails;
