import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { userviewstatus } from "../Services/allAPI";
import { toast } from "react-toastify";

export const UserAlertDialog = ({ isOpen, onClose, Confirm }) => {
  const dialogRef = useRef();
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  // Close the dialog when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onClose(); // Call onClose if the click is outside the dialog
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null; // Don't render if the dialog is not open

  const clickSubmit = () => {
    UserViewAPICall();
  };

  // Service Page API call
  const UserViewAPICall = async (typeofchat) => {
    setLoading(true);
    const response = await userviewstatus({
      user_name: UserInfoData.user_email,
      view_status: true,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        localStorage.setItem(
          "UserInfo",
          JSON.stringify(response?.data.message.userinfo)
        );
        Confirm();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div
        ref={dialogRef} // Attach the ref to the dialog
        className="bg-white flex flex-col w-[95%] lg:w-1/3 items-start justify-center p-4 rounded shadow-lg"
      >
        <h2 className="text-lg font-PlusJakartaSansSemiBold">Description</h2>

        <p className="mt-2 font-PlusJakartaSansRegular text-base">{""}</p>

        <div className="w-full justify-center items-center gap-5 lg:gap-10 mt-4 flex">
          <button
            onClick={onClose}
            className=" px-10 py-2 min-w-32 bg-mButtonColor font-PlusJakartaSansRegular text-base text-white rounded"
          >
            {t("cancel")}
          </button>

          <button
            onClick={() => clickSubmit()}
            className="px-10 py-2  min-w-32 bg-mButtonColor font-PlusJakartaSansRegular text-base text-white rounded"
          >
            {t("ok")}
          </button>
        </div>
      </div>
    </div>
  );
};
