import axios from "axios";

import { mToken } from "./baseURL";

export const commonAPI = async (method, url, data) => {
  // console.log(url);
  // console.log(data);
  let reqConfig = {
    method,
    url,
    data,
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,PATCH,POST,DELETE",

      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Headers":
        "Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale",
      Authorization: mToken,

      //Authorization: "token b4c9bbae92f64f6:63c7fe823796495",
    },
  };

  return await axios(reqConfig)
    .then((result) => {
      // Success: return the result
      return result;
    })
    .catch((err) => {
      if (err.response) {
        // Error : Return status code for further handling
        return err.response;
      } else if (err.request) {
        // Request was made, but no response was received (network error, etc.)
        alert("No response received from the server.");
        return "Network Error";
      } else {
        // Other errors (like setting up the request)
        alert(`Error: ${err.message}`);
        return "Error";
      }
    });

  // return await axios(reqConfig)
  //   .then((result) => {
  //     // alert(JSON.stringify(result));
  //     return result;
  //   })
  //   .catch((err) => {
  //     // alert("Error :" + JSON.stringify(err));
  //     return "500";
  //   });
};
