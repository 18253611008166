import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Utils/Loader";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../assets/Images/ic_back.svg";
import { ReactComponent as SparklesIcon } from "../../assets/Images/ic_sparkles.svg";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { Images, isValidEmail } from "../../Utils/Constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Menutext from "../../Components/Menutext";
import Menutitletext from "../../Components/Menutitletext";
import Titletext from "../../Components/Titletext";
import LogoText from "../../Components/LogoText";

const SuggestingStartupInvestors = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const mBackClick = () => {
    navigate("/deckscore");
  };

  const ProceedClick = () => {};

  const skipclick = () => {
    navigate("/login");
  };

  const investordb = [
    { id: 11, name: "Pre Seed" },
    { id: 12, name: "Seed" },
    { id: 13, name: "Series A" },
    { id: 14, name: "Series B" },
    { id: 15, name: "Pre Seed" },
    { id: 16, name: "Seed" },
    { id: 17, name: "Series A" },
    { id: 18, name: "Series B" },
  ];
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="min-h-screen flex flex-row">
          <div className=" hidden lg:block w-3/5 bg-mwhite  flex-col items-start justify-start p-5">
            <div className="mt-3 ml-3">
              {/* <img
                src={Images.zenpivot}
                alt="google Icon"
                className=" h-9 w-28"
              /> */}
              <LogoText label={t("appname")} />
            </div>

            <div className="w-full h-full justify-center items-center flex">
              <img src={Images.investorbg} alt="login" className="w-3/4 " />
            </div>
          </div>
          <div className="lg:w-2/5 w-full pl-2 py-5 lg:pl-20 lg:py-10 items-center justify-center lg:items-start lg:justify-start bg-mBackground flex-col flex ">
            <div className="w-11/12 h-full ">
              <div className="grid gap-5 grid-cols-4   w-full h-2 mt-5 items-center justify-center ">
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
              </div>

              <div className="mt-4  w-full justify-between flex">
                <div>
                  <p className="text-mTextColor font-PlusJakartaSansMedium text-sm 3xl:text-base">
                    Step 4 / 4
                  </p>
                </div>

                <div>
                  <p className="text-mTextColor font-PlusJakartaSansMedium text-sm cursor-pointer 3xl:text-base"></p>
                </div>
              </div>

              <div className="mt-10 w-full p-2  items-start justify-start flex-col flex">
                <div className="flex flex-row items-center justify-center">
                  <BackIcon
                    className="w-6 h-6 flex-shrink-0 cursor-pointer"
                    onClick={() => mBackClick()}
                  />{" "}
                  <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-base">
                    {t("GoBack")}
                  </p>
                </div>

                <div>
                  <p className="font-PlusJakartaSansSemiBold text-4xl mt-10">
                    {t("SuggestedInvestorsyou")}
                  </p>
                </div>

                {/* <div className="w-4/5 h-full  ml-5 overflow-auto scrollbar-hide">
                  {investordb.length !== 0 ? (
                    <div className="grid-containeritemsugginv">
                      {investordb.map((item) => (
                        <div
                          key={item.id}
                          className="grid-itemsugginv  bg-white p-3"
                        >
                          <div className=" grid grid-cols-1 gap-5">
                            <div className="p-4 flex flex-row justify-between">
                              <div className="flex-row flex h-full w-full justify-center items-center">
                                <img
                                  className="rounded-lg bg-white w-9 h-9"
                                  src={
                                    item.image.length === 0
                                      ? Images.image
                                      : item.image
                                  }
                                  alt=""
                                />
                                <div className="h-full flex w-full ml-2 flex-col">
                                  <Menutext label={item.investor_name} />

                                  <div className="mt-1">
                                    <Menutitletext label={item.investor_type} />
                                  </div>
                                </div>
                              </div>
                              <div></div>
                            </div>

                            <div className="w-full h-px bg-mBorderColor"></div>

                            <div className="grid grid-cols-3 gap-3 w-full ">
                              {item.funding_stages.map((stagesitem) => (
                                <div
                                  className="p-2 rounded-md bg-mCardbgColor justify-center items-center flex"
                                  key={stagesitem.id}
                                >
                                  <p className="font-PlusJakartaSansRegular text-mTextColorLogin text-xs">
                                    {stagesitem.funding_stages}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="justify-center items-center mt-16  flex-col flex w-full h-full ">
                      <img
                        className="rounded-lg bg-white "
                        src={Images.nodata}
                        alt="review"
                      />
                      <div className="mt-10">
                        {" "}
                        <Titletext label={t("nodata")} />
                      </div>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default SuggestingStartupInvestors;
