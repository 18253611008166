import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DashboardTitle from "../../Components/DashboardTitle";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_closegreen.svg";
import { ReactComponent as SubscriptionsIcon } from "../../assets/Images/ic_subscriptions.svg";
import { ReactComponent as PaymentIcon } from "../../assets/Images/ic_paymentlist.svg";
import { Images } from "../../Utils/Constants";
import { getpaymentdetails } from "../../Services/allAPI";
import { toast } from "react-toastify";
import Loader from "../../Utils/Loader";
import { UserAlertDialog } from "../../Components/UserAlertDialog";

const SubscriptionInfo = () => {
  const { t } = useTranslation("");
  const [selectView, setSelectView] = useState(1);
  const [loading, setLoading] = useState(false);
  const [allpayments, setAllpayments] = useState([]);
  const [successpayment, setSuccesspayment] = useState([]);
  const [failedpayment, setFailedpayment] = useState([]);
  const [currentPlan, setcurrentPlan] = useState({});

  const ClickSucesspayment = () => {
    setSelectView(2);
  };

  const ClickFailpayments = () => {
    setSelectView(3);
  };

  const ClickAllpayments = () => {
    setSelectView(1);
  };

  //Set user Alert Msg
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  //
  useEffect(() => {
    ServicePageAPICall();
    if (!UserInfoData.view_status) {
      setIsAlertOpen(true);
    }
  }, []);

  //Set user Alert close
  const closeAlert = () => setIsAlertOpen(false);

  //Set user Alert reload page
  const Confirm = () => {
    setIsAlertOpen(false);
    window.location.reload();
  };

  // Service Page API call
  const ServicePageAPICall = async () => {
    setLoading(true);
    const response = await getpaymentdetails({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setcurrentPlan(
          response?.data.message.subscription_payment_details.current_plan
        );
        setAllpayments(
          response?.data.message.subscription_payment_details.all_payments
        );
        setSuccesspayment(
          response?.data.message.subscription_payment_details.success_payment
        );
        setFailedpayment(
          response?.data.message.subscription_payment_details.failed_payment
        );
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  // const data = [
  //   {
  //     code: "#29359",
  //     amount: "₹2500",
  //     status: "Incomplete",
  //     time: "03:09 AM",
  //     date: "Mar 15, 2023",
  //     plan: "Monthly",
  //     ipAddress: "192.168.0.1",
  //     paymentMethod: "****67836",
  //     statusStyle: "bg-gray-200 text-gray-500",
  //   },
  //   {
  //     code: "#29350",
  //     amount: "₹2500",
  //     status: "Succeeded",
  //     time: "02:26 AM",
  //     date: "Feb 15, 2023",
  //     plan: "Monthly",
  //     ipAddress: "192.168.0.1",
  //     paymentMethod: "****67836",
  //     statusStyle: "bg-green-100 text-green-500",
  //   },
  //   {
  //     code: "#29340",
  //     amount: "₹2500",
  //     status: "Succeeded",
  //     time: "12:09 AM",
  //     date: "Jan 15, 2023",
  //     plan: "Monthly",
  //     ipAddress: "192.168.0.1",
  //     paymentMethod: "****67836",
  //     statusStyle: "bg-green-100 text-green-500",
  //   },
  // ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={``}>
          <div
            className={`${
              !UserInfoData.view_status ? "blur-sm pointer-events-none" : ""
            }`}
          >
            <div className="h-16 md:h-9 justify-between flex flex-row">
              <div className="justify-center items-center flex">
                <SubscriptionsIcon className="w-4 h-4 md:w-5 md:h-5 flex-shrink-0 cursor-pointer" />
                <div className="ml:2 md:ml-3">
                  {" "}
                  <DashboardTitle label={t("Subscriptions")} />
                </div>
              </div>
              <div className="hidden h-9 ml-1 md:ml-6 mt-3 md:mt-0 p-2 md:p-4 bg-mButtonColorligth rounded-lg items-center justify-center  cursor-pointer">
                <CloseIcon className="w-4 h-4 md:w-5 md:h-5 flex-shrink-0 cursor-pointer" />
                <p className="ml-2 font-PlusJakartaSansRegular text-mButtonColor text-xs md:text-base">
                  {t("CancelSubscription")}
                </p>
              </div>
            </div>
            <div className="mt-7 w-full md:w-1/2 bg-white rounded-xl">
              <div className="justify-end flex">
                {" "}
                {/* <div className="w-28 py-1 bg-mButtonColor justify-center items-center flex rounded-tr-xl">
                  <p className="ml-2 font-PlusJakartaSansRegular text-white text-xs ">
                    {t("Billingmonthly")}
                  </p>
                </div> */}
              </div>
              <div className="p-2 flex flex-row mb-2">
                <div>
                  <img
                    src={Images.crownimage}
                    alt="Profile"
                    className="w-16 h-16 rounded-full ml-3"
                  />
                </div>
                <div className=" ml-5 justify-center items-start flex flex-col">
                  <div className="flex">
                    <DashboardTitle label={currentPlan.plan_name} />
                    <div className="ml-3">
                      <DashboardTitle
                        label={t("rupess") + currentPlan.format_offer_price}
                      />
                    </div>
                  </div>

                  {/* <div>
                    <span className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin">
                      Next invoice on Jun 01,2023 for
                    </span>{" "}
                    <span className="font-PlusJakartaSansSemiBold text-sm text-mTextColorLogin">
                      {" "}
                      ₹ 2500
                    </span>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="justify-start items-center flex mt-10">
              <PaymentIcon className="w-4 h-4 md:w-5 md:h-5 flex-shrink-0 cursor-pointer" />
              <div className="ml:2 md:ml-3">
                {" "}
                <DashboardTitle label={t("Payments")} />
              </div>
            </div>
            <div className="mt-5">
              <div className="mt-5 flex">
                <div
                  onClick={() => ClickAllpayments()}
                  className="cursor-pointer w-auto"
                >
                  <p
                    className={` text-base 3xl:text-base ${
                      selectView === 1
                        ? "font-PlusJakartaSansSemiBold"
                        : "font-PlusJakartaSansRegular"
                    }  ${
                      selectView === 1
                        ? "text-mButtonColor"
                        : "text-mTextColorLogin"
                    } text-mSeventeen `}
                  >
                    {t("Allpayments")}
                  </p>
                </div>

                <div
                  onClick={() => ClickSucesspayment()}
                  className="cursor-pointer w-auto ml-5 "
                >
                  <p
                    className={` text-base 3xl:text-base ${
                      selectView === 2
                        ? "font-PlusJakartaSansSemiBold"
                        : "font-PlusJakartaSansRegular"
                    }  ${
                      selectView === 2
                        ? "text-mButtonColor"
                        : "text-mTextColorLogin"
                    }  ml-5 text-mSeventeen `}
                  >
                    {t("Succeeded")}
                  </p>
                </div>

                <div
                  onClick={() => ClickFailpayments()}
                  className="cursor-pointer w-auto ml-5 "
                >
                  <p
                    className={`text-base 3xl:text-base ${
                      selectView === 3
                        ? "font-PlusJakartaSansSemiBold"
                        : "font-PlusJakartaSansRegular"
                    }  ${
                      selectView === 3
                        ? "text-mButtonColor"
                        : "text-mTextColorLogin"
                    }  ml-5 text-mSeventeen `}
                  >
                    {t("Failed")}
                  </p>
                </div>
              </div>
              <div className="mt-3   flex relative justify-start items-center">
                <div className="w-full border border-mBorderColor absolute">
                  {" "}
                </div>
                {/* <div className="w-44 border-2 border-purple-500 relative"> </div> */}
                <div
                  className={`w-28 border-2 ${
                    selectView === 1
                      ? "border-mButtonColor"
                      : "border-transparent"
                  } `}
                ></div>
                <div
                  className={`w-28 ml-6 3xl:ml-11 border-2 ${
                    selectView === 2
                      ? "border-mButtonColor"
                      : "border-transparent"
                  } `}
                ></div>
                <div
                  className={`w-24 border-2 ml-5 3xl:ml-5 ${
                    selectView === 3
                      ? "border-mButtonColor"
                      : "border-transparent"
                  } `}
                ></div>
              </div>

              <div className="mt-3 flex">
                <p className="font-PlusJakartaSansSemiBold text-base  text-[#171A1F]">
                  {selectView === 1
                    ? allpayments.length
                    : selectView === 2
                    ? successpayment.length
                    : failedpayment.length}
                </p>
                <p className="ml-1 font-PlusJakartaSansSemiBold text-base text-[#6F7787]">
                  total
                </p>
              </div>

              <div className="overflow-x-auto mt-3 border border-mBorderColor rounded-lg">
                <table className="min-w-full bg-white rounded-lg">
                  <thead className="bg-mshadowColor text-center">
                    <tr>
                      <TabelHeading label={t("Code")} />

                      <TabelHeading label={t("Amount")} />
                      <TabelHeading label={t("Status")} />
                      <TabelHeading label={t("Time")} />
                      <TabelHeading label={t("Date")} />
                      <TabelHeading label={t("Plan")} />
                    </tr>
                  </thead>
                  <tbody>
                    {/* {selectView === 1
                      ? allpayments
                      : selectView === 2
                      ? successpayment
                      : failedpayment.map((row, index) => (
                          <tr
                            key={index}
                            className={` p-2 items-center justify-between text-center ${
                              index % 2 === 0 ? "bg-white" : "bg-mshadowColor"
                            }`}
                          >
                            <td className="px-2 py-4 text-sm font-PlusJakartaSansMedium text-mButtonColor">
                              {row.name}
                            </td>

                            <td className="px-6 py-4 text-sm font-PlusJakartaSansSemiBold text-mTextColor">
                              {t("rupess")} {row.amount}
                            </td>
                            <td className="px-2 py-4">
                              <span
                                className={`px-3 py-1 text-sm font-medium rounded-full `}
                              >
                                {row.payment_status}
                              </span>
                            </td>
                            <td className="px-2 py-4 text-sm font-PlusJakartaSansRegular text-mTextColor">
                              {row.time}
                            </td>
                            <td className="px-2 py-4 text-sm font-PlusJakartaSansRegular text-mTextColor">
                              {row.date}
                            </td>
                            <td className="px-2 py-4 text-sm font-PlusJakartaSansRegular text-mTextColor">
                              {row.plan_name}
                            </td>
                          </tr>
                        ))} */}
                    {selectView === 1 && allpayments.length > 0
                      ? allpayments.map((row, index) => (
                          <tr
                            key={index}
                            className={`p-2 items-center justify-between text-center ${
                              index % 2 === 0 ? "bg-white" : "bg-mshadowColor"
                            }`}
                          >
                            <CodeHeading label={row.name} />

                            <AmountHeading
                              label={t("rupess") + " " + row.amount}
                            />

                            <StatusHeading label={row.payment_status} />

                            <TimeHeading label={row.time} />

                            <TimeHeading label={row.date} />

                            <TimeHeading label={row.plan_name} />
                          </tr>
                        ))
                      : selectView === 2 && successpayment.length > 0
                      ? successpayment.map((row, index) => (
                          <tr
                            key={index}
                            className={`p-2 items-center justify-between text-center ${
                              index % 2 === 0 ? "bg-white" : "bg-mshadowColor"
                            }`}
                          >
                            <CodeHeading label={row.name} />
                            <AmountHeading
                              label={t("rupess") + " " + row.amount}
                            />
                            <StatusHeading label={row.payment_status} />
                            <TimeHeading label={row.time} />

                            <TimeHeading label={row.date} />

                            <TimeHeading label={row.plan_name} />
                          </tr>
                        ))
                      : failedpayment.map((row, index) => (
                          <tr
                            key={index}
                            className={`p-2 items-center justify-between text-center ${
                              index % 2 === 0 ? "bg-white" : "bg-mshadowColor"
                            }`}
                          >
                            <CodeHeading label={row.name} />

                            <AmountHeading
                              label={t("rupess") + " " + row.amount}
                            />
                            <StatusHeading label={row.payment_status} />
                            <TimeHeading label={row.time} />

                            <TimeHeading label={row.date} />

                            <TimeHeading label={row.plan_name} />
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
              <div className="h-5"></div>
            </div>
          </div>

          <UserAlertDialog
            isOpen={isAlertOpen}
            onClose={closeAlert}
            Confirm={Confirm}
          />
        </div>
      )}
    </>
  );
};

export default SubscriptionInfo;

export const TabelHeading = ({ label }) => (
  <th className="px-2 py-4 text-sm font-PlusJakartaSansSemiBold text-mTextColor">
    {label}
  </th>
);

export const CodeHeading = ({ label }) => (
  <td className="px-2 py-4 text-sm 3xl:text-sm font-PlusJakartaSansMedium text-mButtonColor">
    {label}
  </td>
);

export const AmountHeading = ({ label }) => (
  <td className="px-6 py-4 text-sm 3xl:text-sm font-PlusJakartaSansSemiBold text-mTextColor">
    {label}
  </td>
);

export const StatusHeading = ({ label }) => (
  <th
    className={`px-2 py-4 text-sm 3xl:text-sm font-PlusJakartaSansSemiBold ${
      label === "Paid"
        ? "text-mButtonColor"
        : label === "Success"
        ? "text-mButtonColor"
        : "text-red-500"
    }`}
  >
    {label}
  </th>
);

export const TimeHeading = ({ label }) => (
  <th className="px-2 py-4 text-sm 3xl:text-sm font-PlusJakartaSansRegular text-mTextColor">
    {label}
  </th>
);
