import React from "react";
import { Images } from "./Constants";
import "../App.css";
import Lottie from "lottie-react";
import loaderAnimation from "../assets/Images/loader.json";

const Loader = () => {
  return (
    <div className="loader-container">
      <Lottie
        animationData={loaderAnimation}
        loop={true} // Set to true to loop the animation
        style={{ width: 150, height: 150 }} // Adjust size as needed
      />
    </div>
  );
};

export default Loader;
// src="https://example.com/loader.gif"
