import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";
import { ReactComponent as UploadIcon } from "../../assets/Images/ic_upload.svg";
import TextView from "../../Components/TextView";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { Images, isValidEmail } from "../../Utils/Constants";
import { toast } from "react-toastify";
import { mEditAboutcompany, mEditcompanydetails } from "../../Services/allAPI";
import Loader from "../../Utils/Loader";
import { Country, State, City } from "country-state-city";
import "../../Components/Autocomplete/Autocompletestyle.css";
import TextViewlight from "../../Components/TextViewlight";

const Companyinfoedit = ({
  show,
  onCancel,
  clickCompOutsideClose,
  isEditAbout,
  companyInformation,
}) => {
  const { t } = useTranslation("");
  const [companyname, setCompanyName] = useState(
    companyInformation[0].company_name
  );
  const [postion, setPostion] = useState(companyInformation[0].position);
  const [sector, setSector] = useState(companyInformation[0].sector);
  const [email, setEmailId] = useState(companyInformation[0].mail_id);
  const [address, setAddress] = useState(companyInformation[0].address);
  const [website, setWebsite] = useState(companyInformation[0].website);

  const [about, setabout] = useState(companyInformation[0].company_about);
  const [loading, setLoading] = useState(false);

  const isDisabled =
    !companyname || !postion || !sector || !email || !address || !website;
  const isAboutDisabled = !about;

  const saveClick = () => {
    if (!isValidEmail(email)) {
      toast.error(t("vaildmail"));
      return;
    }
    SaveCall();
  };

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  // Company save API Call
  const SaveCall = async () => {
    setLoading(true);
    const response = await mEditcompanydetails({
      user_name: UserInfoData.user_email,
      company_name: companyname,
      position: postion,
      sector: sector,
      mail_id: email,
      address: address,
      address_id: companyInformation[0].address_id,
      city: selectedCity,
      country: selectedCountry,
      state: selectedState,
      pincode: "",
      website: website,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        onCancel();
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const saveAboutClick = () => {
    SaveAboutCall();
  };

  // Company about save API Call
  const SaveAboutCall = async () => {
    setLoading(true);
    const response = await mEditAboutcompany({
      user_name: UserInfoData.user_email,
      about_company: about,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        onCancel();
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  // Select country state city start

  const [selectedCountry, setSelectedCountry] = useState(
    companyInformation[0].country
  );
  const [selectedCountryid, setSelectedCountryid] = useState(null);
  const [selectedState, setSelectedState] = useState(
    companyInformation[0].state
  );
  const [selectedStateid, setSelectedStateid] = useState(null);
  const [selectedCity, setSelectedCity] = useState(companyInformation[0].city);
  // const [inputValue, setInputValue] = useState("");
  const [Countryfiltered, setCountryFiltered] = useState([]);
  const [mGetState, setmGetState] = useState([]);
  const [Statefiltered, setStateFiltered] = useState([]);
  const [mGetCity, setmGetCity] = useState([]);
  const [Cityfiltered, setCityfiltered] = useState([]);
  const [isCountryDropdownDisabled, setIsCountryDropdownDisabled] =
    useState(false);

  const [isStateDropdownDisabled, setIsStateDropdownDisabled] = useState(false);
  const [isCityDropdownDisabled, setIsCityDropdownDisabled] = useState(false);

  const [isStateDisabled, setIsStateDisabled] = useState(true);
  const [isCityDisabled, setIsCityDisabled] = useState(true);

  // Get country options for the dropdown
  const countryOptions = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  const handleCountryChange = (event) => {
    const inputValue = event.target.value;
    // setInputValue(inputValue);
    setSelectedCountry(inputValue);
    setIsCountryDropdownDisabled(false);
    const filteredSuggestions = countryOptions.filter((suggestion) =>
      suggestion.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    //alert(JSON.stringify(filteredSuggestions));
    setCountryFiltered(filteredSuggestions);
  };

  const handleCountrySelect = (name, id) => {
    setSelectedState(null);
    setSelectedStateid(null);
    setSelectedCity(null);
    setSelectedCountry(name);
    setIsStateDisabled(false);
    setIsCityDisabled(true);
    setSelectedCountryid(id);
    setIsCountryDropdownDisabled(true);

    const stateOptions = Countryfiltered
      ? State.getStatesOfCountry(id).map((state) => ({
          value: state.isoCode,
          label: state.name,
        }))
      : [];
    setmGetState(stateOptions);
  };

  const handleStateChange = (event) => {
    if (!isStateDisabled) {
      const inputValue = event.target.value;
      setSelectedState(inputValue);
      setIsStateDropdownDisabled(false);
      const filteredStateSuggestions = mGetState.filter((suggestion) =>
        suggestion.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      setStateFiltered(filteredStateSuggestions);
    }
  };

  const handleStateSelect = (name, id) => {
    setSelectedStateid(id);
    setSelectedState(name);
    setIsCityDisabled(false);
    setSelectedCity(null);
    setIsStateDropdownDisabled(true);
    const cityOptions = selectedState
      ? City.getCitiesOfState(selectedCountryid, id).map((city) => ({
          value: city.name,
          label: city.name,
        }))
      : [];

    setmGetCity(cityOptions);
  };

  const handleCityChange = (event) => {
    // if (isCityDisabled) {
    const inputValue = event.target.value;

    setSelectedCity(inputValue);
    setIsCityDropdownDisabled(false);
    const filteredCitySuggestions = mGetCity.filter((suggestion) =>
      suggestion.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    setCityfiltered(filteredCitySuggestions);
    // }
  };

  const handleCitySelect = (value) => {
    setSelectedCity(value);
    setIsCityDropdownDisabled(true);
  };

  // Select country state city end

  const dialogRef = useRef();

  // Close the dialog when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        clickCompOutsideClose(); // Call onClose if the click is outside the dialog
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, clickCompOutsideClose]);

  if (!show) return null; // Don't render if the dialog is not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  z-50">
      {/* max-w-sm */}

      {!isEditAbout ? (
        <div
          className="bg-mDashboardBg rounded-lg h-90  mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4 "
          ref={dialogRef}
        >
          <div className="justify-between flex">
            <div>
              <p className="font-PlusJakartaSansSemiBold text-lg text-mTextColor">
                {t("update")} {t("CompanyInformation")}
              </p>
            </div>

            <div>
              <button onClick={onCancel}>
                <CloseIcon className="mb-4 " />
              </button>
            </div>
          </div>

          {loading ? (
            // <div className="max-h-72">
            <Loader />
          ) : (
            <div>
              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("CompanyName")} ismandatory={true} />
                </div>
                <InputContainer
                  type="text"
                  value={companyname}
                  placeholder={t("enter") + t("CompanyName")}
                  label="text"
                  name="text"
                  disabled={false}
                  maxLength={200}
                  showIcon={false}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>

              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("Position")} ismandatory={true} />
                </div>
                <InputContainer
                  type="text"
                  value={postion}
                  placeholder={t("enter") + t("Position")}
                  label="text"
                  name="text"
                  disabled={false}
                  maxLength={200}
                  showIcon={false}
                  onChange={(e) => {
                    setPostion(e.target.value);
                  }}
                />
              </div>

              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("Sector")} ismandatory={true} />
                </div>
                <InputContainer
                  type="text"
                  value={sector}
                  placeholder={t("enter") + t("Sector")}
                  label="text"
                  name="text"
                  disabled={false}
                  maxLength={200}
                  showIcon={false}
                  onChange={(e) => {
                    setSector(e.target.value);
                  }}
                />
              </div>

              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("MailID")} ismandatory={true} />
                </div>
                <InputContainer
                  type="email"
                  value={email}
                  placeholder={t("enter") + t("MailID")}
                  label="email"
                  name="email"
                  disabled={false}
                  maxLength={200}
                  showIcon={false}
                  onChange={(e) => {
                    setEmailId(e.target.value);
                  }}
                />
              </div>

              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("Address")} ismandatory={true} />
                </div>
                <InputContainer
                  type="text"
                  value={address}
                  placeholder={t("enter") + t("Address")}
                  label="text"
                  name="text"
                  disabled={false}
                  maxLength={200}
                  showIcon={false}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>

              {/* Country Select */}
              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("Country")} ismandatory={true} />
                </div>
                <div className=" mt-3">
                  <div className="autocomplete-container absolute">
                    <input
                      className="autocomplete-input font-PlusJakartaSansRegular text-sm  3xl:text-sm text-mTextColor placeholder-mTextColorLogin"
                      type="text"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      placeholder="Search country..."
                    />

                    {!isCountryDropdownDisabled && (
                      <ul className="autocomplete-suggestions">
                        {Countryfiltered.map((suggestion, index) => (
                          <li
                            key={index}
                            className="autocomplete-suggestion font-PlusJakartaSansRegular text-mTextColor text-sm  3xl:text-sm"
                            onClick={() =>
                              handleCountrySelect(
                                suggestion.label,
                                suggestion.value
                              )
                            }
                          >
                            <div className="w-full h-full">
                              <div className="flex-row flex h-full">
                                <div className="h-9 flex  ml-2 justify-center items-center">
                                  <TextViewlight label={suggestion.label} />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              {/* State Select */}
              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("state")} ismandatory={true} />
                </div>
                <div className=" mt-3">
                  <div className="autocomplete-container absolute">
                    <input
                      className={`autocomplete-input font-PlusJakartaSansRegular text-sm  3xl:text-sm text-mTextColor placeholder-mTextColorLogin ${
                        isStateDisabled ? "bg-mMenuSelect" : "bg-white"
                      }`}
                      type="text"
                      value={selectedState}
                      onChange={handleStateChange}
                      disabled={isStateDisabled}
                      placeholder="Search state..."
                    />

                    {!isStateDropdownDisabled && (
                      <ul className="autocomplete-suggestions">
                        {Statefiltered.map((suggestion, index) => (
                          <li
                            key={index}
                            className="autocomplete-suggestion font-PlusJakartaSansRegular text-mTextColor text-sm  3xl:text-sm"
                            onClick={() =>
                              handleStateSelect(
                                suggestion.label,
                                suggestion.value
                              )
                            }
                          >
                            <div className="w-full h-full">
                              <div className="flex-row flex h-full">
                                <div className="h-9 flex  ml-2 justify-center items-center">
                                  <TextViewlight label={suggestion.label} />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              {/* City Select */}
              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("city")} ismandatory={true} />
                </div>
                <div className=" mt-3">
                  <div className="autocomplete-container absolute">
                    <input
                      className={`autocomplete-input font-PlusJakartaSansRegular text-sm  3xl:text-sm text-mTextColor placeholder-mTextColorLogin ${
                        isCityDisabled ? "bg-mMenuSelect" : "bg-white"
                      }`}
                      type="text"
                      value={selectedCity}
                      onChange={handleCityChange}
                      disabled={isCityDisabled}
                      placeholder="Search City..."
                    />

                    {!isCityDropdownDisabled && (
                      <ul className="autocomplete-suggestions">
                        {Cityfiltered.map((suggestion, index) => (
                          <li
                            key={index}
                            className="autocomplete-suggestion font-PlusJakartaSansRegular text-mTextColor text-sm  3xl:text-sm"
                            onClick={() => handleCitySelect(suggestion.label)}
                          >
                            <div className="w-full h-full">
                              <div className="flex-row flex h-full">
                                <div className="h-9 flex  ml-2 justify-center items-center">
                                  <TextViewlight label={suggestion.label} />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("Website")} ismandatory={true} />
                </div>
                <InputContainer
                  type="text"
                  value={website}
                  placeholder={t("enter") + t("Website")}
                  label="text"
                  name="text"
                  disabled={false}
                  maxLength={200}
                  showIcon={false}
                  onChange={(e) => {
                    setWebsite(e.target.value);
                  }}
                />
              </div>

              <div className="mt-5">
                <PrimaryButton
                  label={t("save")}
                  onClick={saveClick}
                  onDisable={isDisabled}
                ></PrimaryButton>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="bg-mDashboardBg rounded-lg mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4 "
          ref={dialogRef}
        >
          <div className="justify-between flex">
            <div>
              <p className="font-PlusJakartaSansSemiBold text-lg text-mTextColor">
                {t("update")} {t("CompanyInformation")}
              </p>
            </div>

            <div>
              <button onClick={onCancel}>
                <CloseIcon className="mb-4 " />
              </button>
            </div>
          </div>

          {loading ? (
            <div className="max-h-60">
              <Loader />
            </div>
          ) : (
            <div>
              <div className="mt-5">
                <div className="mt-4">
                  <TextView label={t("About")} ismandatory={true} />
                </div>
                <div className="h-32 mt-2 border-mGreyFour text-start justify-start items-start flex text-xs  lg:text-sm  rounded-md w-full  border bg-mLightColorOne">
                  <textarea
                    id="message"
                    rows="5"
                    value={about}
                    onChange={(e) => setabout(e.target.value)}
                    maxLength={3000}
                    className="block  w-full border-black  text-sm h-full break-words text-mTextColor px-2 py-3 text-start justify-start items-start  rounded-md   font-PlusJakartaSansRegular border-none focus:border-none  bg-mLightColorOne border-4 !outline-none"
                    placeholder={t("enter") + t("About")}
                  ></textarea>
                </div>
              </div>

              <div className="mt-5">
                <PrimaryButton
                  label={t("save")}
                  onClick={saveAboutClick}
                  onDisable={isAboutDisabled}
                ></PrimaryButton>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Companyinfoedit;
