import React, { useEffect, useRef } from "react";
import Titletext from "./Titletext";
import { ReactComponent as BackIcon } from "../assets/Images/ic_summeryback.svg";
import { ReactComponent as NotificationIcon } from "../assets/Images/ic_notification.svg";
import { Images } from "../Utils/Constants";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CrownIcon } from "../assets/Images/ic_crown.svg";
import DashboardTitle from "./DashboardTitle";
import { ReactComponent as LogoutIcon } from "../assets/Images/ic_logout.svg";
import { ReactComponent as CloseIcon } from "../assets/Images/ic_close.svg";
import { useDispatch } from "react-redux";
import { removeUser } from "../redux/slice/userSlice";
import { getusernotifications, notificationDelete } from "../Services/allAPI";
import { toast } from "react-toastify";
import DeleteDialog from "./DeleteDialog";
import PrimaryButton from "./PrimaryButton";

const HeaderMenu = ({
  title,
  onClick,
  isShowing,
  notShowsubscription,
  mClickstatus,
  isLoadNotification,
  isCallBtn,
  balancedeck,
  balancedeckstatus,
}) => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  const menuRef = useRef(null);
  const notifyRef = useRef(null);
  const notifyRef1 = useRef(null);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationmessage, setNotificationMessage] = useState([]);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const today = new Date();

  // Get the current day, month, and year
  const currentDate = `${String(today.getDate()).padStart(2, "0")}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${today.getFullYear()}`;

  // Calculate no of days

  const [remainingDays, setRemainingDays] = useState(null);

  const start = currentDate;
  const end = UserInfoData.subscription_expiry_date;

  useEffect(() => {
    // Split the string into day, month, and year
    // const [startday, startmonth, startyear] = start.split("-");
    // const [endday, endmonth, endyear] = end.split("-");

    // const differenceInTime =
    //   new Date(endyear, endmonth - 1, endday).getTime() -
    //   new Date(startyear, startmonth - 1, startday).getTime();

    // // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
    // const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    // setRemainingDays(differenceInDays);
    setRemainingDays(10);
  }, []);

  // End Calculate no of days

  // Click Menu open
  const toggleMenu = () => {
    if (!mClickstatus) {
      setIsOpen(!isOpen);
    }
  };

  // Click Subscription menu
  const SubscriptionClick = () => {
    if (!mClickstatus) {
      navigate("/subscription");
    }
  };

  // Close the menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(isOpen);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  // Close the menu on notification
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (notifyRef.current && notifyRef.current.contains(event.target)) {
  //       setShowPopup(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [notifyRef]);

  // Notification click Event
  const NotificationClick = () => {
    if (!mClickstatus) {
      if (notificationmessage.length !== 0) {
        setShowPopup(true);
        document.body.style.overflow = "hidden"; // Disable background scrolling when the popup is shown
      } else {
        toast.success("Notification not available");
      }
    }
  };

  // Close the alert message
  const handleClose = () => {
    setShowPopup(false);
    document.body.style.overflow = "auto"; // Re-enable background scrolling
  };

  const handleClick = () => {
    // setShowPopup(false);
    // document.body.style.overflow = "auto"; // Re-enable background scrolling
  };
  // Click Logout button
  const mLogout = () => {
    localStorage.clear();
    dispatch(removeUser());
    navigate("/login");
  };

  useEffect(() => {
    if (isLoadNotification) {
      NotificationDetails();
    } else {
      setNotificationMessage(
        localStorage.getItem("NotificationMessage") !== null
          ? JSON.parse(localStorage.getItem("NotificationMessage"))
          : []
      );
      setNotificationCount(
        localStorage.getItem("NotificationCount") !== null
          ? JSON.parse(localStorage.getItem("NotificationCount"))
          : 0
      );
    }
  }, []);

  const NotificationDetails = async () => {
    setLoading(true);
    const response = await getusernotifications({
      user_name: UserInfoData.user_email,
      // user_name: "swetha_raj@outlook.com",
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setNotificationMessage(response?.data.message.message);
        const length = response?.data?.message?.message?.length || 0;
        setNotificationCount(length);
        localStorage.setItem(
          "NotificationMessage",
          JSON.stringify(response?.data.message.message)
        );
        localStorage.setItem("NotificationCount", length);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  // Change data formet start
  const formatDate = (inputDate) => {
    // Split the date string into day, month, year
    const [day, month, year] = inputDate.split("-");
    // Create a new Date object from the components (month is zero-based, so we subtract 1)
    const date = new Date(year, month - 1, day);
    // Format the date using toLocaleDateString with options
    const options = { day: "numeric", month: "short", year: "numeric" }; // e.g., 25 Sep 2024
    return date.toLocaleDateString("en-US", options);
  };
  // Change date formet end

  //View more option

  const [isExpanded, setIsExpanded] = useState(false);

  // Number of characters to show initially
  const initialContentLength = 100;

  // Toggle view more/less
  const toggleViewMore = () => {
    setIsExpanded(!isExpanded);
  };

  //Notification Delete dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [SelectNotification, setSelectNotification] = useState({});

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const clickDelete = (item, event) => {
    event.stopPropagation(); // Prevents handleClose1 from propagating to parent
    handleClick(); // Call handleClose1 for individual notification
    setSelectNotification(item);
    setIsDialogOpen(true);
  };

  const confirmDelete = () => {
    // Perform delete action here
    console.log("Item deleted");
    closeDialog();

    DeleteAPICall();
  };

  // Service Page API call
  const DeleteAPICall = async () => {
    setLoading(true);
    const response = await notificationDelete({
      user_name: UserInfoData.user_email,
      notification_id: SelectNotification.id,
      delete: 1,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        NotificationDetails();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const longText = `This is a long text that will initially be truncated. 
  When you click the "View More" button, the full content will be revealed. 
  Clicking "View Less" will collapse it again. This pattern is useful for 
  managing large blocks of text without overwhelming users.`;

  const initialVisibleLabels = 6; // Number of labels to show initially

  const openWebsite = (url) => {
    // Check if the URL already includes a protocol
    const websiteUrl =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `https://${url}`;

    window.open(websiteUrl, "_blank");
  };

  const callClick = () => {
    openWebsite("https://calendly.com/theprevc/30min");
  };

  return (
    <div>
      <div className=" hidden lg:flex w-full h-16 ">
        <div className="w-1/2 p-4 justify-start items-center flex mt-1 ">
          {isShowing ? (
            <BackIcon
              className="w-6 h-6 flex-shrink-0 cursor-pointer mr-2"
              onClick={onClick}
            />
          ) : (
            <div></div>
          )}

          <Titletext label={title} />
        </div>

        <div className="w-1/2 p-4 justify-end items-center flex mt-1 ">
          {UserInfoData.user_type === "Investor" ||
          notShowsubscription ||
          !UserInfoData.subscription_status ? (
            <div></div>
          ) : (
            <div
              className={`h-9 hidden 3xl:h-9 rounded-full bg-mButtonColor items-center justify-center px-4 py-2  ${
                mClickstatus ? "cursor-not-allowed" : "cursor-pointer"
              } `}
              onClick={() => SubscriptionClick()}
            >
              <CrownIcon className="w-6 h-6 3xl:w-6 3xl:h-6 flex-shrink-0 cursor-pointer" />
              <p className="ml-2 font-PlusJakartaSansSemiBold text-white text-base 3xl:text-lg">
                {" "}
                {t("Subscription")}{" "}
              </p>
              <p className="ml-2 font-PlusJakartaSansSemiBold text-white text-base">
                {" "}
              </p>
            </div>
          )}

          {isCallBtn ? (
            <div
              className="mr-5  bg-mButtonColor px-4 py-2 justify-center items-center rounded-md cursor-pointer"
              onClick={() => {
                callClick();
              }}
            >
              <p className="text-white text-base 3xl:text-lg font-PlusJakartaSansSemiBold">
                Raise Capital
              </p>
            </div>
          ) : (
            <div></div>
          )}

          {balancedeckstatus ? (
            <div className="mr-5">
              <p className="font-PlusJakartaSansMedium text-mTextColor text-sm  xl:text-base 3xl:text-lg ">
                Remaning Deck : {balancedeck}
              </p>
            </div>
          ) : (
            <div></div>
          )}

          <div
            className={`w-11 h-11   justify-center items-center flex relative ml-2 ${
              mClickstatus ? "cursor-not-allowed" : "cursor-pointer"
            }`}
            onClick={() => NotificationClick()}
          >
            <NotificationIcon className=" w-7 h-7 3xl:w-7 3xl:h-7" />
            {/* {notificationCount > 0 && (
            
            )} */}
            <span className="absolute top-0 right-0 inline-flex items-center justify-center font-PlusJakartaSansRegular px-1.5 py-1 leading-none text-white text-xs bg-mButtonColor rounded-full">
              {notificationCount}
            </span>
          </div>
          <div
            onClick={toggleMenu}
            className={`${
              mClickstatus ? "cursor-not-allowed" : "cursor-pointer"
            }`}
          >
            {UserInfoData.image_url.length === 0 ? (
              <img
                src={Images.profileimage}
                alt="Profile"
                className="w-11 h-11 rounded-full ml-3 "
              />
            ) : (
              <img
                src={UserInfoData.image_url}
                alt="Profile"
                className="w-11 h-11 rounded-full ml-3 "
              />
            )}
          </div>
        </div>
      </div>

      <div className=" flex lg:hidden w-full h-16 ">
        <div className="w-1/2 p-4 justify-start items-center flex mt-1">
          {isShowing ? (
            <BackIcon
              className="w-6 h-6 flex-shrink-0 cursor-pointer mr-2"
              onClick={onClick}
            />
          ) : (
            <div></div>
          )}

          <Titletext label={title} />
        </div>

        <div className="w-1/2 p-4 justify-end items-center flex mt-1">
          {isCallBtn ? (
            <div
              className="mr-2 justify-center items-center cursor-pointer"
              onClick={() => {
                callClick();
              }}
            >
              <img src={Images.phonecall} alt="Profile" className="w-9 h-8 " />
            </div>
          ) : (
            <div></div>
          )}

          <div
            className="w-11 h-11 justify-center items-center flex relative ml-2 cursor-pointer"
            onClick={() => NotificationClick()}
          >
            <NotificationIcon className=" w-7 h-7" />
            {notificationCount > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center font-PlusJakartaSansRegular px-2 py-1 leading-none text-white text-xs bg-mButtonColor rounded-full">
                {notificationCount}
              </span>
            )}
          </div>
          <div onClick={toggleMenu}>
            {UserInfoData.image_url.length === 0 ? (
              <img
                src={Images.profileimage}
                alt="Profile"
                className="w-11 h-11 rounded-full ml-3 object-cover"
              />
            ) : (
              <img
                src={UserInfoData.image_url}
                alt="Profile"
                className="w-11 h-11 rounded-full ml-3 object-cover"
              />
            )}
          </div>
        </div>
      </div>

      <div className="w-full h-px mt-1 bg-mBorderColor"></div>

      <div className="relative flex justify-center items-center ">
        {/* Button to show the notification */}

        {/* Notification Popup Dialog */}
        {showPopup && (
          <div
            className="relative"
            onClick={handleClose} // Close the dialog by clicking outside
          >
            <div className="fixed inset-0 flex justify-end items-start h-full bg-black bg-opacity-50 z-40 px-2">
              <div
                className="bg-white rounded-lg shadow-lg max-w-md mt-16 lg:mr-5"
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dialog content
              >
                <div className="flex justify-between items-center p-4">
                  <DashboardTitle label={t("Notification")} />
                  <button
                    onClick={handleClose} // Close when clicking the close button
                    className="text-gray-500 hover:text-gray-700 w-5 h-5"
                  >
                    <CloseIcon className="w-5 h-5 3xl:w-5 3xl:h-5" />
                  </button>
                </div>

                <div className="w-full h-px bg-mBorderColor"></div>

                <div
                  className="max-h-[600px] lg:min-w-96 min-h-[400px] overflow-auto relative"
                  onClick={handleClick} // Trigger handleClose1 on this section click
                >
                  {notificationmessage.map((item, index) => (
                    <div
                      key={item.id}
                      className={`${
                        index % 2 === 0 ? "bg-mshadowColor" : "bg-white"
                      } p-4 grid scroll-y grid-cols-1 gap-6`}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents handleClose1 from propagating to parent
                        handleClick(); // Call handleClose1 for individual notification
                      }}
                    >
                      <div>
                        <div className="justify-between flex">
                          <DashboardTitle label={item.service_name} />
                          <img
                            src={Images.delete}
                            alt="Profile"
                            onClick={(e) => {
                              e.stopPropagation();
                              clickDelete(item, e);
                            }}
                            className="w-5 h-5 cursor-pointer"
                          />
                        </div>

                        <p className="font-PlusJakartaSansRegular text-sm 3xl:text-sm text-mTextColorLogin mt-2">
                          {item.service_message}
                        </p>

                        <div>
                          <p className="font-PlusJakartaSansRegular text-right text-sm 3xl:text-sm text-mTextColorLogin mt-3">
                            {formatDate(item.notification_date)}{" "}
                            {item.notification_time}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <DeleteDialog
                  isOpen={isDialogOpen}
                  onCancel={closeDialog}
                  onConfirm={confirmDelete}
                  title={"Delete Confirmation"}
                  message={" Are you sure you want to delete this item? "}
                />
              </div>
            </div>
          </div>
        )}

        {isOpen && (
          <div
            className="origin-top-right absolute  z-10 right-1 mt-12  rounded-xl px-5 py-3 border border-mGreyThree  bg-white "
            ref={menuRef}
          >
            <div
              className=" flex justify-center items-center cursor-pointer px-5 py-2"
              onClick={mLogout}
            >
              <LogoutIcon className="w-5 h-5 3xl:w-5 3xl:h-5 mt-2" />
              <p className="font-PlusJakartaSansRegular text-base 3xl:text-base text-mTextColor ml-2 h-5">
                {t("logout")}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderMenu;
