import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";
import { ReactComponent as UploadIcon } from "../../assets/Images/ic_upload.svg";
import TextView from "../../Components/TextView";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import DashboardTitle from "../../Components/DashboardTitle";
import { mCreateServices, paymentordercreate } from "../../Services/allAPI";
import { toast } from "react-toastify";
import Loader from "../../Utils/Loader";

const ServiceviewDialog = ({ show, onConfirm, onCancel, service }) => {
  const { t } = useTranslation("");
  const [valutname, setValutName] = useState("");
  const [description, setDescription] = useState("");
  const isDisabled = !valutname || !description;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const CreateService = () => {
    //  CreateServiceAPICall();
    handlePayment();
  };
  // Call your Python backend to create an order
  const handlePayment = async () => {
    var price = service.service_price * 100;
    const response = await paymentordercreate({
      amount: price,
    });

    if (response.status === 200) {
      try {
        // Configure Razorpay options
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Razorpay API key
          amount: response?.data.message.order_id.amount,
          currency: response?.data.message.order_id.currency,
          name: "theprevc",
          description:
            UserInfoData.user_name + " Purchase the Service " + service.id,
          order_id: response?.data.message.order_id.id,
          handler: function (paymentresponse) {
            // Handle successful payment here
            console.log(JSON.stringify(paymentresponse));
            console.log(response?.data.message.order_id.amount);
            console.log("Payment Success:", JSON.stringify(paymentresponse));
            CreateServiceAPICall(
              response?.data.message.order_id.amount,
              paymentresponse.razorpay_payment_id,
              paymentresponse.razorpay_order_id
            );
          },
          prefill: {
            name: UserInfoData.user_name,
            email: UserInfoData.user_email,
            //  contact: "9999999999",
          },
          theme: {
            color: "#2A7A5A",
          },
        };

        // Open Razorpay checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } catch (error) {
        console.error("Payment error:", error);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  // Service Page API call
  const CreateServiceAPICall = async (Amount, paymentId, orderId) => {
    setLoading(true);
    const response = await mCreateServices({
      user_name: UserInfoData.user_email,
      service_id: service.id,
      payment_transaction_id: paymentId,
      amount: Amount / 100,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        onConfirm();
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const dialogRef = useRef();

  // Close the dialog when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        onCancel(); // Call onClose if the click is outside the dialog
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, onCancel]);

  if (!show) return null; // Don't render if the dialog is not open

  return (
    <>
      {loading ? (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  z-50">
          {/* max-w-sm */}
          <div
            className="bg-mDashboardBg rounded-lg max-h-[50%] mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4"
            ref={dialogRef}
          >
            <Loader />
          </div>
        </div>
      ) : (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  z-50">
          {/* max-w-sm */}
          <div
            className="bg-mDashboardBg rounded-lg max-h-[90%] mx-2 overflow-auto shadow-xl w-full md:w-2/5 lg:w-1/3 p-4"
            ref={dialogRef}
          >
            <div className="justify-between flex">
              <div>
                <p className="font-PlusJakartaSansSemiBold text-lg text-mTextColor">
                  {service.service_name}
                </p>
              </div>

              <div>
                <button onClick={onCancel}>
                  <CloseIcon className="mb-4 " />
                </button>
              </div>
            </div>

            <div>
              <p className="font-PlusJakartaSansRegular text-base text-mTextColor">
                {t("Description")}
              </p>

              <p className="font-PlusJakartaSansRegular text-sm text-mTextColor mt-1 leading-6 line-clamp-10 min-h-16">
                {service.description}
              </p>
            </div>

            <div className="mt-10 w-full justify-between flex h-14 ">
              <div className="justify-center flex items-center">
                <DashboardTitle
                  label={t("rupess") + " " + service.pricing_format}
                />
              </div>

              <div className="w-40">
                <PrimaryButton
                  label={t("Proceed")}
                  onClick={CreateService}
                ></PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceviewDialog;
