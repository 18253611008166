import React from "react";
import { useTranslation } from "react-i18next";
import DashboardTitle from "../../Components/DashboardTitle";
import { ReactComponent as EditIcon } from "../../assets/Images/ic_edit.svg";
import { ReactComponent as UserIcon } from "../../assets/Images/ic_user.svg";
import { ReactComponent as GenderIcon } from "../../assets/Images/ic_gender.svg";
import { ReactComponent as MobileIcon } from "../../assets/Images/ic_mobile.svg";
import { ReactComponent as MailIcon } from "../../assets/Images/ic_mail.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/Images/ic_linkedin.svg";
import TextView from "../../Components/TextView";
import TextViewlight from "../../Components/TextViewlight";

const Profileinfo = ({
  mEditclick,
  mEditAboutclick,
  getprofileInformation,
}) => {
  const { t } = useTranslation("");

  const openWebsite = (url) => {
    // Check if the URL already includes a protocol
    const websiteUrl =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `https://${url}`;

    window.open(websiteUrl, "_blank");
  };
  return (
    <>
      {getprofileInformation.length > 0 ? (
        <div>
          <div className="bg-white rounded-md lg:rounded-xl p-2 lg:p-6">
            <div className="grid grid-cols-1 gap-5">
              <div className="h-9 justify-between flex ">
                <div className="justify-center items-center flex">
                  <DashboardTitle label={t("PersonalInformation")} />
                </div>
                <div
                  className="h-7 lg:h-9 ml-3 lg:ml-6 p-2 lg:p-4 bg-mButtonColorligth rounded-md lg:rounded-lg items-center justify-center flex cursor-pointer"
                  onClick={mEditclick}
                >
                  <EditIcon className="w-5 h-5 3xl:w-5 3xl:h-5 flex-shrink-0 cursor-pointer" />
                  <p className="ml-2 font-PlusJakartaSansRegular text-mButtonColor text-base 3xl:text-base">
                    {t("Edit")}
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <UserIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("FullName")} />
                  </div>
                </div>

                <div className="w-full md:w-3/4 ml-10">
                  <TextViewlight label={getprofileInformation[0].full_name} />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <GenderIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("Gender")} />
                  </div>
                </div>

                <div className="w-full md:w-3/4 ml-10">
                  <TextViewlight label={getprofileInformation[0].gender} />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <MobileIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("MobileNumber")} />
                  </div>
                </div>

                <div className="w-full md:w-3/4 ml-10">
                  <TextViewlight label={getprofileInformation[0].mobile_no} />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <MailIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("MailID")} />
                  </div>
                </div>

                <div className="w-full md:w-3/4 ml-10">
                  <TextViewlight label={getprofileInformation[0].mail_id} />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <LinkedinIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("LinkedIn")} />
                  </div>
                </div>

                <div
                  className="w-full md:w-3/4 ml-10"
                  onClick={() => {
                    openWebsite(getprofileInformation[0].linkedin);
                    //window.open(getprofileInformation[0].linkedin, "_blank");
                  }}
                >
                  <p className="text-blue-500 underline hover:text-blue-700 font-PlusJakartaSansRegular font-normal leading-7 cursor-pointer text-sm md:text-base xl:text-lg 3xl:text-lg">
                    {getprofileInformation[0].linkedin}
                  </p>
                  {/* <TextViewlight label={getprofileInformation[0].linkedin} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-xl p-6 mt-6">
            <div className="grid grid-cols-1 gap-5">
              <div className="h-9 justify-between flex ">
                <div className="justify-center items-center flex">
                  <DashboardTitle label={t("About")} />
                </div>
                <div
                  className="h-7 lg:h-9 ml-3 lg:ml-6 p-2 lg:p-4 bg-mButtonColorligth rounded-lg items-center justify-center flex cursor-pointer"
                  onClick={mEditAboutclick}
                >
                  <EditIcon className="w-5 h-5 3xl:w-5 3xl:h-5  flex-shrink-0 cursor-pointer" />
                  <p className="ml-2 font-PlusJakartaSansRegular text-mButtonColor text-base 3xl:text-base">
                    {t("Edit")}
                  </p>
                </div>
              </div>
              <div>
                <TextViewlight label={getprofileInformation[0].about} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Profileinfo;
