import React from "react";

// Custom button

const SecondaryButton = ({ label, onClick, onDisable }) => {
  return (
    <button
      className={` text-mTextColor text-base p-4 rounded-lg w-full h-11 justify-center items-center flex bg-mMenuSelect mx-auto font-PlusJakartaSansSemiBold `}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default SecondaryButton;
