import React, { useState, useRef, useEffect } from "react";

const ReadMoreText = ({ text }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (textRef.current) {
      // Check if the text overflows
      setIsOverflowing(
        textRef.current.scrollHeight > textRef.current.clientHeight
      );
    }
  }, [text]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="font-PlusJakartaSansRegular break-words leading-6 text-mTextColor text-sm">
      {/* Display two lines only */}
      <p ref={textRef} className="line-clamp-2 overflow-hidden">
        {text}
      </p>

      {/* "Read More" button */}

      {isOverflowing && (
        <button
          onClick={openModal}
          className="text-blue-500 font-PlusJakartaSansRegular break-words leading-6  text-sm mt-2"
        >
          Read More
        </button>
      )}

      {/* <button
        onClick={openModal}
        className="text-blue-500 font-PlusJakartaSansRegular break-words leading-6  text-sm mt-2"
      >
        Read More
      </button> */}

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full mx-4">
            <p className="font-PlusJakartaSansRegular text-mTextColor mb-6">
              {text}
            </p>
            <button
              onClick={closeModal}
              className="bg-blue-500 font-PlusJakartaSansRegular text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReadMoreText;
