import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Utils/Loader";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../assets/Images/ic_back.svg";
import { ReactComponent as EmailIcon } from "../../assets/Images/ic_email.svg";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { Images, isValidEmail } from "../../Utils/Constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { mSendotptomail } from "../../Services/allAPI";
import Headingtext from "../../Components/Headingtext";
import TextView from "../../Components/TextView";
import LogoText from "../../Components/LogoText";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  //Send OTP Click

  const sendOTPClick = () => {
    if (!isValidEmail(email)) {
      toast.error(t("vaildmail"));
      return;
    }
    ForgetPwdCall();
  };

  const ForgetPwdCall = async () => {
    // navigate("/resetpasswordotp");
    setLoading(true);
    const response = await mSendotptomail({
      email: email,
    });

    if (response.status === 200) {
      setLoading(false);
      toast.success(response?.data.message.message);
      if (response?.data.message.status) {
        navigate("/resetpasswordotp", {
          state: {
            mEmail: email,
          },
        });
        //  navigate("/resetpasswordotp");
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const mBackClick = () => {
    navigate("/login");
  };

  // Check Disable button
  const isDisabled = !email;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="min-h-screen flex flex-row">
          <div className="hidden lg:block w-3/5 bg-mwhite  flex-col items-start justify-start p-5">
            <div className="mt-3 ml-3">
              {/* <img
                src={Images.zenpivot}
                alt="google Icon"
                className=" h-9 w-28"
              /> */}
              <LogoText label={t("appname")} />
            </div>

            <div className="w-full h-auto justify-center items-center flex">
              <img src={Images.forgetpwd} alt="login" className="w-2/3 " />
            </div>
          </div>
          <div className=" lg:w-2/5 w-full pl-5 py-5 lg:pl-20 lg:py-10 bg-mBackground items-center justify-start flex ">
            <div className="grid gap-10 grid-cols-1 w-11/12">
              <div className="flex flex-row items-center justify-start">
                <BackIcon
                  className="w-6 h-6  flex-shrink-0 cursor-pointer mr-2"
                  onClick={mBackClick}
                />{" "}
                <p className="font-PlusJakartaSansRegular font-normal text-sm 3xl:text-base">
                  {t("GoBack")}
                </p>
              </div>
              <div>
                <Headingtext label={t("ResetPassword")} />
              </div>
              <div>
                <TextView label={t("EMail")} ismandatory={true} />

                <InputContainer
                  type="email"
                  value={email}
                  placeholder={t("Enteryouremail")}
                  label="email"
                  name="email"
                  disabled={false}
                  maxLength={60}
                  showIcon={true}
                  Icon={EmailIcon}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    // setforgetPwd({ ...forgetPwd, email: event.target.value });
                  }}
                />
              </div>
              <PrimaryButton
                label={t("SendOtp")}
                onClick={sendOTPClick}
                onDisable={isDisabled}
              ></PrimaryButton>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ResetPassword;
