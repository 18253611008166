import React from "react";
// Small App Logo in text formet
const LogoTextsmall = ({ label }) => (
  <div className="flex">
    <p className="text-[28px] font-semibold leading-normal font-HelveticaNowDisplayBold text-mTextColor h-[38px]">
      vc
    </p>
    <p className="text-[28px] font-semibold leading-normal font-HelveticaNowDisplayBold text-mButtonColor h-[38px]">
      .
    </p>
  </div>
);

export default LogoTextsmall;
