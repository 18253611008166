import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { mGetallMaster } from "../../Services/allAPI";
import { toast } from "react-toastify";

export const fetchMasterData = createAsyncThunk("masterSlice", async () => {
  // Call Master data
  const response = await mGetallMaster();
  if (response.status === 200) {
    if (response?.data.message.status) {
      return response?.data.message;
    } else {
      return response?.data.message.status;
    }
  } else {
    const errorMessage = response.data?.message || "Server Error";
    toast.error(errorMessage);
    console.log("Error");
  }
});

// Create Master list Slice
const masterSlice = createSlice({
  name: "masterSlice",
  initialState: {
    MasterdataList: {},
    status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMasterData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMasterData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.MasterdataList = action.payload.master_data;
      })
      .addCase(fetchMasterData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.status;
      });
  },
});

export default masterSlice.reducer;
