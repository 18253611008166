import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import "./App.css";
import reportWebVitals from "./reportWebVitals";
import "./Utils/IMLocalize";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./Screens/Dashboard/Dashboard";
import Deckdetails from "./Screens/DeckDetails/Deckdetails";

import { Provider } from "react-redux";
import store from "./redux/Store";
import PrivateRoute from "./Services/PrivateRoute";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ResetPassword from "./Screens/ResetPassword/ResetPassword";
import ResetPasswordOTP from "./Screens/ResetPassword/ResetPasswordOTP";
import Login from "./Screens/Login/Login";
import Signup from "./Screens/Signup/Signup";
import Uploadpicthdeck from "./Screens/Onboarding/Uploadpicthdeck";
import Deckscore from "./Screens/Onboarding/Deckscore";
import MyDecks from "./Screens/MyDecks/MyDecks";
import ServicePage from "./Screens/ServicePage/ServicePage";
import ValutPage from "./Screens/Valut/ValutPage";
import ValutDetails from "./Screens/Valut/ValutDetails";
import InvestorsCRM from "./Screens/StartupCRM/StartupCRM";
import Subscription from "./Screens/Subscription/Subscription";
import ProfilePage from "./Screens/Profile/ProfilePage";
import HelpSupport from "./Screens/Help/HelpSupport";
import Zenchat from "./Screens/ZenChat/Zenchat";
import Question from "./Screens/Onboarding/Question";
import SuggestingInvestors from "./Screens/StartupCRM/SuggestingStartupInvestors";
import InvestorDashboard from "./Screens/Dashboard/InvestorDashboard";
import StartupCRM from "./Screens/StartupCRM/StartupCRM";
import SuggestingStartupInvestors from "./Screens/StartupCRM/SuggestingStartupInvestors";
import InvestorCRM from "./Screens/InvestorsCRM/InvestorCRM";

const root = ReactDOM.createRoot(document.getElementById("root"));

// August 14th, 2024 6:00 PM

root.render(
  // <React.StrictMode>
  // <React.StrictMode>
  <GoogleOAuthProvider clientId="418560784218-cooa4gvsddrbkar0e832ulnolobl4rvf.apps.googleusercontent.com">
    <Provider store={store}>
      <Router>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          {/* Home Page */}
          <Route path="/" element={<Uploadpicthdeck />} />

          {/* Login & Signup */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/resetpasswordotp" element={<ResetPasswordOTP />} />

          {/* <Route path="/deckupload" element={<Uploadpicthdeck />} /> */}

          {/* <Route path="/chat" element={<Zenchat />} /> */}

          <Route element={<PrivateRoute />}>
            {/* Onboarding Flow */}
            <Route path="/deckscore" element={<Deckscore />} />
            <Route path="/question" element={<Question />} />
            <Route
              path="/suggestedinvestors"
              element={<SuggestingStartupInvestors />}
            />

            {/* Dashboard */}
            <Route path="/dashboard" element={<Dashboard />} />

            {/* Investor Dashboard */}
            <Route path="/investordashboard" element={<InvestorDashboard />} />

            {/* Review Deck */}
            <Route path="/mydecks" element={<MyDecks />} />
            <Route path="/deckdetails" element={<Deckdetails />} />

            {/* Service */}
            <Route path="/service" element={<ServicePage />} />

            {/* Valut Page */}
            <Route path="/valut" element={<ValutPage />} />
            <Route path="/valutdetails" element={<ValutDetails />} />

            {/* Investors CRM */}
            <Route path="/startupcrm" element={<StartupCRM />} />

            {/* Profile */}
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/help" element={<HelpSupport />} />

            {/* Chat */}
            <Route path="/chat" element={<Zenchat />} />

            {/* Investor CRM */}
            <Route path="/investorscrm" element={<InvestorCRM />} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  </GoogleOAuthProvider>
  // {/* </React.StrictMode> */}
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
