import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DashboardTitle from "../../Components/DashboardTitle";
import Menutitletext from "../../Components/Menutitletext";
import MultiRangeSlider from "../../Components/MultiRangeSlider";
import Menutext from "../../Components/Menutext";
import { Images, isEmptyObject } from "../../Utils/Constants";
import {
  mGetDeals,
  mGetDealsShortlist,
  mgetInvestordb,
  mInvestordb,
  mInvestorShortlist,
} from "../../Services/allAPI";
import { toast } from "react-toastify";
import Loader from "../../Utils/Loader";
import { ReactComponent as DottedIcon } from "../../assets/Images/ic_dotted.svg";
import { ReactComponent as TickIcon } from "../../assets/Images/ic_tickgray.svg";
import { ReactComponent as UserIcon } from "../../assets/Images/ic_user.svg";
import Titletext from "../../Components/Titletext";
import PrimaryButton from "../../Components/PrimaryButton";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";
import DealsDetails from "./DealsDetails";

const Deals = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [rangeValues, setRangeValues] = useState([0, 1000]);
  const [dealsdb, setdealsdb] = useState([]);
  const menuRef = useRef(null);
  const [ShowInvestorDetails, setShowInvestorDetails] = useState(false);
  const [ShowInvestorItem, setShowInvestorItem] = useState({});
  const [showDialog, setshowDialog] = useState(false);
  const [checkSizeMin, setcheckSizeMin] = useState(0);
  const [checkSizeMax, setcheckSizeMax] = useState(1000);

  //alert(onShowDialog);

  // Filter Function Start

  //Filter Function  End

  // Slider Range select
  const handleRangeChange = (values) => {
    // setRangeValues(values);
    setcheckSizeMin(values.min);
    setcheckSizeMax(values.max);
    console.log("Selected " + values.min + "  " + values.max);
  };

  // Close the menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  //Get suggested Investor

  useEffect(() => {
    ServicePageAPICall();
  }, []);

  // Service Page API call
  const ServicePageAPICall = async () => {
    setLoading(true);
    const response = await mGetDeals({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setdealsdb(response?.data.message.investor_crm);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };

  const clickShortlist = (item, event) => {
    setActiveMenu(null);
    ShortlistAPICall(item.id);
  };

  const clickViewInvestor = (item, event) => {
    setActiveMenu(null);
    setShowInvestorDetails(true);
    setShowInvestorItem(item);
  };

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  // Shortlist API Call
  const ShortlistAPICall = async (id) => {
    setLoading(true);
    const response = await mGetDealsShortlist({
      user_name: UserInfoData.user_email,
      crm_id: id,
      status: "Deal",
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        ServicePageAPICall();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const clickRefresh = () => {
    setShowInvestorDetails(false);
    ServicePageAPICall();
  };

  const clickClose = () => {
    setShowInvestorDetails(false);
  };

  // Investor Type Filter start
  const InvestorType = [
    { id: 1, name: "Angel Investor" },
    { id: 2, name: "Venture Capital" },
  ];

  // State to keep track of selected checkboxes
  const [selectedInvestorType, setSelectedInvestorType] = useState([]);

  // Handle checkbox change
  const handleInvestorTypeCheckbox = (id) => {
    setSelectedInvestorType((prevSelected) => {
      if (prevSelected.includes(id)) {
        // Remove from selected if already selected
        return prevSelected.filter((filterId) => filterId !== id);
      } else {
        // Add to selected if not already selected
        return [...prevSelected, id];
      }
    });
  };

  // Display the selected filters
  var selectedFilterNames = InvestorType.filter((filter) =>
    selectedInvestorType.includes(filter.id)
  ).map((filter) => filter.name);

  // Investor Type Filter End

  // stage Filter Start
  const stage = [
    { id: 11, name: "Pre Seed" },
    { id: 12, name: "Seed" },
    { id: 13, name: "Series A" },
    { id: 14, name: "Series B" },
    { id: 15, name: "Pre Seed" },
    { id: 16, name: "Seed" },
    { id: 17, name: "Series A" },
    { id: 18, name: "Series B" },
  ];

  // State to keep track of selected checkboxes
  const [selectedStage, setSelectedStage] = useState([]);

  // Handle checkbox change
  const handleStageCheckbox = (id) => {
    setSelectedStage((prevSelected) => {
      if (prevSelected.includes(id)) {
        // Remove from selected if already selected
        return prevSelected.filter((filterId) => filterId !== id);
      } else {
        // Add to selected if not already selected
        return [...prevSelected, id];
      }
    });
  };

  // Display the selected filters
  var selectedStageNames = stage
    .filter((filter) => selectedStage.includes(filter.id))
    .map((filter) => filter.name);

  // stage Filter End

  const filterClick = () => {
    console.log(checkSizeMin);
    console.log(checkSizeMax);
    console.log(selectedFilterNames);
    console.log(selectedStageNames);
  };

  const clearall = () => {
    setSelectedStage([]);
    setSelectedInvestorType([]);

    if (childRef.current) {
      childRef.current.triggerButtonClick(); // Call method exposed by child
    }
  };

  const childRef = useRef(null);

  const onCancel = () => {
    setshowDialog(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="h-full">
          <div className="w-full h-full flex flex-row ">
            {/* <div className="hidden  w-1/5 h-full bg-white rounded-lg p-4 overflow-auto scrollbar-hide">
              <div className=" grid grid-cols-1 gap-7">
                <div className="flex flex-row justify-between ">
                  <div className="justify-center items-center flex">
                    <DashboardTitle label={t("Filter")} />
                  </div>
                  <div
                    className="justify-center items-center flex cursor-pointer"
                    onClick={clearall}
                  >
                    <p className="font-PlusJakartaSansRegular text-mButtonColor text-xs">
                      {t("Clearall")}
                    </p>
                  </div>
                </div>
                <div>
                  <Menutitletext label={t("CheckSize")} />
                  <div
                    className="flex flex-col items-center w-full mt-2
                                  "
                  >
                    <div className="mt-3 relative">
                      <MultiRangeSlider
                        min={0}
                        max={1000}
                        ref={childRef}
                        onChange={handleRangeChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full h-px bg-mBorderColor"> </div>
                <div>
                  <Menutitletext label={t("InvestorType")} />
                  <div>
                    <div className="mt-5 grid grid-cols-1 gap-5">
                      {InvestorType.map((item) => (
                        <div className="flex flex-col" key={item.id}>
                          <div className="flex items-center justify-start ">
                            <label className="text-sm text-mTextColorLogin font-PlusJakartaSansRegular  items-center justify-start flex">
                              <input
                                type="checkbox"
                                value={item.id}
                                checked={selectedInvestorType.includes(item.id)}
                                onChange={() =>
                                  handleInvestorTypeCheckbox(item.id)
                                }
                                className="h-4 w-4 mr-2 font-PlusJakartaSansRegular  items-center justify-start text-mTextColor text-sm"
                              />
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="w-full h-px bg-mBorderColor"> </div>
                <div>
                  <Menutitletext label={t("Stage")} />
                  <div className="mt-5 grid grid-cols-1 gap-5">
                    {stage.map((item) => (
                      <div className="flex flex-col" key={item.id}>
                        <div className="flex items-center justify-start ">
                          <label className="text-sm text-mTextColorLogin font-PlusJakartaSansRegular  items-center justify-start flex">
                            <input
                              type="checkbox"
                              value={item.id}
                              checked={selectedStage.includes(item.id)}
                              onChange={() => handleStageCheckbox(item.id)}
                              className="h-4 w-4 mr-2 font-PlusJakartaSansRegular  items-center justify-start text-mTextColor text-sm"
                            />

                            {item.name}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="w-full h-px bg-mBorderColor"> </div>

                <div className="">
                  <button
                    className={`  text-white text-base px-5 rounded-md w-full h-9 font-PlusJakartaSansSemiBold ${"bg-mButtonColor"}`}
                    onClick={filterClick}
                  >
                    {t("submit")}
                  </button>
                </div>
              </div>
            </div> */}

            {/* Filter popup start */}
            {/* <div className="hidden">
              {showDialog && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50  px-2 z-50">
                 
                  <div className="bg-mDashboardBg rounded-md overflow-auto scrollbar-hide shadow-xl w-full py-4 pl-8 pr-4 h-90">
                    <div className="justify-between flex flex-col ">
                      <div className="w-full justify-end items-end flex">
                        <button>
                          <CloseIcon className="" />
                        </button>
                      </div>
                      <div className=" grid grid-cols-1 gap-7 mt-4">
                        <div className="flex flex-row justify-between ">
                          <div className="justify-center items-center flex">
                            <DashboardTitle label={t("Filter")} />
                          </div>
                          <div
                            className="justify-center items-center flex cursor-pointer"
                            onClick={clearall}
                          >
                            <p className="font-PlusJakartaSansRegular text-mButtonColor text-xs">
                              {t("Clearall")}
                            </p>
                          </div>
                        </div>
                        <div>
                          <Menutitletext label={t("CheckSize")} />
                          <div
                            className="flex flex-col items-center w-full mt-2
                                  "
                          >
                            <div className="mt-3 relative">
                              <MultiRangeSlider
                                min={0}
                                max={1000}
                                ref={childRef}
                               
                                onChange={handleRangeChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-px bg-mBorderColor"> </div>
                        <div>
                          <Menutitletext label={t("InvestorType")} />
                          <div>
                            <div className="mt-5 grid grid-cols-1 gap-5">
                              {InvestorType.map((item) => (
                                <div className="flex flex-col" key={item.id}>
                                  <div className="flex items-center justify-start ">
                                    <label className="text-sm text-mTextColorLogin font-PlusJakartaSansRegular  items-center justify-start flex">
                                      <input
                                        type="checkbox"
                                        value={item.id}
                                        checked={selectedInvestorType.includes(
                                          item.id
                                        )}
                                        onChange={() =>
                                          handleInvestorTypeCheckbox(item.id)
                                        }
                                        className="h-4 w-4 mr-2 font-PlusJakartaSansRegular  items-center justify-start text-mTextColor text-sm"
                                      />
                                      
                                      {item.name}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="w-full h-px bg-mBorderColor"> </div>
                        <div>
                          <Menutitletext label={t("Stage")} />
                          <div className="mt-5 grid grid-cols-1 gap-5">
                            {stage.map((item) => (
                              <div className="flex flex-col" key={item.id}>
                                <div className="flex items-center justify-start ">
                                  <label className="text-sm text-mTextColorLogin font-PlusJakartaSansRegular  items-center justify-start flex">
                                    <input
                                      type="checkbox"
                                      value={item.id}
                                      checked={selectedStage.includes(item.id)}
                                      onChange={() =>
                                        handleStageCheckbox(item.id)
                                      }
                                      className="h-4 w-4 mr-2 font-PlusJakartaSansRegular  items-center justify-start text-mTextColor text-sm"
                                    />
                                    
                                    {item.name}
                                  </label>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="w-full h-px bg-mBorderColor"> </div>

                        <div className="">
                          <button
                            className={`  text-white text-base px-5 rounded-md w-full h-9 font-PlusJakartaSansSemiBold ${"bg-mButtonColor"}`}
                            onClick={filterClick}
                          >
                            {t("submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div> */}

            {/* Filter popup end */}

            <div className="w-full  h-full  lg:ml-5 overflow-auto scrollbar-hide">
              {dealsdb.length !== 0 ? (
                <div className="grid-containeritemsugginv">
                  {dealsdb.map((item) => (
                    <div
                      key={item.id}
                      className="grid-itemsugginv  bg-white p-3"
                    >
                      <div className=" grid grid-cols-1 gap-5">
                        <div className="p-4 flex flex-row justify-between">
                          <div
                            className="flex-row flex h-full w-full justify-center items-center"
                            onClick={() => clickViewInvestor(item)}
                          >
                            <img
                              src={Images.Pdf}
                              alt="Profile"
                              className="cursor-pointer w-11 h-11"
                            />
                            <div className="h-full flex w-full ml-2 flex-col">
                              <Menutext label={item.deal_name} />

                              <div className="mt-1">
                                <Menutitletext label={item.sector} />
                              </div>
                            </div>
                          </div>
                          <div>
                            <DottedIcon
                              className="w-6 h-6 flex-shrink-0 cursor-pointer"
                              onClick={() => toggleMenu(item.id)}
                            />
                          </div>
                        </div>

                        <div className="w-full h-px bg-mBorderColor"></div>

                        {/* <div className="grid grid-cols-3 gap-3 w-full ">
                          {item.funding_stages.map((stagesitem) => (
                            <div
                              className="p-2 rounded-md bg-mCardbgColor justify-center items-center flex"
                              key={stagesitem.id}
                            >
                              <p className="font-PlusJakartaSansRegular text-mTextColorLogin text-xs">
                                {stagesitem.funding_stages}
                              </p>
                            </div>
                          ))}
                        </div> */}
                        <p className="font-PlusJakartaSansRegular text-sm text-mTextColorLogin">
                          {" "}
                          {t("rupess") + " " + item.ask}{" "}
                        </p>
                      </div>

                      {activeMenu === item.id && (
                        <div
                          className="popup-menuValutdetails right-2 mt-2 w-40 bg-white p-3 rounded-xl shadow-xl shadow-mChatbg z-10"
                          ref={menuRef}
                        >
                          <ul>
                            <li
                              className="px-2 py-2 mt-2 cursor-pointer"
                              onClick={() => clickShortlist(item)}
                            >
                              <div className="flex flex-row justify-start items-center">
                                <TickIcon className="w-5 h-5 flex-shrink-0 " />
                                <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base ml-3">
                                  {t("Shortlist")}{" "}
                                </p>
                              </div>
                            </li>

                            <li
                              className="px-2 py-2 mt-2 cursor-pointer"
                              onClick={() => clickViewInvestor(item)}
                            >
                              <div className="flex flex-row justify-start items-center">
                                <UserIcon className="w-5 h-5 flex-shrink-0 " />
                                <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base ml-3">
                                  {t("ViewProfile")}{" "}
                                </p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="justify-center items-center mt-16  flex-col flex w-full h-full ">
                  <img
                    className="rounded-lg bg-white "
                    src={Images.nodata}
                    alt="review"
                  />
                  <div className="mt-10">
                    {" "}
                    <Titletext label={t("nodata")} />
                  </div>
                </div>
              )}
            </div>
          </div>
          {ShowInvestorDetails ? (
            <DealsDetails
              show={ShowInvestorDetails}
              onCancel={clickClose}
              clickRefresh={clickRefresh}
              getInvestors={ShowInvestorItem}
            />
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
  );
};

export default Deals;
