import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import Menutext from "./Menutext";
import { useTranslation } from "react-i18next";

const MultiRangeSlider = forwardRef(
  ({ min, max, onChange, handleButtonClick }, ref) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(min);
    const maxValRef = useRef(max);
    const range = useRef(null);
    const { t } = useTranslation("");

    const buttonRef = useRef(null);

    // Expose methods to parent component
    useImperativeHandle(ref, () => ({
      triggerButtonClick() {
        if (buttonRef.current) {
          buttonRef.current.click(); // Trigger button click
        }
      },
    }));

    const handleClick = () => {
      setMinVal(min);
      setMaxVal(max);
      minValRef.current = min;
      maxValRef.current = max;
    };

    // Convert to percentage
    const getPercent = useCallback(
      (value) => Math.round(((value - min) / (max - min)) * 100),
      [min, max]
    );

    handleButtonClick = () => {
      alert("Button in child component clicked!");
    };

    // Set width of the range to decrease from the left side
    useEffect(() => {
      const minPercent = getPercent(minVal);
      const maxPercent = getPercent(maxValRef.current);

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }, [minVal, getPercent]);

    // Set width of the range to decrease from the right side
    useEffect(() => {
      const minPercent = getPercent(minValRef.current);
      const maxPercent = getPercent(maxVal);

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
      onChange({ min: minVal, max: maxVal });
    }, [minVal, maxVal, onChange]);

    return (
      <div>
        <div className="w-full h-11  flex flex-row justify-between">
          <div className="justify-center items-center flex">
            <Menutext label={t("rupess") + " " + minVal} />
          </div>
          <div className="justify-center items-center flex">
            <Menutext label={t("rupess") + " " + maxVal} />
          </div>
        </div>

        <div className="container mt-3">
          <input
            type="range"
            min={min}
            max={max}
            value={minVal}
            onChange={(event) => {
              const value = Math.min(Number(event.target.value), maxVal - 1);
              setMinVal(value);
              minValRef.current = value;
            }}
            className="thumb thumb--left"
            style={{ zIndex: minVal > max - 100 && "5" }}
          />
          <input
            type="range"
            min={min}
            max={max}
            value={maxVal}
            onChange={(event) => {
              const value = Math.max(Number(event.target.value), minVal + 1);
              setMaxVal(value);
              maxValRef.current = value;
            }}
            className="thumb thumb--right"
          />

          <div className="slider">
            <div className="slider__track" />
            <div ref={range} className="slider__range" />
            {/* <div className="slider__left-value">{minVal}</div>
      <div className="slider__right-value">{maxVal}</div> */}
          </div>
        </div>
        <button ref={buttonRef} onClick={handleClick}></button>
      </div>
    );
  }
);

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiRangeSlider;
