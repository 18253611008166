import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import { Images } from "../../Utils/Constants";
import { mGetDashboard } from "../../Services/allAPI";
import { toast } from "react-toastify";

import Headingtext from "../../Components/Headingtext";

import HeaderMenu from "../../Components/HeaderMenu";
import DashboardTitle from "../../Components/DashboardTitle";
import Titletext from "../../Components/Titletext";
import { AlertDialog } from "../../Components/AlertDialog";
import Deckdetails from "../DeckDetails/Deckdetails";
import CreateNewVault from "../Valut/CreateNewVault";
import Deckdetailscopy from "../DeckDetails/DeckdetailsDialog";
import DeckdetailsDialog from "../DeckDetails/DeckdetailsDialog";

const MyDecks = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [dashboarddata, setDashboardData] = useState([]);

  const [balancedeck, setbalancedeck] = useState(0);
  const gridRef = useRef(null);
  const location = useLocation(); // For accessing the current location state
  const [selectedRow, setSelectedRow] = useState(null);
  const rowRefs = useRef([]); // Array of refs for each row
  const [scrollPosition, setScrollPosition] = useState(0);
  const [newValutShow, setnewValutShow] = useState(false);
  const [selectedDeck, setselectedDeck] = useState("");

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  //
  useEffect(() => {
    MyDecksAPICall();
  }, []);

  // Dashboard API call
  const MyDecksAPICall = async () => {
    setLoading(true);
    const response = await mGetDashboard({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setDashboardData(response?.data.message.deck_details);
        setbalancedeck(response?.data.message.balance_deck);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [Description, setDescription] = useState("");

  const handleItemClick = (mDeck, rowIndex) => {
    if (mDeck.deck_status) {
      setDescription(mDeck.deck_response);
      setIsAlertOpen(true);
    } else {
      setselectedDeck(mDeck.id);
      setnewValutShow(true);
      // navigate("/deckdetails", {
      //   state: {
      //     mDeck_Id: mDeck.id,
      //   },
      // });
    }
  };

  const clickClose = () => setnewValutShow(false);

  const closeAlert = () => setIsAlertOpen(false);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="flex h-screen overflow-hidden bg-no-repeat bg-cover">
            {/* Sidebar */}
            <SideMenu mFrom={1} mProfile={-1} />

            {/* Main content */}
            <div className="flex flex-col flex-grow overflow-hidden bg-mDashboardBg">
              {/* Top Navigation */}
              <HeaderMenu
                title={t("DeckUploader")}
                balancedeck={balancedeck}
                balancedeckstatus={true}
              />

              {/* Scrollable content */}
              <main className="flex-grow overflow-auto mt-2 mb-2 mr-2 p-4 md:p-4 lg:p-8 ">
                {dashboarddata.length !== 0 ? (
                  <div
                    className="grid sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 lg:gap-6 gap-4 w-full"
                    ref={gridRef}
                  >
                    {dashboarddata.map((item, i) => (
                      <div
                        key={item.id}
                        ref={(el) => (rowRefs.current[i] = el)}
                        className="shadow-sm"
                      >
                        <div
                          className={`rounded-lg p-4 bg-white cursor-pointer  ${
                            item.deck_status ? "blur-sm" : ""
                          } `}
                          onClick={() => handleItemClick(item, i)}
                        >
                          <p className="text-left font-PlusJakartaSansSemiBold text-lg">
                            {t("PitchDeck")} {i + 1}
                          </p>

                          {/* Image container */}
                          <div className="rounded-lg mt-4 justify-center items-center flex">
                            <img
                              alt="name"
                              src={item.deck_profile_image}
                              className="max-h-36 min-h-36 w-full object-cover rounded-lg"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="justify-center items-center flex-col flex w-full h-full">
                    <img
                      className="rounded-lg bg-white"
                      src={Images.nodata}
                      alt="review"
                    />
                    <div className="mt-10">
                      <Titletext label={t("nodata")} />
                    </div>
                  </div>
                )}
              </main>

              {newValutShow ? (
                <DeckdetailsDialog
                  show={newValutShow}
                  onDeckid={selectedDeck}
                  onCancel={clickClose}
                />
              ) : (
                <div></div>
              )}
            </div>

            <AlertDialog
              isOpen={isAlertOpen}
              onClose={closeAlert}
              message={Description}
              isShowDes={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default MyDecks;
