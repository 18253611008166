import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../Utils/Loader";
import SideMenu from "../../Components/SideMenu";
import { Images } from "../../Utils/Constants";
import {
  deleteVault,
  getVaultList,
  mGetDashboard,
} from "../../Services/allAPI";
import { toast } from "react-toastify";

import Headingtext from "../../Components/Headingtext";
import HeaderMenu from "../../Components/HeaderMenu";
import { ReactComponent as AddIcon } from "../../assets/Images/ic_add.svg";
import CreateNewVault from "./CreateNewVault";
import { ReactComponent as MenuIcon } from "../../assets/Images/ic_menu.svg";
import { ReactComponent as MenuEditIcon } from "../../assets/Images/ic_menuedit.svg";
import { ReactComponent as MenuDeleteIcon } from "../../assets/Images/ic_menudelete.svg";
import DeleteDialog from "../../Components/DeleteDialog";
import Titletext from "../../Components/Titletext";
import CreateNewFile from "./CreateNewFile";
import { UserAlertDialog } from "../../Components/UserAlertDialog";

const ValutPage = () => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [valutdata, setvalutdata] = useState([]);
  const [newValutShow, setnewValutShow] = useState(false);
  const menuRef = useRef(null);
  const [Valutitem, setValutitem] = useState({});

  // Close the menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  //Set user Alert Msg
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  //
  useEffect(() => {
    VaultListAPICall();
    if (!UserInfoData.view_status) {
      setIsAlertOpen(true);
    }
  }, []);

  //Set user Alert close
  const closeAlert = () => setIsAlertOpen(false);

  //Set user Alert reload page
  const Confirm = () => {
    setIsAlertOpen(false);
    window.location.reload();
  };
  // Service Page API call
  const VaultListAPICall = async () => {
    setLoading(true);
    const response = await getVaultList({
      user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setvalutdata(response?.data.message.vault_details);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const handleItemClick = (mDeck_Id) => {
    navigate("/deckdetails", {
      state: {
        mDeck_Id: mDeck_Id,
      },
    });
  };

  const newvalutClick = () => {
    setnewValutShow(true);
  };

  const newvalutCreate = () => {
    VaultListAPICall();
    setnewValutShow(false);
  };

  const clickClose = () => {
    setnewValutShow(false);
  };

  const [activeMenu, setActiveMenu] = useState(null);

  const menuClick = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };

  const clickEdit = (item, event) => {
    //alert(`Edit ${item.id}`);
    setActiveMenu(null);

    navigate("/valutdetails", {
      state: {
        mValut_Id: item.id,
      },
    });
  };
  const [newFileShow, setnewFileShow] = useState(false);
  const [selectedFiles, setselectedFiles] = useState({});
  const [isEdit, setisEdit] = useState(false);
  const [Title, setTitle] = useState(t("CreateNewFile"));
  const [buttonname, setButtonname] = useState(t("uploadnow"));
  const clickEditdetails = (item, event) => {
    // alert(`Edit ${item.Task}`);
    setselectedFiles(item);
    setActiveMenu(null);
    setnewFileShow(true);
    setisEdit(true);

    setTitle(t("EditDetails"));
    setButtonname(t("update"));
  };

  const newfileCreate = () => {
    VaultListAPICall();
    setnewFileShow(false);
  };

  const clickDelete = (item, event) => {
    setActiveMenu(null);
    setValutitem(item);
    setIsDialogOpen(true);
  };

  //Delete dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const confirmDelete = () => {
    // Perform delete action here
    console.log("Item deleted");

    ondeleteAPICall();
  };

  // Service Page API call
  const ondeleteAPICall = async () => {
    setLoading(true);
    const response = await deleteVault({
      user_name: UserInfoData.user_email,
      vault_id: Valutitem.id,
      delete: 1,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.message);
        closeDialog();
        VaultListAPICall();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const clickEditClose = () => {
    setnewFileShow(false);
  };

  // Delete dialog end

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="flex h-screen overflow-hidden bg-gray-100">
          {/* Sidebar */}
          <SideMenu mFrom={5} mProfile={-1} />

          {/* Main content */}
          <div className="flex flex-col flex-grow overflow-hidden ml-4 mr-4 mt-3">
            {/* Top Navigation */}
            <HeaderMenu title={t("Valut")} />

            {/* Scrollable content */}
            <main
              className={`flex-grow overflow-auto  mt-5 mb-3 scrollbar-hide ${
                !UserInfoData.view_status ? "blur-sm pointer-events-none" : ""
              }`}
            >
              {/* Adjusted padding top to avoid overlap */}

              <div className="w-full justify-between flex">
                <div className="h-9 items-center justify-center flex">
                  <p className="font-PlusJakartaSansSemiBold text-sm md:text-base xl:text-lg 3xl:text-lg text-mTextColor">
                    Vault
                  </p>
                </div>

                <div>
                  <div
                    className="h-8 md:h-9 3xl:h-9 rounded-lg bg-mButtonColor items-center justify-center flex p-2 md:p-3 cursor-pointer"
                    onClick={newvalutClick}
                  >
                    <AddIcon className="w-6 h-6 flex-shrink-0 " />
                    <p className="ml-2 font-PlusJakartaSansSemiBold text-white text-base">
                      {t("NewVault")}
                    </p>
                  </div>
                </div>
              </div>

              <div className="mt-5">
                {valutdata.length !== 0 ? (
                  <div className="grid-container">
                    {valutdata.map((item) => (
                      <div
                        key={item.id}
                        className=" grid-itemValut bg-white flex justify-start items-center w-full"
                      >
                        <div
                          className="w-[95%] flex justify-start items-center "
                          onClick={() => clickEdit(item)}
                        >
                          <div className="w-10 h-[40px]">
                            <img
                              src={Images.folderimage}
                              alt="Profile"
                              className="cursor-pointer"
                              onClick={() => clickEdit(item)}
                            />
                          </div>
                          <div className="ml-3">
                            <p className="font-PlusJakartaSansRegular text-mTextColor  text-sm md:text-base xl:text-lg 3xl:text-lg items-center justify-center flex">
                              {item.vault_name}
                            </p>
                          </div>
                        </div>
                        <div className="w-[5%] cursor-pointer items-center justify-center flex">
                          <MenuIcon
                            className="w-8 h-8 flex-shrink-0 "
                            onClick={() => menuClick(item.id)}
                          />
                        </div>

                        {activeMenu === item.id && (
                          <div
                            className="absolute  lg:right-0 mt-20 w-40 3xl:w-56 bg-white p-3 rounded-xl shadow shadow-mChatbg z-10"
                            ref={menuRef}
                          >
                            <ul>
                              <li
                                className="px-2 py-2  cursor-pointer"
                                onClick={() => clickEditdetails(item)}
                              >
                                <div className="flex flex-row justify-start items-center">
                                  <MenuEditIcon className="w-5 h-5 3xl:w-6 3xl:h-6 flex-shrink-0 " />
                                  <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base 3xl:text-base ml-3">
                                    {t("EditDetails")}{" "}
                                  </p>
                                </div>
                              </li>
                              <li
                                className="px-2 py-2 mt-2 cursor-pointer"
                                onClick={() => clickDelete(item)}
                              >
                                <div className="flex flex-row justify-start items-center">
                                  <MenuDeleteIcon className="w-5 h-5 3xl:w-6 3xl:h-6 flex-shrink-0 " />
                                  <p className="font-PlusJakartaSansRegular  text-mTextColorLogin text-base 3xl:text-base ml-3">
                                    {t("Delete")}{" "}
                                  </p>
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="justify-center items-center flex-col flex w-full h-full ">
                    <img
                      className="rounded-lg bg-white "
                      src={Images.nodata}
                      alt="review"
                    />
                    <div className="mt-10">
                      {" "}
                      <Titletext label={t("nodata")} />
                    </div>
                  </div>
                )}
              </div>
            </main>

            {newFileShow ? (
              <CreateNewFile
                show={newFileShow}
                onConfirm={newfileCreate}
                onCancel={clickEditClose}
                Title={Title}
                selectedItem={selectedFiles}
                valutdetails={selectedFiles}
                isEdit={false}
                buttonname={buttonname}
              />
            ) : (
              <div></div>
            )}

            {newValutShow ? (
              <CreateNewVault
                show={newValutShow}
                onConfirm={newvalutCreate}
                onCancel={clickClose}
              />
            ) : (
              <div></div>
            )}

            <UserAlertDialog
              isOpen={isAlertOpen}
              onClose={closeAlert}
              Confirm={Confirm}
            />
          </div>
          <DeleteDialog
            isOpen={isDialogOpen}
            onCancel={closeDialog}
            onConfirm={confirmDelete}
            title={"Delete Confirmation"}
            message={" Are you sure you want to delete this item? "}
          />
        </div>
      )}
    </>
  );
};

export default ValutPage;
