import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Utils/Loader";
import { useTranslation } from "react-i18next";
import { ReactComponent as UserIcon } from "../../assets/Images/ic_user.svg";
import { ReactComponent as EmailIcon } from "../../assets/Images/ic_email.svg";
import { ReactComponent as Icondrop } from "../../assets/Images/ic_dropdown.svg";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { Images, isValidEmail, mTimerValue } from "../../Utils/Constants";
import { toast } from "react-toastify";
import {
  mGetMaster,
  mGooglelogin,
  mLogin,
  mSignup,
} from "../../Services/allAPI";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/slice/userSlice";
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/DropdownMenu.css";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import Headingtext from "../../Components/Headingtext";
import TextView from "../../Components/TextView";
import { fetchMasterData } from "../../redux/slice/masterSlice";
import LogoText from "../../Components/LogoText";

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [invitecode, setInvitecode] = useState("");
  const [companyname, setCompanyname] = useState("");
  const [isChecked, setisChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userImageBase64, setUserImageBase64] = useState(null);

  const [userType, setuserType] = useState([]);
  // Set Progressbar
  const [progress, setProgress] = useState(0);
  const [isRunning, setIsRunning] = useState(true);

  // Get Deck id

  const location = useLocation();
  const mGetdeckId = location.state?.mdeckId || "";
  const mGetdeckName = location.state?.mdeckName || "";

  // Google login start

  const [userDetails, setUserDetails] = useState([]);
  const [profile, setProfile] = useState([]);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [timerStart, settimerStart] = useState(false);

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => setUserDetails(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  const gmailLoginClick = () => {
    console.log("sghdkhg");
    if (selectedOption === "Select User Type") {
      toast.error("Please Select User Type");
    } else if (invitecode === "") {
      toast.error("Please enter vaild invite code");
    } else {
      googleLogin();
      console.log("sghdkhg");
    }
  };

  console.log("Google Login : " + JSON.stringify(profile));
  useEffect(() => {
    if (userDetails.length !== 0) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userDetails.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${userDetails.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          convertToBase64(userDetails.picture);
          setProfileLoaded(true);
          if (profile.length !== 0) {
            //
            //   SignupCall();
          }

          //SignupCall();
        })
        .catch((err) => console.log(err));
    }
  }, [userDetails]);

  useEffect(() => {
    if (profileLoaded) {
      // Convert image to base64 and then trigger the signup call
      convertToBase64(profile.picture)
        .then(() => {
          if (profile.name) {
            SignupCall(); // Call Signup API
          }
        })
        .catch((err) => console.log(err));
    }
  }, [profileLoaded, profile]);

  // Google login end

  if (!location.state || !location.state.mdeckId) {
  } else {
  }

  // One min interval
  useEffect(() => {
    if (!isRunning) return;

    const intervalTime = mTimerValue / 100; // Update every 0.6 seconds (1% of 60 seconds)

    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isRunning]);

  //Convert Google user image to base64
  const convertToBase64 = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onloadend = () => {
        setUserImageBase64(reader.result); // This will be the base64 string
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error converting image to base64:", error);
    }
  };

  // Signup API Call
  const SignupCall = async () => {
    setLoading(true);
    console.log("Error" + profile.name);
    const response = await mGooglelogin({
      user_name: profile.name,
      email: profile.email,
      user_type: selectedOption,
      deck_id: mGetdeckId,
      file_name: "",
      file_type: "",
      profile_image: "",
      deal_name: "",
      coupon_code: invitecode,
      company_name: companyname,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        dispatch(setUser({ user: response?.data.message.userinfo }));

        toast.success(response?.data.message.message);

        localStorage.setItem(
          "UserInfo",
          JSON.stringify(response?.data.message.userinfo)
        );
        const savedData = localStorage.getItem("UserInfo");
        console.log(savedData);
        //  alert(JSON.stringify(savedData));
        if (mGetdeckId.length !== 0) {
          navigate("/question", {
            state: {
              mdeckId: mGetdeckId,
              mdeckName: mGetdeckName,
            },
          });
        } else {
          navigate("/dashboard");
        }
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const PasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleCheckboxChange = (event) => {
    setisChecked(event.target.checked);
  };

  const registerClick = () => {
    if (!isValidEmail(email)) {
      toast.error(t("vaildmail"));
      return;
    }
    NormalSignupCall();
  };

  // Login API Call
  const NormalSignupCall = async () => {
    setLoading(true);
    const response = await mSignup({
      email: email,
      password: password,
      user_type: selectedOption,
      deck_id: mGetdeckId,
      user_name: name,
      deal_name: "",
      coupon_code: invitecode,
      company_name: companyname,
    });

    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        dispatch(setUser({ user: response?.data.message.userinfo }));

        toast.success(response?.data.message.message);

        localStorage.setItem(
          "UserInfo",
          JSON.stringify(response?.data.message.userinfo)
        );
        const savedData = localStorage.getItem("UserInfo");
        console.log(savedData);
        if (mGetdeckId.length !== 0) {
          ///question
          navigate("/dashboard", {
            state: {
              mdeckId: mGetdeckId,
              mdeckName: mGetdeckName,
            },
          });
        } else {
          navigate("/dashboard");
        }
      } else {
        toast.error(response?.data.message.message);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      console.log("Error");
    }
  };

  const loginClick = () => {
    navigate("/login", {
      state: {
        mdeckId: mGetdeckId,
        mdeckName: mGetdeckName,
      },
    });
    //  navigate("/login");
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select User Type");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const masterdataList = useSelector((state) => state.master.MasterdataList);
  let status = useSelector((state) => state.master.status);
  const error = useSelector((state) => state.master.error);

  // Get Master data start

  useEffect(() => {
    dispatch(fetchMasterData());

    // alert(JSON.stringify(masterdataList.customer_group[0].name));
  }, [dispatch]);

  useEffect(() => {
    if (masterdataList?.customer_group?.length > 0) {
      setSelectedOption(masterdataList.customer_group[0].name);
    }
  }, [masterdataList]);

  console.log(status);

  if (status === "loading" || status === "idle") {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  // Get master data end

  // Check Disable button
  const isDisabled =
    !name ||
    !email ||
    !password ||
    !isChecked ||
    !invitecode ||
    selectedOption === "Select User Type" ||
    (mGetdeckId.length !== 0 && isRunning && progress !== 100);

  const isLoginDisbled =
    mGetdeckId.length !== 0 && isRunning && progress !== 100;

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="min-h-screen flex flex-row">
          <div className="w-3/5 hidden lg:block bg-mwhite  flex-col items-start justify-start p-5">
            <div className="mt-3 ml-3">
              {/* <img src={Images.zenpivot} alt="google Icon" className=" " /> */}
              <LogoText label={t("appname")} />
            </div>

            <div className="w-full h-auto justify-center items-center flex">
              <img src={Images.loginbg} alt="login" className="w-2/3 " />
            </div>
          </div>
          <div className=" lg:w-2/5 w-full pl-5 py-5 lg:pl-20 lg:py-10  bg-mBackground items-center justify-start flex ">
            <div className="grid gap-7 grid-cols-1 w-11/12">
              <div>
                <Headingtext label={t("Signup")} />
              </div>

              <div
                className={` hidden h-14 w-full border border-mBorderColor rounded-lg justify-center items-center flex ${
                  isLoginDisbled ? "cursor-not-allowed" : "cursor-pointer"
                }`}
                // onClick={googleloginClick}
                onClick={gmailLoginClick}
                disabled={isLoginDisbled}
              >
                <img
                  src={Images.google}
                  alt="Upload Icon"
                  className={`w-6 h-6 `}
                />
                <p className="font-PlusJakartaSansRegular font-normal text-base 3xl:text-base ml-2">
                  {" "}
                  {t("Google")}
                </p>
              </div>

              <div className="hidden flex items-center before:flex-1 before:border-t before:border-mBorderColor after:flex-1 after:border-t after:border-mBorderColor">
                <p className="mx-2 mb-0 text-center font-PlusJakartaSansRegular text-sm   text-mTextColorLogin">
                  {t("or")}
                </p>
              </div>
              <div>
                <div className=" ">
                  <TextView label={t("Joinas")} ismandatory={true} />

                  <div className="border-mBorderColor h-14 rounded-lg p-3 w-full border bg-white mt-3 flex-row justify-between flex">
                    <div className=" items-center justify-center flex w-full">
                      <div className="relative  w-full">
                        <button
                          className="custom-dropdown-toggle"
                          onClick={toggleDropdown}
                        >
                          <p
                            className={`font-PlusJakartaSansRegular font-normal text-mTextColorLogin text-sm 3xl:text-base `}
                          >
                            {selectedOption}
                          </p>
                        </button>

                        {/* Dropdown menu */}
                        <ul
                          className={`custom-dropdown-menu  ${
                            isOpen ? "show" : ""
                          }`}
                        >
                          {masterdataList.customer_group.map((option) => (
                            <li
                              key={option.name}
                              className={`custom-dropdown-option ${
                                selectedOption === option.name ? "selected" : ""
                              }`}
                              onClick={() => handleOptionClick(option.name)}
                            >
                              <p
                                className={`font-PlusJakartaSansRegular font-normal text-mTextColorLogin text-sm 3xl:text-base ${
                                  selectedOption === option.name
                                    ? "text-white"
                                    : "text-mTextColorLogin"
                                }`}
                              >
                                {option.name}
                              </p>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <Icondrop className="w-6 h-6 flex-shrink-0" />
                  </div>
                </div>

                <div>
                  <div className="mt-4">
                    <TextView label={t("Name")} ismandatory={true} />
                  </div>
                  <InputContainer
                    type="text"
                    value={name}
                    placeholder={t("EnterYourName")}
                    label="text"
                    name="text"
                    disabled={false}
                    maxLength={60}
                    showIcon={true}
                    Icon={UserIcon}
                    onChange={(e) => {
                      setName(e.target.value);
                      // setforgetPwd({ ...forgetPwd, email: event.target.value });
                    }}
                  />
                </div>

                <div>
                  <div className="mt-4">
                    <TextView label={t("EMail")} ismandatory={true} />
                  </div>
                  <InputContainer
                    type="email"
                    value={email}
                    placeholder={t("Enteryouremail")}
                    label="email"
                    name="email"
                    disabled={false}
                    maxLength={60}
                    showIcon={true}
                    Icon={EmailIcon}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      // setforgetPwd({ ...forgetPwd, email: event.target.value });
                    }}
                  />
                </div>

                <div>
                  <div className="mt-4">
                    <TextView label={t("Password")} ismandatory={true} />
                  </div>
                  <InputContainer
                    type={showPassword ? "text" : "password"}
                    value={password}
                    placeholder={t("EnterPassword")}
                    label="password"
                    name="password"
                    disabled={false}
                    maxLength={60}
                    secureTextEntry={true}
                    PasswordVisibility={PasswordVisibility}
                    setshowPassword={showPassword}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      // setLogin({ ...login, password: e.target.value });
                    }}
                  />
                </div>

                <div>
                  <div className="mt-4">
                    <TextView label={t("referralcode")} ismandatory={true} />
                  </div>
                  <InputContainer
                    type={"text"}
                    value={invitecode}
                    placeholder={t("enterreferralcode")}
                    label="referralcode"
                    name="passwreferralcodeord"
                    disabled={false}
                    maxLength={8}
                    secureTextEntry={false}
                    onChange={(e) => {
                      setInvitecode(e.target.value);
                      // setLogin({ ...login, password: e.target.value });
                    }}
                  />
                </div>

                <div>
                  <div className="mt-4">
                    <TextView label={t("company")} ismandatory={false} />
                  </div>
                  <InputContainer
                    type={"text"}
                    value={companyname}
                    placeholder={t("entercompany")}
                    label="entercompany"
                    name="entercompany"
                    disabled={false}
                    maxLength={1000}
                    secureTextEntry={false}
                    onChange={(e) => {
                      setCompanyname(e.target.value);
                      // setLogin({ ...login, password: e.target.value });
                    }}
                  />
                </div>

                {mGetdeckId.length !== 0 ? (
                  <div className="mt-10">
                    <ProgressBar progress={progress} />
                    <p className="font-PlusJakartaSansRegular text-mTextColor font-normal text-sm mt-2">
                      {mGetdeckName}
                    </p>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="w-full flex flex-row  items-center mt-8">
                  <div className="flex flex-col">
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="privacyTermsCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="form-checkbox h-4 w-4 "
                      />

                      <label
                        htmlFor="privacyTermsCheckbox"
                        className="ml-2  text-mTextColorLogin text-sm 3xl:text-base font-PlusJakartaSansRegular"
                      >
                        {t("Iagreetothe")}{" "}
                        {/* <a
                          href="https://theprevc.webflow.io/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-mButtonColor hover:underline text-sm 3xl:text-base font-PlusJakartaSansRegular"
                        >
                          {t("TermsOfUse")}
                        </a>{" "}
                        {t("and")}{" "} */}
                        <a
                          href="https://www.theprevc.com/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-mButtonColor hover:underline text-sm 3xl:text-base font-PlusJakartaSansRegular"
                        >
                          {t("PrivacyPolicy")}
                        </a>
                        .
                      </label>
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <PrimaryButton
                    label={t("Signup")}
                    onClick={registerClick}
                    onDisable={isDisabled}
                  ></PrimaryButton>
                </div>
                <div className="flex-row flex justify-center mt-4">
                  <p className="text-center font-PlusJakartaSansRegular text-sm 3xl:text-base text-mTextColorLogin">
                    {t("alreadyaccount")}
                  </p>
                  <button
                    className={`text-center font-PlusJakartaSansSemiBold text-sm  3xl:text-base ml-2 ${
                      isLoginDisbled
                        ? "text-mButtonDisable cursor-not-allowed"
                        : "text-mButtonColor"
                    } `}
                    onClick={loginClick}
                    disabled={isLoginDisbled}
                  >
                    {t("login")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const ProgressBar = ({ progress }) => {
  return (
    <div className="bg-mBorderColor rounded-md">
      <div
        className="progress-bar"
        style={{
          width: `${progress}%`,
          backgroundColor: progress < 100 ? "#3A7D50" : "#3A7D50",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <span
          className="progress-text font-PlusJakartaSansSemiBold text-base"
          style={{}}
        >
          {progress}%
        </span>
      </div>
    </div>
  );
};

export default Signup;
