import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DashboardTitle from "../../Components/DashboardTitle";
import Menutitletext from "../../Components/Menutitletext";
import MultiRangeSlider from "../../Components/MultiRangeSlider";
import Menutext from "../../Components/Menutext";
import { Images, isEmptyObject } from "../../Utils/Constants";
import {
  mgetInvestordb,
  mInvestordb,
  mInvestorShortlist,
} from "../../Services/allAPI";
import { toast } from "react-toastify";
import Loader from "../../Utils/Loader";
import { ReactComponent as DottedIcon } from "../../assets/Images/ic_dotted.svg";
import { ReactComponent as TickIcon } from "../../assets/Images/ic_tickgray.svg";
import { ReactComponent as UserIcon } from "../../assets/Images/ic_user.svg";
import ViewSuggestedInvestors from "./ViewSuggestedStartupInvestors";
import Titletext from "../../Components/Titletext";
import PrimaryButton from "../../Components/PrimaryButton";
import ViewSuggestedStartupInvestors from "./ViewSuggestedStartupInvestors";
import { ReactComponent as CloseIcon } from "../../assets/Images/ic_close.svg";

const SuggestedStartupInvestors = ({ onShowDialog, onDialogCancel }) => {
  const { t } = useTranslation("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [rangeValues, setRangeValues] = useState([0, 1000]);
  const [investordb, setinvestordb] = useState([]);
  const menuRef = useRef(null);
  const [ShowInvestorDetails, setShowInvestorDetails] = useState(false);
  const [ShowInvestorItem, setShowInvestorItem] = useState({});
  const [showDialog, setshowDialog] = useState(false);
  const [checkSizeMin, setcheckSizeMin] = useState(0);
  const [checkSizeMax, setcheckSizeMax] = useState(1000);

  //alert(onShowDialog);

  // Filter Function Start

  //Filter Function  End

  // Slider Range select
  const handleRangeChange = (values) => {
    // setRangeValues(values);
    setcheckSizeMin(values.min);
    setcheckSizeMax(values.max);
    console.log("Selected " + values.min + "  " + values.max);
  };

  // Close the menu on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  //Get suggested Investor

  useEffect(() => {
    ServicePageAPICall();
  }, []);

  useEffect(() => {
    if (onShowDialog) {
      setshowDialog(true);
    } else {
      setshowDialog(false);
    }
    console.log("Dialog state changed: ", onShowDialog);
  }, [onShowDialog]);

  // Service Page API call
  const ServicePageAPICall = async () => {
    setLoading(true);
    const response = await mInvestordb({
      // user_name: UserInfoData.user_email,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        setinvestordb(response?.data.message.investor_db);
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (id) => {
    setActiveMenu(activeMenu === id ? null : id);
  };

  const clickShortlist = (item, event) => {
    setActiveMenu(null);
    ShortlistAPICall(item.id);
  };

  const clickViewInvestor = (item, event) => {
    setActiveMenu(null);
    setShowInvestorDetails(true);
    setShowInvestorItem(item);
    // alert("dskdhg");
  };

  //Get User Details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  // Shortlist API Call
  const ShortlistAPICall = async (id) => {
    setLoading(true);
    const response = await mInvestorShortlist({
      user_name: UserInfoData.user_email,
      investor_id: id,
    });
    //console.log(response);
    if (response.status === 200) {
      setLoading(false);
      if (response?.data.message.status) {
        toast.success(response?.data.message.status);
        ServicePageAPICall();
      } else {
        toast.error(response?.data.message.status);
      }
    } else {
      const errorMessage = response.data?.message || "Server Error";
      toast.error(errorMessage);
      setLoading(false);
      //  console.log("Error");
    }
  };

  const clickClose = () => {
    setShowInvestorDetails(false);
  };

  // Investor Type Filter start
  const InvestorType = [
    { id: 1, name: "Angel Investor" },
    { id: 2, name: "Venture Capital" },
    { id: 3, name: "Family Office" },
    { id: 4, name: "Private Equity" },
  ];

  // State to keep track of selected checkboxes
  const [selectedInvestorType, setSelectedInvestorType] = useState([]);

  // Handle checkbox change
  const handleInvestorTypeCheckbox = (id) => {
    setSelectedInvestorType((prevSelected) => {
      if (prevSelected.includes(id)) {
        // Remove from selected if already selected
        return prevSelected.filter((filterId) => filterId !== id);
      } else {
        // Add to selected if not already selected
        return [...prevSelected, id];
      }
    });
  };

  // Display the selected filters
  var selectedFilterNames = InvestorType.filter((filter) =>
    selectedInvestorType.includes(filter.id)
  ).map((filter) => filter.name);

  // Investor Type Filter End

  // stage Filter Start
  const stage = [
    { id: 11, name: "FMCG" },
    { id: 12, name: "IT & ITES" },
    { id: 13, name: " Agri-business" },
    { id: 14, name: "Manufacturing" },
    { id: 15, name: "BFSI" },
    { id: 16, name: "Engg. & Construction" },
    { id: 17, name: "Retail" },
    { id: 18, name: "Food & Beverages" },
    { id: 19, name: "Others" },
  ];

  // State to keep track of selected checkboxes
  const [selectedStage, setSelectedStage] = useState([]);

  // Handle checkbox change
  const handleStageCheckbox = (id) => {
    setSelectedStage((prevSelected) => {
      if (prevSelected.includes(id)) {
        // Remove from selected if already selected
        return prevSelected.filter((filterId) => filterId !== id);
      } else {
        // Add to selected if not already selected
        return [...prevSelected, id];
      }
    });
  };

  // Display the selected filters
  var selectedStageNames = stage
    .filter((filter) => selectedStage.includes(filter.id))
    .map((filter) => filter.name);

  // stage Filter End

  const filterClick = () => {
    console.log(checkSizeMin);
    console.log(checkSizeMax);
    console.log(selectedFilterNames);
    console.log(selectedStageNames);
  };

  const clearall = () => {
    setSelectedStage([]);
    setSelectedInvestorType([]);

    if (childRef.current) {
      childRef.current.triggerButtonClick(); // Call method exposed by child
    }
  };

  const childRef = useRef(null);

  const onCancel = () => {
    setshowDialog(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="w-full h-full flex flex-row">
          {/* Left Section (Filter) */}
          <div className="hidden lg:block w-1/5 bg-white rounded-lg p-4 overflow-y-auto max-h-screen scrollbar-hide">
            <div className="grid grid-cols-1 gap-7">
              {/* Filter Section */}
              <div className="flex flex-row justify-between">
                <DashboardTitle label={t("Filter")} />
                <div
                  className="justify-center items-center flex cursor-pointer"
                  onClick={clearall}
                >
                  <p className="font-PlusJakartaSansRegular text-mButtonColor text-xs">
                    {t("Clearall")}
                  </p>
                </div>
              </div>

              <div className="hidden">
                <Menutitletext label={t("CheckSize")} />
                <div className="flex flex-col items-center w-full mt-2">
                  <div className="mt-3 relative">
                    <MultiRangeSlider
                      min={0}
                      max={1000}
                      ref={childRef}
                      onChange={handleRangeChange}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full h-px bg-mBorderColor"></div>

              {/* Investor Type Section */}
              <Menutitletext label={t("InvestorType")} />
              <div className="mt-5 grid grid-cols-1 gap-5">
                {InvestorType.map((item) => (
                  <div className="flex flex-col" key={item.id}>
                    <div className="flex items-center justify-start">
                      <label className="text-sm text-mTextColorLogin font-PlusJakartaSansRegular flex items-center">
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedInvestorType.includes(item.id)}
                          onChange={() => handleInvestorTypeCheckbox(item.id)}
                          className="h-4 w-4 mr-2 font-PlusJakartaSansRegular"
                        />
                        {item.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-px bg-mBorderColor"></div>

              {/* Industry Section */}
              <Menutitletext label={t("industry")} />
              <div className="mt-5 grid grid-cols-1 gap-5">
                {stage.map((item) => (
                  <div className="flex flex-col" key={item.id}>
                    <div className="flex items-center justify-start">
                      <label className="text-sm text-mTextColorLogin font-PlusJakartaSansRegular flex items-center">
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={selectedStage.includes(item.id)}
                          onChange={() => handleStageCheckbox(item.id)}
                          className="h-4 w-4 mr-2 font-PlusJakartaSansRegular"
                        />
                        {item.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>

              <div className="w-full h-px bg-mBorderColor"></div>

              <div>
                <button
                  className="bg-mButtonColor text-white text-base px-5 rounded-md w-full h-9 font-PlusJakartaSansSemiBold"
                  onClick={filterClick}
                >
                  {t("submit")}
                </button>
              </div>
            </div>
            <div className="h-9"></div>
          </div>

          {/* Right Section (Investor List) */}
          <div className="w-full lg:w-4/5 h-full lg:ml-5 overflow-y-auto max-h-screen scrollbar-hide">
            {investordb.length !== 0 ? (
              <div className="grid-containeritemsugginv">
                {investordb.map((item) => (
                  <div key={item.id} className="grid-itemsugginv bg-white p-3">
                    <div className="grid grid-cols-1 gap-5">
                      <div className="p-4 flex flex-row justify-between">
                        <div
                          className="flex-row flex h-full w-full justify-center items-center"
                          onClick={() => clickViewInvestor(item)}
                        >
                          <img
                            className="rounded-lg bg-white w-9 h-9"
                            src={
                              item.image.length === 0
                                ? Images.image
                                : item.image
                            }
                            alt=""
                          />
                          <div className="h-full flex w-full ml-2 flex-col">
                            <Menutext label={item.investor_name} />
                            <div className="mt-1">
                              <Menutitletext label={item.investor_type} />
                            </div>
                          </div>
                        </div>
                        <div>
                          <DottedIcon
                            className="w-6 h-6 flex-shrink-0 cursor-pointer"
                            onClick={() => toggleMenu(item.id)}
                          />
                        </div>
                      </div>

                      <div className="w-full h-px bg-mBorderColor"></div>

                      <div
                        className="grid grid-cols-3 gap-3 w-full"
                        onClick={() => clickViewInvestor(item)}
                      >
                        {item.funding_stages.map((stagesitem) => (
                          <div
                            className="p-2 rounded-md bg-mCardbgColor justify-center items-center flex"
                            key={stagesitem.id}
                          >
                            <p className="font-PlusJakartaSansRegular text-mTextColorLogin text-xs">
                              {stagesitem.funding_stages}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                    {activeMenu === item.id && (
                      <div
                        className="popup-menuValutdetails right-2 mt-2 w-40 bg-white p-3 rounded-xl shadow-xl shadow-mChatbg z-10"
                        ref={menuRef}
                      >
                        <ul>
                          <li
                            className="px-2 py-2 mt-2 cursor-pointer"
                            onClick={() => clickShortlist(item)}
                          >
                            <div className="flex flex-row justify-start items-center">
                              <TickIcon className="w-5 h-5 flex-shrink-0" />
                              <p className="font-PlusJakartaSansRegular text-mTextColorLogin text-base ml-3">
                                {t("Shortlist")}
                              </p>
                            </div>
                          </li>
                          <li
                            className="px-2 py-2 mt-2 cursor-pointer"
                            onClick={() => clickViewInvestor(item)}
                          >
                            <div className="flex flex-row justify-start items-center">
                              <UserIcon className="w-5 h-5 flex-shrink-0" />
                              <p className="font-PlusJakartaSansRegular text-mTextColorLogin text-base ml-3">
                                {t("ViewProfile")}
                              </p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div className="justify-center items-center mt-16 flex-col flex w-full h-full">
                <img
                  className="rounded-lg bg-white"
                  src={Images.nodata}
                  alt="review"
                />
                <div className="mt-10">
                  <Titletext label={t("nodata")} />
                </div>
              </div>
            )}
            <div className="h-9"></div>
          </div>
        </div>
      )}
    </>
  );
};
export default SuggestedStartupInvestors;
