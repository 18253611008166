import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DashboardTitle from "../../Components/DashboardTitle";
import { ReactComponent as EditIcon } from "../../assets/Images/ic_edit.svg";
import { ReactComponent as UserIcon } from "../../assets/Images/ic_user.svg";
import { ReactComponent as PostionIcon } from "../../assets/Images/ic_postion.svg";
import { ReactComponent as SectorIcon } from "../../assets/Images/ic_sector.svg";
import { ReactComponent as MailIcon } from "../../assets/Images/ic_mail.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/Images/ic_linkedin.svg";
import { ReactComponent as AddressIcon } from "../../assets/Images/ic_address.svg";
import TextView from "../../Components/TextView";
import TextViewlight from "../../Components/TextViewlight";

const Companyinfo = ({
  mcompEditclick,
  mcompEditAboutclick,
  companyInformation,
}) => {
  const { t } = useTranslation("");

  const openWebsite = (url) => {
    // Check if the URL already includes a protocol
    const websiteUrl =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `https://${url}`;

    window.open(websiteUrl, "_blank");
  };

  return (
    <>
      {companyInformation.length > 0 ? (
        <div>
          <div className="bg-white rounded-md lg:rounded-xl p-2 lg:p-6">
            <div className="grid grid-cols-1 gap-5">
              <div className="h-9 justify-between flex ">
                <div className="justify-center items-center flex">
                  <DashboardTitle label={t("CompanyInformation")} />
                </div>
                <div
                  className="h-7 lg:h-9 ml-3 lg:ml-6 p-2 lg:p-4 bg-mButtonColorligth rounded-lg items-center justify-center flex cursor-pointer"
                  onClick={mcompEditclick}
                >
                  <EditIcon className="w-5 h-5 flex-shrink-0 cursor-pointer" />
                  <p className="ml-2 font-PlusJakartaSansRegular text-mButtonColor text-base">
                    {t("Edit")}
                  </p>
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <UserIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("CompanyName")} />
                  </div>
                </div>

                <div className="w-full md:w-3/4 ml-10">
                  <TextViewlight label={companyInformation[0].company_name} />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <PostionIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("Position")} />
                  </div>
                </div>

                <div className="w-full md:w-3/4 ml-10">
                  <TextViewlight label={companyInformation[0].position} />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <SectorIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("Sector")} />
                  </div>
                </div>

                <div className="w-full md:w-3/4 ml-10">
                  <TextViewlight label={companyInformation[0].sector} />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <MailIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("MailID")} />
                  </div>
                </div>

                <div className="w-full md:w-3/4 ml-10">
                  <TextViewlight label={companyInformation[0].mail_id} />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <AddressIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("Address")} />
                  </div>
                </div>

                <div className="w-full md:w-3/4 ml-10">
                  <TextViewlight label={companyInformation[0].address} />
                </div>
              </div>
              <div className="w-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/4 flex flex-row justify-start items-center ">
                  <LinkedinIcon className="w-5 h-5 flex-shrink-0 " />
                  <div className="ml-3 justify-center items-center flex">
                    <TextView label={t("Website")} />
                  </div>
                </div>

                <div
                  className="w-full md:w-3/4 ml-10"
                  onClick={() => {
                    // window.open(companyInformation[0].website, "_blank");
                    openWebsite(companyInformation[0].website);
                    // window.open(companyInformation[0].website, "_blank");
                  }}
                >
                  <p className="text-blue-500 underline hover:text-blue-700 font-PlusJakartaSansRegular font-normal leading-7 cursor-pointer text-sm md:text-base xl:text-lg 3xl:text-lg">
                    {companyInformation[0].website}
                  </p>
                  {/* <TextViewlight label={companyInformation[0].website} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-xl p-6 mt-6">
            <div className="grid grid-cols-1 gap-5">
              <div className="h-9 justify-between flex ">
                <div className="justify-center items-center flex">
                  <DashboardTitle label={t("AboutCompany")} />
                </div>
                <div
                  className="h-7 lg:h-9 ml-3 lg:ml-6 p-2 lg:p-4 bg-mButtonColorligth rounded-lg items-center justify-center flex cursor-pointer"
                  onClick={mcompEditAboutclick}
                >
                  <EditIcon className="w-5 h-5 flex-shrink-0 cursor-pointer" />
                  <p className="ml-2 font-PlusJakartaSansRegular text-mButtonColor text-base">
                    {t("Edit")}
                  </p>
                </div>
              </div>
              <div>
                <TextViewlight label={companyInformation[0].company_about} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Companyinfo;
