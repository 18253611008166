import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as CompanyIcon } from "../assets/Images/ic_company.svg";
import { ReactComponent as UserIcon } from "../assets/Images/ic_user.svg";
import { ReactComponent as CrownIcon } from "../assets/Images/ic_crownblock.svg";
import { ReactComponent as SettingsIcon } from "../assets/Images/ic_setting.svg";

const ProfileSideMenu = ({ mFrom, handleClick }) => {
  const { t } = useTranslation("");

  // Get user details
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  const Menus = [
    {
      title: t("GeneralInformation"),
      src: UserIcon,
    },
    {
      title: t("CompanyInformation"),
      src: CompanyIcon,
    },

    {
      title: t("SubscriptionInfo"),
      src: CrownIcon,
    },
    {
      title: t("Accountsettings"),
      src: SettingsIcon,
    },
  ];

  //alert(UserInfoData.user_type);

  const filteredMenus = Menus.filter((menu) => {
    // If the menu is "Services" or "Valut", include it only if isFeatureEnabled is true
    if (menu.title === t("SubscriptionInfo")) {
      //alert(menu.title);
      //return UserInfoData.subscription_status;
      return false;
    } else if (
      menu.title === t("SubscriptionInfo") ||
      menu.title === t("CompanyInformation")
    ) {
      return UserInfoData.user_type === "Entrepreneur" ? true : false;
    } else if (
      menu.title === t("SubscriptionInfo") ||
      menu.title === t("CompanyInformation")
    ) {
      return UserInfoData.user_type === "Investor" ? true : false;
    }

    // Otherwise, include all other menus
    return true;
  });

  // const InvestorMenus = [
  //   {
  //     title: t("GeneralInformation"),
  //     src: UserIcon,
  //   },
  //   {
  //     title: t("Accountsettings"),
  //     src: SettingsIcon,
  //   },
  // ];

  return (
    <div className=" h-full lg:h-screen">
      <div
        className={` bg-dark-purple justify-center  h-full lg:h-screen items-start  overflow-hidden  px-2  `}
      >
        <div className=" flex flex-col">
          <div className=" p-1 ">
            <div>
              <ul className="mt-1  flex flex-row lg:flex-col">
                {filteredMenus.map((Menu, index) => (
                  <li key={index} onClick={() => handleClick(index)}>
                    <div className="py-1 lg:py-2  ">
                      <div
                        className={`flex rounded-sm lg:rounded-md cursor-pointer hover:bg-light-white text-gray-300 text-sm 3xl:text-sm items-center flex-row py-2 ml-5 lg:ml-0
                      ${mFrom === index ? "bg-mMenuSelect" : "bg-white"}  ${
                          mFrom === index ? "px-1" : "px-0"
                        } `}
                      >
                        <Menu.src className="lg:w-5 lg:h-5 w-6 h-6  flex-shrink-0 " />
                        <p
                          className={`hidden lg:block font-PlusJakartaSansRegular font-normal text-mTextColor text-sm  lg:text-sm 3xl:text-sm   ml-4`}
                        >
                          {" "}
                          {Menu.title}{" "}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileSideMenu;
// UserInfoData.user_type === "Investor"
//                   ? InvestorMenus.map((Menu, index) => (
//                       <li key={index} onClick={() => handleClick(index)}>
//                         <div className="py-1 lg:py-2  ">
//                           <div
//                             className={`flex rounded-sm lg:rounded-md cursor-pointer hover:bg-light-white text-gray-300 text-sm 3xl:text-sm items-center flex-row py-2 ml-5 lg:ml-0
//                       ${mFrom === index ? "bg-mMenuSelect" : "bg-white"}  ${
//                               mFrom === index ? "px-1" : "px-0"
//                             } `}
//                           >
//                             <Menu.src className="lg:w-5 lg:h-5 w-6 h-6  flex-shrink-0 " />
//                             <p
//                               className={`hidden lg:block font-PlusJakartaSansRegular font-normal text-mTextColor text-sm  lg:text-sm 3xl:text-sm   ml-4`}
//                             >
//                               {" "}
//                               {Menu.title}{" "}
//                             </p>
//                           </div>
//                         </div>
//                       </li>
//                     ))
//                   :
