import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as DashboardEmailIcon } from "../assets/Images/menu_dashboard.svg";
import { ReactComponent as DeckuploaderIcon } from "../assets/Images/menu_deckuploader.svg";
import { ReactComponent as InversorcrmIcon } from "../assets/Images/menu_inversorcrm.svg";
import { ReactComponent as ServiceIcon } from "../assets/Images/menu_service.svg";
import { ReactComponent as ValutIcon } from "../assets/Images/menu_valut.svg";
import { ReactComponent as ProfileIcon } from "../assets/Images/menu_profile.svg";
import { ReactComponent as SettingsIcon } from "../assets/Images/menu_settings.svg";
import { ReactComponent as ChatIcon } from "../assets/Images/menu_chat.svg";
import { ReactComponent as CrownblocIcon } from "../assets/Images/ic_crownblock.svg";
import LogoText from "./LogoText";
import LogoTextsmall from "./LogoTextsmall";

const SideMenu = (props) => {
  const { t } = useTranslation("");
  const navigate = useNavigate();

  // Get user info
  const UserInfoData =
    localStorage.getItem("UserInfo") !== null
      ? JSON.parse(localStorage.getItem("UserInfo"))
      : {};

  // Menu items
  const Menus = [
    {
      title: t("Dashboard"),
      src: DashboardEmailIcon,
    },
    {
      title: t("DeckUploader"),
      src: DeckuploaderIcon,
    },

    {
      title: t("ZenpivotChat"),
      src: ChatIcon,
    },

    {
      title: t("InvestersCRM"),
      src: InversorcrmIcon,
    },
    {
      title: t("Services"),
      src: ServiceIcon,
    },
    {
      title: t("Valut"),
      src: ValutIcon,
    },
    {
      title: t("Subscription"),
      src: CrownblocIcon,
    },
  ];

  // const InvestorMenus = [
  //   {
  //     title: t("Dashboard"),
  //     src: DashboardEmailIcon,
  //   },
  //   {
  //     title: t("DeckUploader"),
  //     src: DeckuploaderIcon,
  //   },

  //   {
  //     title: t("InvestersCRM"),
  //     src: InversorcrmIcon,
  //   },
  // ];

  // Profile Menu items
  const ProfileMenus = [
    {
      title: t("profile"),
      src: ProfileIcon,
    },
    {
      title: t("Settings"),
      src: SettingsIcon,
    },
  ];

  const filteredMenus = Menus.filter((menu) => {
    // If the menu is "Services" or "Valut", include it only if isFeatureEnabled is true
    if (
      menu.title === t("Services") ||
      menu.title === t("Valut") ||
      menu.title === t("ZenpivotChat") ||
      menu.title === t("InvestersCRM") ||
      menu.title === t("Subscription")
    ) {
      //alert(menu.title);
      //return UserInfoData.subscription_status;
      return false;
    } else if (
      menu.title === t("Services") ||
      menu.title === t("Valut") ||
      menu.title === t("ZenpivotChat") ||
      menu.title === t("InvestersCRM") ||
      menu.title === t("Subscription")
    ) {
      return UserInfoData.user_type === "Investor" ? true : false;
    }

    // Otherwise, include all other menus
    return true;
  });

  // alert(JSON.stringify(filteredMenus));

  // Handle Click
  const handleClick = (itemName) => {
    if (props.mClickstatus) {
    } else {
      if (itemName === 0) {
        if (UserInfoData.user_type === "Investor") {
          navigate("/investordashboard");
        } else {
          navigate("/dashboard");
        }
      } else if (itemName === 1) {
        if (UserInfoData.user_type === "Investor") {
          navigate("/mydecks");
        } else {
          navigate("/mydecks");
        }
      } else if (itemName === 2) {
        if (UserInfoData.user_type === "Investor") {
          navigate("/investorscrm");
        } else {
          navigate("/chat");
        }
      } else if (itemName === 3) {
        navigate("/startupcrm");
      } else if (itemName === 4) {
        navigate("/service");
      } else if (itemName === 5) {
        navigate("/valut");
      } else if (itemName === 6) {
        navigate("/subscription");
      }
    }
  };
  // Handle proflie menu Click
  const handleSettngsClick = (itemName) => {
    if (props.mClickstatus) {
    } else {
      if (itemName === 0) {
        navigate("/profile");
      } else if (itemName === 1) {
        navigate("/help");
      }
    }
  };

  return (
    <div className="">
      <div
        className={`${"w-64"} hidden lg:block bg-dark-purple justify-center h-full relative items-start bg-white overflow-hidden ml-2 px-6 py-3 border-r border-mBorderColor`}
      >
        <div className="flex flex-col">
          <div className="flex items-center  mb-1">
            {/* <img
              src={Images.applogo}
              alt="appicon"
              className=" p-2 w-32 h-11 "
            /> */}
            <LogoText label={t("appname")} />
          </div>

          <div className="mt-10 p-2">
            <p className="font-PlusJakartaSansRegular font-normal text-mTextColor text-sm ">
              {" "}
              {t("MENU")}
            </p>
            {/* UserInfoData.user_type === "Investor"
                  ? InvestorMenus.map((Menu, index) => (
                      <li
                        key={index}
                        className={`${index === 6 ? "hidden" : "block"}`}
                        onClick={() => handleClick(index)}
                      >
                        <div className=" py-2 ">
                          <div
                            className={`flex rounded-md ${
                              props.mClickstatus
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } hover:bg-light-white text-gray-300 text-sm items-center flex-row py-2
                    ${props.mFrom === index ? "bg-mMenuSelect" : "bg-white"}  ${
                              props.mFrom === index ? "px-2" : "px-0"
                            } `}
                          >
                            <Menu.src className="w-5 h-5 flex-shrink-0 " />
                            <p
                              className={` font-PlusJakartaSansRegular font-normal text-mTextColor text-sm xl:text-base   ml-4`}
                            >
                              {" "}
                              {Menu.title}{" "}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))
                  : */}
            <div>
              <ul className="mt-4">
                {filteredMenus.map((Menu, index) => (
                  <li
                    key={index}
                    className={`${
                      Menu.title === t("Subscription") ? "hidden" : "block"
                    }`}
                    onClick={() => handleClick(index)}
                  >
                    <div className=" py-2 ">
                      <div
                        className={`flex rounded-md ${
                          props.mClickstatus
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        } hover:bg-light-white text-gray-300 text-sm items-center flex-row py-2
                      ${
                        props.mFrom === index ? "bg-mMenuSelect" : "bg-white"
                      }  ${props.mFrom === index ? "px-2" : "px-0"} `}
                      >
                        <Menu.src className="w-5 h-5 3xl:w-6 3xl:h-6 flex-shrink-0 " />
                        <p
                          className={` font-PlusJakartaSansRegular font-normal text-mTextColor text-sm xl:text-base ml-4`}
                        >
                          {" "}
                          {Menu.title}{" "}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="border border-mBorderColor w-full mt-4"></div>

            <div className="mt-8">
              {" "}
              <p className="font-PlusJakartaSansRegular font-normal text-mTextColor text-sm ">
                {" "}
                {t("Profile")}
              </p>
              <div>
                <ul className="mt-4">
                  {ProfileMenus.map((Menu, index) => (
                    <li key={index} onClick={() => handleSettngsClick(index)}>
                      <div className=" py-2 ">
                        <div
                          className={`flex rounded-md ${
                            props.mClickstatus
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          } hover:bg-light-white text-gray-300 text-sm items-center flex-row py-2
                      ${
                        props.mProfile === index ? "bg-mMenuSelect" : "bg-white"
                      }  ${props.mProfile === index ? "px-2" : "px-0"} `}
                        >
                          <Menu.src className="w-5 h-5 3xl:w-6 3xl:h-6 flex-shrink-0 " />
                          <p
                            className={` font-PlusJakartaSansRegular font-normal text-mTextColor text-sm xl:text-base ml-4`}
                          >
                            {" "}
                            {Menu.title}{" "}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="border border-mBorderColor w-full mt-4"></div>
          </div>
        </div>
      </div>

      <div
        className={`${"w-12"} block lg:hidden bg-dark-purple justify-center h-full relative items-start bg-white overflow-hidden ml-2 px-2 py-3 border-r border-mBorderColor`}
      >
        <div className="flex flex-col">
          <div className="flex items-center  mb-1">
            {/* <img src={Images.zenpivot} alt="appicon" className=" w-5 h-5 " /> */}
            <LogoTextsmall />
          </div>

          <div className="mt-1">
            <div>
              <ul className="mt-4">
                {filteredMenus.map((Menu, index) => (
                  <li
                    key={index}
                    className={``}
                    onClick={() => handleClick(index)}
                  >
                    <div className=" py-2 ">
                      <div
                        className={`flex rounded-md ${
                          props.mClickstatus
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        } hover:bg-light-white text-gray-300 text-sm items-center flex-row py-2
                      ${
                        props.mFrom === index ? "bg-mMenuSelect" : "bg-white"
                      }  ${props.mFrom === index ? "px-1" : "px-0"} `}
                      >
                        <Menu.src className="w-6 h-6 flex-shrink-0 " />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="border border-mBorderColor w-full mt-4"></div>

            <div className="mt-8">
              {" "}
              <div>
                <ul className="mt-4">
                  {ProfileMenus.map((Menu, index) => (
                    <li key={index} onClick={() => handleSettngsClick(index)}>
                      <div className=" py-2 ">
                        <div
                          className={`flex rounded-md ${
                            props.mClickstatus
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          } hover:bg-light-white text-gray-300 text-sm items-center flex-row py-2
                      ${
                        props.mProfile === index ? "bg-mMenuSelect" : "bg-white"
                      }  ${props.mProfile === index ? "px-1" : "px-0"} `}
                        >
                          <Menu.src className="w-6 h-6 flex-shrink-0 " />
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="border border-mBorderColor w-full mt-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
