import React, { useState, useEffect, useRef } from "react";
import Loader from "../../Utils/Loader";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../assets/Images/ic_back.svg";
import { ReactComponent as SparklesIcon } from "../../assets/Images/ic_sparkles.svg";
import InputContainer from "../../Components/InputContainer";
import PrimaryButton from "../../Components/PrimaryButton";
import { Images, isValidEmail } from "../../Utils/Constants";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import LogoText from "../../Components/LogoText";

const Question = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  // Get Deck id
  const location = useLocation();
  const mGetdeckId = location.state?.mdeckId || "";
  const mGetdeckName = location.state?.mdeckName || "";

  const mBackClick = () => {
    navigate("/");
  };

  const ProceedClick = () => {};

  const skipclick = () => {
    navigate("/login");
  };

  const questionClick = () => {
    navigate("/deckscore", {
      state: {
        mdeckId: mGetdeckId,
        mdeckName: mGetdeckName,
      },
    });
  };
  const isDisabled = false;
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className="min-h-screen flex flex-row">
          <div className="hidden lg:block  w-3/5 bg-mwhite flex-col items-start justify-start p-5">
            <div className="mt-3 ml-3">
              {/* <img
                src={Images.zenpivot}
                alt="google Icon"
                className=" h-9 w-28"
              /> */}
              <LogoText label={t("appname")} />
            </div>

            <div className="w-full h-auto justify-center items-center flex">
              <img src={Images.questionbg} alt="login" className="w-2/3 " />
            </div>
          </div>
          <div className="lg:w-2/5 w-full pl-2 py-5 lg:pl-20 lg:py-10 items-center justify-center lg:items-start lg:justify-start bg-mBackground  flex-col flex ">
            <div className="w-11/12 h-full ">
              <div className="grid gap-5 grid-cols-4   w-full h-2 mt-5 items-center justify-center ">
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
                <div className=" h-1 bg-mButtonColor rounded-full"></div>
                <div className=" h-1 bg-mTextColorLogin rounded-full"></div>
                <div className=" h-1 bg-mTextColorLogin rounded-full"></div>
              </div>

              <div className="mt-4  w-full justify-between flex">
                <div>
                  <p className="text-mTextColor font-PlusJakartaSansMedium text-sm 3xl:text-base">
                    Step 2 / 4
                  </p>
                </div>

                <div onClick={() => skipclick()}>
                  <p className="text-mTextColor font-PlusJakartaSansMedium text-sm cursor-pointer 3xl:text-base">
                    {t("skip")}
                  </p>
                </div>
              </div>

              <div className="mt-10 w-full p-2  items-start justify-start flex-col flex">
                <div className="flex flex-row items-center justify-center">
                  <BackIcon
                    className="w-6 h-6  flex-shrink-0 cursor-pointer"
                    onClick={() => mBackClick()}
                  />{" "}
                  <p className="ml-2 font-PlusJakartaSansRegular font-normal text-sm 3xl:text-base">
                    {t("GoBack")}
                  </p>
                </div>

                <div>
                  <p className="font-PlusJakartaSansSemiBold text-4xl mt-10">
                    {t("JustsomeQuestionsforyou")}
                  </p>
                </div>
              </div>

              <div className="mt-4">
                <PrimaryButton
                  label={t("Signup")}
                  onClick={questionClick}
                  onDisable={isDisabled}
                ></PrimaryButton>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Question;
