import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../redux/slice/userSlice";
import masterSlice from "./slice/masterSlice";

//configure the store
export const store = configureStore({
  reducer: {
    userInfo: userReducer,
    master: masterSlice,
  },
});

export default store;
